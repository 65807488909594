import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { AgentWaitingComponent } from './agent-waiting/agent-waiting.component';
import { CaseCompleteComponent } from './case-complete/case-complete.component';
import { AcceptCaseWrapperComponent } from './accept-case/accept-case-wrapper/accept-case-wrapper.component';
import { CaseTypeSelectionWrapperComponent } from './case-type-selection/case-type-selection-wrapper/case-type-selection-wrapper.component';
import { LoginComponent } from './auth/login/login.component';

const routes: Routes = [
	{
		path: '',
		component: LoginComponent,
	},
	{
		path: 'home',
		component: AgentWaitingComponent,
		canActivate: [AuthGuard],
		pathMatch: 'full',
	},
	{
		path: 'dashboard',
		component: CaseTypeSelectionWrapperComponent,
		canActivate: [AuthGuard],
		pathMatch: 'full',
	},
	{
		path: 'customer',
		loadChildren: () =>
			import(
				'./ShoppingSession/shopping-session/shopping-session.module'
			).then((m) => m.ShoppingSessionModule),
		canActivate: [AuthGuard],
		pathMatch: 'full',
	},
	{
		path: 'accept-case',
		component: AcceptCaseWrapperComponent,
		canActivate: [AuthGuard],
		pathMatch: 'full',
	},
	{
		path: 'case-complete',
		component: CaseCompleteComponent,
		canActivate: [AuthGuard],
		pathMatch: 'full',
	},
	{
		path: 'manager',
		loadChildren: () =>
			import('./management/management.module').then(
				(m) => m.ManagementModule
			),
		canActivate: [AuthGuard],
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
