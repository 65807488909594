import { Store, StoreConfig } from '@datorama/akita';
import { Rack } from './Models/rack.model';
import { ProductZone } from './Models/product-zone.model';
import { Camera } from '../../camera/Models/camera.model';
import { AlgoCart } from '../../product-view/Models/algo-cart';

export type ShoppingSessionState = {
	unitId: number | null;
	racks: Rack[];
	shoppingCart: ProductZone[];
	cameraId: number;
	rackCameras: Camera[];
	overviewCameras: Camera[];
	algoCart: AlgoCart[];
};

export const initialState: ShoppingSessionState = {
	unitId: null,
	racks: null,
	shoppingCart: [],
	cameraId: null,
	rackCameras: [],
	overviewCameras: [],
	algoCart: [],
};

@StoreConfig({ name: 'shopping-session', resettable: true })
export class ShoppingSessionStore extends Store<ShoppingSessionState> {
	constructor() {
		super(initialState);
	}

	setUnitId(unitId: number) {
		this.update({ unitId });
	}

	setRacks(racks: Rack[]) {
		this.update({ racks });
	}

	setShoppingCart(shoppingCart: ProductZone[]) {
		this.update({ shoppingCart });
	}

	setCameraId(cameraId: number) {
		this.update({ cameraId });
	}

	setRackCameras(rackCameras: Camera[]) {
		this.update({ rackCameras });
	}

	setOverviewCameras(overviewCameras: Camera[]) {
		this.update({ overviewCameras });
	}

	setAlgoCart(algoCart: AlgoCart[]) {
		this.update({ algoCart });
	}
}
