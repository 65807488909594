<mat-sidenav-container fullscreen>
	<mat-sidenav mode="side" opened>
		<mat-nav-list>
			<app-side-menu></app-side-menu>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<app-toolbar></app-toolbar>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
