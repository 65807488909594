import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreLayoutComponent } from './store-layout.component';

@NgModule({
	declarations: [StoreLayoutComponent],
	imports: [CommonModule],
	exports: [StoreLayoutComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StoreLayoutModule {}
