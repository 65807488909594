import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	AfterViewInit,
	AfterContentChecked,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { StoreLayoutService } from './store-layout.service';
import { ShoppingSessionService } from '../shopping-session/shopping-session-state/shopping-session.service';
import { ShoppingSessionQuery } from '../shopping-session/shopping-session-state/shopping-session.query';
import { Rack } from './models/rack.model';
import '@lunchbox/elements/library';
import { map, take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Product } from '../product-view/Models/product.model';

@Component({
	selector: 'app-store-layout',
	templateUrl: './store-layout.component.html',
	styleUrls: ['./store-layout.component.css'],
})
export class StoreLayoutComponent implements OnInit, AfterViewInit {
	@ViewChild('svg') svg: ElementRef;
	@ViewChild('img') img: ElementRef;
	store$: Observable<any>;

	rack$: Observable<any> = this.shoppingSessionQuery.rack$;
	JSON: JSON;
	showOrigin: boolean = false;
	selectedRack: Rack;
	selectedCameraId: number;

	constructor(
		private storeLayoutService: StoreLayoutService,
		private shoppingSessionService: ShoppingSessionService,
		private shoppingSessionQuery: ShoppingSessionQuery
	) {}

	ngOnInit() {
		this.storeLayoutService.getStoreLayout().subscribe((store: any) => {
			const units = this.storeLayoutService.getUnits(store);
			this.shoppingSessionService.setRacks(units);
			this.shoppingSessionService.setUnitId(
				this.findFirstRackWithProducts(units).id
			);
		});
	}

	ngAfterViewInit() {
		this.store$ = this.storeLayoutService
			.getStoreLayout()
			.pipe(map((store: any) => JSON.stringify(store)));
	}

	// updateViewBox(image) {
	// 	const imgUrl = image.href.baseVal;
	// 	const img = new Image();
	// 	img.src = imgUrl;
	// 	img.onload = event => {
	// 		const loadedImage: any = event.currentTarget;
	// 		this.svg.nativeElement.attributes.viewBox.textContent = `0 0 ${loadedImage.width} ${loadedImage.height}`;
	// 	};
	// }

	updateProducts(rack: Rack) {
		//update the store to set the current rack
		this.shoppingSessionService.setUnitId(rack.id);
	}

	updateSelectedCamera(cameraId: number) {
		console.log('Camera ' + cameraId + ' selected');
		this.shoppingSessionService.setCameraId(cameraId);
		this.selectedCameraId = cameraId;
	}

	async displayData(event: any) {
		if (
			Object.prototype.hasOwnProperty.call(event.detail, 'id') &&
			event.detail.id != this.selectedRack?.id
		) {
			const products: any =
				await this.shoppingSessionService.getProductByUnitId(
					event.detail.id
				);

			this.updateProducts(event.detail);
			this.selectedRack = event.detail;

			const currentRack = await this.rack$.pipe(take(1)).toPromise();

			currentRack.layers = currentRack.layers.map((layer) => {
				const newProductsObj = products.filter(
					(product: any) => product.layerId == layer.id
				);

				layer.productZones = newProductsObj.map((res: any) => {
					res.shelfIndex = layer.layerIndex;

					res.cartQty = 0;
					return res;
				});

				return layer;
			});

			const racks = await this.shoppingSessionQuery.allRacks
				.pipe(take(1))
				.toPromise();

			racks.forEach((rack: any, index, arr) => {
				if (rack.unitIndex == currentRack.unitIndex) {
					racks[index] = currentRack;
				}
			});

			this.shoppingSessionService.setRacks(racks);

			const rackCameras =
				await this.shoppingSessionQuery.selectRackCamerasByUnitId$
					.pipe(take(1))
					.toPromise();

			if (rackCameras.length > 0) {
				this.updateSelectedCamera(rackCameras[0].id);
			}
		}
	}

	findFirstRackWithProducts(racks: any): Rack {
		let foundRack = null;
		racks.forEach((rack) => {
			if (rack.layers && rack.layers.length > 0) {
				rack.layers.forEach((shelf: any) => {
					if (shelf.length > 0) {
						if (foundRack === null) {
							foundRack = rack;
						}
					}
				});
			}
		});
		return foundRack === null ? racks[0] : foundRack;
	}
}
