export const shoppingSessionTicket = {
	id: '5ee9060a98669c0016f45a88',
	ack: 'e45cf7398c9d88116592a25485d0c00b',
	payload: {
		accessToken:
			'257728adc989c20b60969b4604890929:17ef222bad3508a1e8cfe92033cfe21b',
		storeId: 1,
	},
	tries: 8,
};

export const shoppingSessionAcceptTicket =
	'Agent ticket processing time 5ee9060a98669c0016f45a88 for type customer-session extended by 30 minutes';

export const refundRequestTicket = {
	id: '5edff7f798f32b00155b1c22',
	ack: '4ef6a1630758c03e559ca8c8f8295c9a',
	payload: {
		accessToken:
			'257728adc989c20b60969b4604890929:17ef222bad3508a1e8cfe92033cfe21b',
		storeId: 1,
		refundData: {
			comment: 'This is a refund',
			productId: '300519-0-1',
			productName: 'Doritos Cool Ranch 9.75oz',
			productImageUrl:
				'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/300519_doritos_cool_ranch_family_scroll1.png',
			productQuantity: '1',
			refundAmount: '2.99',
		},
	},
	tries: 61,
};
