<div class="logo">
	<img class="logo" src="../../assets/img/Logo-manager.svg" alt="" />
</div>
<mat-list-item
	[ngClass]="{ 'active-list-item': i === selectedIndex }"
	*ngFor="let item of menuItems; let i = index"
	(click)="showSelected(i)"
>
	<div *ngIf="i === selectedIndex" class="rectangle"></div>
	<a routerLinkActive="router-link-active" class="label-menu">
		{{ item.title }}
	</a>
</mat-list-item>
<img class="single-pattern" src="../../assets/img/pattern-manager.svg" alt="" />
