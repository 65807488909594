

// example url: https://61b0b4968e417.streamlock.net/QA/ACCC8ED4C3D7/playlist.m3u8?DVR&wowzadvrplayliststart=20220701144238&wowzadvrplaylistduration=36000
export function buildWowzaUrl(
	fromPrevUrl: string,
	{ duration, startTime }: WowzaBuilderOptions
) {
	const baseUrl = fromPrevUrl.split('?')?.[0];
	const oldStartTimeString = startTimeStringFromWowzaUrl(fromPrevUrl);
	const oldDurationString = durationFromWowzaUrl(fromPrevUrl);

	if (!baseUrl || !oldStartTimeString || !oldDurationString) {
		console.error('Badly formed wowza url:', fromPrevUrl);
		return fromPrevUrl
	}

	const newDuration = duration || Number(oldDurationString);
	
	const isVodLink = fromPrevUrl.includes('/_definst_/mp4')
	if (isVodLink){
		// for vod links, we're disallowing changing the start time for now
		return `${baseUrl}?wowzaplaystart=${oldStartTimeString}&wowzaplayduration=${newDuration}`;
	}
	else {
		const newStartTime = startTime || dateFromWowzaTimestamp(oldStartTimeString);
		const newStartTimeStr = dateToWowzaTimestamp(newStartTime);
		return `${baseUrl}?DVR&wowzadvrplayliststart=${newStartTimeStr}&wowzadvrplaylistduration=${newDuration}`;
	}
}

export function startTimeStringFromWowzaUrl(wowzaUrl: string) {
	return wowzaUrl.match(/[&,?](wowzadvrplayliststart|wowzaplaystart)=(\d+)/)?.[2];
}

export function durationFromWowzaUrl(wowzaUrl: string) {
	return wowzaUrl.match(/[&,?](wowzadvrplaylistduration|wowzaplayduration)=(\d+)/)?.[2];
}

export function dateToWowzaTimestamp(date: Date) {
	const YYYY = date.getFullYear().toString().padStart(4, '0');
	const MM = (date.getMonth() + 1).toString().padStart(2, '0');
	const DD = date.getDate().toString().padStart(2, '0');
	const hh = date.getHours().toString().padStart(2, '0');
	const mm = date.getMinutes().toString().padStart(2, '0');
	const ss = date.getSeconds().toString().padStart(2, '0');
	return `${YYYY}${MM}${DD}${hh}${mm}${ss}`;
}

export function dateFromWowzaTimestamp(wowzaTimestamp: string) {
	if (wowzaTimestamp.length != 14)
		throw `badly formed wowzaTimestamp: '${wowzaTimestamp}'`;
	const year = Number(wowzaTimestamp.substring(0, 4));
	const month = Number(wowzaTimestamp.substring(4, 6)) - 1;
	const day = Number(wowzaTimestamp.substring(6, 8));
	const hour = Number(wowzaTimestamp.substring(8, 10));
	const minute = Number(wowzaTimestamp.substring(10, 12));
	const second = Number(wowzaTimestamp.substring(12, 14));
	return new Date(year, month, day, hour, minute, second);
}

export type WowzaBuilderOptions = {
	duration?: number;
	startTime?: Date;
};
