<vg-player style="min-height: 100px" #vgPlayer>
	<vg-controls #controls *ngIf="inputVideoElement">
		<vg-play-pause></vg-play-pause>
		<vg-time-display
			[vgProperty]="'current'"
			[vgFormat]="'hh:mm:ss'"
		></vg-time-display>
		<vg-time-display
			[vgProperty]="'total'"
			[vgFormat]="'hh:mm:ss'"
		></vg-time-display>
		<vg-scrub-bar>
			<vg-scrub-bar-current-time></vg-scrub-bar-current-time>
			<vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
		</vg-scrub-bar>
		<span class="vg-time-display">{{formattedTime$ | async | date:'YYYY-MM-dd h:mm:ss a'}}</span>
		<vg-fullscreen></vg-fullscreen>
		<div>
			<button
				mat-icon-button
				(click)="togglePan()"
				[ngStyle]="{ color: panZoom?.isPaused() ? 'gray' : 'white' }"
				matTooltip="disable pan zoom"
			>
				<mat-icon>zoom_out_map</mat-icon>
			</button>
			<button
				mat-icon-button
				(click)="resetPanZoom()"
				matTooltip="reset zoom"
			>
				<mat-icon>format_align_center</mat-icon>
			</button>
		</div>
	</vg-controls>
	<div>
		<!-- This wrapper div is important. Without it, panzoom will catch and handle drag events meant for the vg-scrub-bar :: LT-->
		<canvas #mainVideoCanvas style="width: 100%; height: 100%"></canvas>
	</div>
</vg-player>
