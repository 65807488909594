<div class="page-wrapper">
	<div class="login-wrapper">
		<div class="rectangle">
			<div class="parent-banner">
				<img class="patterns" src="./assets/img/Patterns.png" alt="" />
				<img class="logo" src="./assets/img/Logo.svg" />
				<p class="finish-message">
					The cart has been submitted. <br />Please capture notes and
					status below.
				</p>
			</div>
			<form [formGroup]="form" class="form">
				<mat-select id="status" formControlName="status" required>
					<mat-option value="" disabled selected>Status</mat-option>
					<mat-option
						*ngFor="let status of caseStatuses$ | async"
						[value]="status.id"
						>{{ status.status }}</mat-option
					>
				</mat-select>
				<textarea
					matInput
					formControlName="notes"
					placeholder="Notes (Optional)"
				></textarea>
				<button
					mat-raised-button
					(click)="submitForm()"
					[disabled]="!form.valid"
				>
					SUBMIT
				</button>
				<div class="error" *ngIf="this.errMsg">
					<p>{{ this.errMsg }}</p>
				</div>
			</form>
		</div>
	</div>
</div>
