import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
	ROUTE_USER_PROFILE,
	ROUTE_REFUNDS,
	ROUTE_REPORTS,
} from '../../utils/routes.constants';

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.css'],
})
export class SideMenuComponent {
	selectedIndex: number = 0;
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {}

	menuItems: any = [
		{ title: 'User Profile', route: ROUTE_USER_PROFILE },
		{ title: 'Refund', route: ROUTE_REFUNDS },
		{ title: 'Reports', route: ROUTE_REPORTS },
	];

	showSelected(index: number) {
		this.selectedIndex = index;
		this.router.navigate([this.menuItems[index].route], {
			relativeTo: this.activatedRoute,
		});
	}
}
