import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type ErrorDialogData = {
	message: string;
	title: string;
	rawPayload: any;
};

@Component({
	selector: 'app-error-dialog',
	templateUrl: './error-dialog.component.html',
	styleUrls: ['./error-dialog.component.css'],
})
export class ErrorDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<ErrorDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public errorData: ErrorDialogData
	) {}

	showPayload: boolean = false;

	cancel(): void {
		this.dialogRef.close();
	}
	rawPayloadString(): string {
		try {
			return JSON.stringify(this.errorData.rawPayload, undefined, 2);
		} catch {
			return this.safeStringify(this.errorData.rawPayload);
		}
	}

	private safeStringify = (obj, indent = 2) => {
		let cache = [];
		const retVal = JSON.stringify(
			obj,
			(key, value) =>
				typeof value === 'object' && value !== null
					? cache.includes(value)
						? undefined // Duplicate reference found, discard key
						: cache.push(value) && value // Store value in our collection
					: value,
			indent
		);
		cache = null;
		return retVal;
	};
}
