import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EnvService } from '../../Services/env-service.service';
import { TicketService } from '../../ticket/state/ticket.service';
import { Store } from './models/store.model';
import { map, tap } from 'rxjs/operators';
import { ShoppingSessionService } from '../shopping-session/shopping-session-state/shopping-session.service';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class StoreLayoutService {
	private baseUrl = `${this.env.hitlServiceUrl}`;
	constructor(
		private env: EnvService,
		private ticketService: TicketService,
		private http: HttpClient,
		private shoppingSessionService: ShoppingSessionService
	) {}

	getStoreLayout() {
		const storeId = this.ticketService.getStoreId();
		const hitlSessionId = this.ticketService.getAccessToken();

		// return this.http
		// 	.get<any>(`/assets/smp-store-response.json`)\

		return this.http
			.get<any>(
				`${this.env.hitlServiceUrl}/store/${storeId}/layout?hitlSessionId=${hitlSessionId}`
			)
			.pipe(
				tap((result) =>
					this.shoppingSessionService.setRackCameras(
						result.cameras.filter(
							(camera) =>
								camera.cameraType?.name == 'IP' &&
								!camera.isGeneral
						)
					)
				)
			);
	}

	getUnits(store) {
		// console.log(store);
		store.units.forEach((unit) => {
			unit.gondolaName = store.storeName;
			if (unit.layers) {
				unit.layers.forEach((layer) => {
					if (layer.productZones) {
						layer.productZones.forEach((pz, index) => {
							/**
							 * Initialize additional fields that is needed
							 * by hitl front end logic
							 */
							pz.cartQty = 0;
						});
					}
				});
			}
		});
		return store.units;
	}
}
