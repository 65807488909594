import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcceptCaseComponent } from './accept-case.component';
import { NavModule } from '../nav/nav.module';
import { AppMaterialModule } from '../app-material/app-material.module';
import { AcceptCaseWrapperComponent } from './accept-case-wrapper/accept-case-wrapper.component';

@NgModule({
	declarations: [AcceptCaseComponent, AcceptCaseWrapperComponent],
	imports: [CommonModule, NavModule, AppMaterialModule],
	exports: [AcceptCaseComponent, AcceptCaseWrapperComponent],
})
export class AcceptCaseModule {}
