import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-management',
	templateUrl: './management.component.html',
	styleUrls: ['./management.component.css'],
})
export class ManagementComponent {
	constructor() {}
}
