import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseCompleteComponent } from './case-complete.component';
import { AppMaterialModule } from '../app-material/app-material.module';

@NgModule({
	declarations: [CaseCompleteComponent],
	imports: [CommonModule, AppMaterialModule],
	exports: [CaseCompleteComponent],
})
export class CaseCompleteModule {}
