import { Injectable, Injector } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EnvService } from '../Services/env-service.service';
import { customerImages } from '../mock-data/customer-image';
//import { signin } from '../mock-data/auth';
import {
	shoppingSessionTicket,
	refundRequestTicket,
} from '../mock-data/ticketing';
import { shoppingSessionAcceptTicket } from '../mock-data/ticketing';
import { layout } from '../mock-data/layout';
import { products } from '../mock-data/products';
import { overviewCameras } from '../mock-data/overview-camera';
import { rackCameras } from '../mock-data/rack-cameras';
import { algoCart } from '../mock-data/algo-cart';
import { hitlCart } from '../mock-data/hitl-cart';
import { caseStatus } from '../mock-data/case-status';

@Injectable()
export class HttpMockInterceptor implements HttpInterceptor {
	urls = [
		// {
		// 	url: `${this.env.authUrl}/auth/user/signin`,
		// 	json: signin
		// },
		{
			url: `${this.env.ticketingUrl}/ticket/customer-session`,
			json: shoppingSessionTicket,
		},
		{
			url: `${this.env.ticketingUrl}/ticket/refund-request`,
			json: refundRequestTicket,
		},
		{
			url: `${this.env.ticketingUrl}/ticket/customer-session/accept-ticket`,
			json: shoppingSessionAcceptTicket,
		},
		{
			url: `${this.env.ticketingUrl}/ticket/refund-request/accept-ticket`,
			json: shoppingSessionAcceptTicket,
		},
		{
			url: `${this.env.hitlServiceUrl}/shopping-session/snapshots/${shoppingSessionTicket.payload.accessToken}`,
			json: customerImages,
		},
		{
			url: `${this.env.hitlServiceUrl}/store/layout/${shoppingSessionTicket.payload.storeId}`,
			json: layout,
		},
		{
			url: `${this.env.hitlServiceUrl}/store/${shoppingSessionTicket.payload.storeId}/products`,
			json: products,
		},
		{
			url: `${this.env.hitlServiceUrl}/shopping-session/algo-cart/${shoppingSessionTicket.payload.accessToken}`,
			json: algoCart,
		},
		{
			url: `${this.env.hitlServiceUrl}/shopping-session/overview-cameras/${shoppingSessionTicket.payload.storeId}/${shoppingSessionTicket.payload.accessToken}`,
			json: overviewCameras,
		},
		{
			url: `${this.env.hitlServiceUrl}/shopping-session/rack-cameras/${shoppingSessionTicket.payload.storeId}/${shoppingSessionTicket.payload.accessToken}`,
			json: rackCameras,
		},
		{
			url: `${this.env.hitlServiceUrl}/shopping-session/hitl-cart-item/${shoppingSessionTicket.payload.accessToken}`,
			json: hitlCart,
		},
		{
			url: `${this.env.hitlServiceUrl}/shopping-session/case-status`,
			json: caseStatus,
		},
		{
			url: `${this.env.hitlServiceUrl}/shopping-session/submit-cart`,
			json: '',
		},
		{
			url: `${this.env.hitlServiceUrl}/shopping-session/submit-refund`,
			json: '',
		},
		{
			url: `${this.env.hitlServiceUrl}/shopping-session/hitl-session`,
			json: '',
		},
		{
			url: `${this.env.ticketingUrl}/ticket/refund-request/ack`,
			json: '',
		},
		{
			url: `${this.env.ticketingUrl}/ticket/shopping-session/ack`,
			json: '',
		},
	];

	constructor(private injector: Injector, private env: EnvService) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		for (const element of this.urls) {
			console.log(
				'Loaded from mocked data! ' +
					'\nOriginal external call: ' +
					request.url
			);
			if (element.url === request.url) {
				return of(
					new HttpResponse({
						status: 200,
						body: element.json as any,
					})
				);
			}
		}
		console.log(
			`Endpoint ${request.url} was called while in mocked data mode.  Please ensure this is desired.`
		);
		return next.handle(request);
	}
}
