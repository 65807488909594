import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar.component';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';

@NgModule({
	imports: [CommonModule, AppMaterialModule],
	exports: [ToolbarComponent],
	declarations: [ToolbarComponent],
	providers: [],
})
export class ToolbarModule {}
