import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { CanActivate } from '@angular/router';
import { AuthQuery } from './state/auth.query';
import { take, switchMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private authQuery: AuthQuery) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		return this.authQuery.isLoggedIn$.pipe(
			take(1),
			switchMap((isLoggedIn) => {
				if (!isLoggedIn) {
					this.router.navigate(['/']);
				}
				return of(isLoggedIn);
			})
		);
	}
}
