<div class="nav-border">
	<img class="single-pattern" src="../../assets/img/pattern-nav.svg" alt="" />
	<img class="logo" src="../../assets/img/Logo-nav.svg" alt="" />
	<div class="account">
		<button
			mat-icon-button
			(click)="isMenuSelected = !isMenuSelected"
			(focusout)="clickMenu($event)"
		>
			<mat-icon>account_circle</mat-icon>
		</button>
	</div>
	<div *ngIf="isMenuSelected" class="menu">
		<button mat-icon-button (click)="signOut()">SIGN OUT</button>
	</div>
</div>
