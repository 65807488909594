import { Pipe, PipeTransform } from '@angular/core';
import { CameraService } from '../camera.service';

@Pipe({
	name: 'livestream',
})
export class LivestreamPipe implements PipeTransform {
	constructor(private cameraService: CameraService) {}
	transform(livestreamUrl: any, args?: any): any {
		if (livestreamUrl?.indexOf('wowzadvrplaylistduration') === -1) {
			return livestreamUrl;
		} else {
			let duration = livestreamUrl?.substring(
				livestreamUrl?.lastIndexOf('=') + 1,
				livestreamUrl.length
			);
			this.cameraService.setDuration(Number(duration));
			return (
				livestreamUrl?.substring(
					0,
					livestreamUrl.lastIndexOf('=') + 1
				) + Number(duration)
			);
		}
	}
}
