import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { CameraService } from '../../camera.service';
import { Camera } from '../../Models/camera.model';
import { Observable, Subscription } from 'rxjs';
import { ShoppingSessionQuery } from 'src/app/ShoppingSession/shopping-session/shopping-session-state/shopping-session.query';
import PanZoomFactory, { PanZoom } from 'panzoom';
import { VgPlayerComponent } from '@videogular/ngx-videogular/core';
import { dateFromWowzaTimestamp } from 'src/app/utils/wowzaUrlHelpers';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-main-camera',
	templateUrl: './main-camera.component.html',
	styleUrls: ['./main-camera.component.css'],
})
export class MainCameraComponent {
	constructor(
		private cameraService: CameraService,
		private shoppingSessionQuery: ShoppingSessionQuery
	) {}
	camera$: Observable<Camera> = this.shoppingSessionQuery.selectedCamera$;
	formattedTime$: Observable<Date>;
	public panZoom: PanZoom;

	@ViewChild('mainVideoCanvas')
	mainVideoCanvas: ElementRef<HTMLCanvasElement>;
	@ViewChild('vgPlayer') vgPlayer: VgPlayerComponent;

	private canvas2dContext: CanvasRenderingContext2D;
	public inputVideoElement: HTMLVideoElement;
	private camera: Camera;

	animationFrame() {
		if (this.inputVideoElement)
			this.canvas2dContext?.drawImage(this.inputVideoElement, 0, 0);
		if (!this.mainVideoSub.closed /* apoptosis */)
			requestAnimationFrame(() => this.animationFrame());
	}

	ngAfterViewInit(): void {
		this.panZoom = PanZoomFactory(this.mainVideoCanvas.nativeElement);
		this.canvas2dContext =
			this.mainVideoCanvas.nativeElement.getContext('2d');

		this.animationFrame();
	}

	ngOnInit(): void {
		this.mainVideoSub = this.cameraService.mainVideoElement$.subscribe(
			([videoElement, playable, camera]) => {
				this.camera = camera;
				this.vgPlayer.api.registerMedia(playable);
				this.inputVideoElement = videoElement;
				this.mainVideoCanvas.nativeElement.width =
					videoElement.videoWidth;
				this.mainVideoCanvas.nativeElement.height =
					videoElement.videoHeight;

				this.formattedTime$ = this.vgPlayer.api
					?.getDefaultMedia()
					.subscriptions.timeUpdate.pipe(
						map((res) =>
							this.calculateFormattedTime(res.target.currentTime)
						)
					);
			}
		);
	}

	private mainVideoSub: Subscription;
	ngOnDestroy() {
		this.mainVideoSub.unsubscribe(); // apoptosis
	}

	resetPanZoom() {
		this.panZoom.moveTo(0, 0);
		this.panZoom.zoomAbs(0, 0, 1);
	}

	togglePan() {
		if (this.panZoom.isPaused()) this.panZoom.resume();
		else this.panZoom.pause();
	}

	@HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		event.preventDefault();
		switch (event.key.toLowerCase()) {
			case 'd':
				this.cameraService.syncAllVideos(
					this.vgPlayer.api.currentTime + 0.01
				);
				break;
			case 'a':
				this.cameraService.syncAllVideos(
					this.vgPlayer.api.currentTime - 0.01
				);
				break;
			case ' ':
				this.cameraService.isPaused === true
					? this.cameraService.keyboardPlay()
					: this.cameraService.keyboardPause();
				break;
			case 'escape': // this one doesn't work
				this.resetPanZoom();
				break;
			default:
		}
	}

	public calculateFormattedTime(currentTime: number) {
		// todo, this needs to tolerate vod time strings which have the start time in the file name, and an offset in milliseconds that is added to that
		const dateString = this.camera?.liveStreamUrl.match(
			/&wowzadvrplayliststart=(\d{14})/
		)?.[1];
		const startDate = dateFromWowzaTimestamp(dateString);

		return new Date(
			startDate.valueOf() +
				currentTime * 1000 -
				startDate.getTimezoneOffset() * 60_000
		);
	}
}
