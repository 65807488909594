import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HOME } from 'src/app/utils/routes.constants';
import { IUser } from '../models/user.model';
import { AuthService } from '../state/auth.service';
import { AuthStore } from '../state/auth.store';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnDestroy, OnInit {
	errMsg: string;
	subs: Subscription[] = [];
	userInfo: IUser;

	constructor(
		public auth: AuthService,
		public authStore: AuthStore,
		private router: Router
	) {}

	ngOnDestroy(): void {
		this.subs.forEach((sub) => sub.unsubscribe());
	}

	ngOnInit(): void {
		if (sessionStorage.getItem('loggedIn'))
			this.errMsg = 'You need to be authenticated to use this service.';
	}

	async login() {
		await this.auth.loginWithSSO();
	}

	async logout() {
		await this.auth.logout();
	}
}
