export const SHOPPING_SESSION = 'customer-session';
export const REFUND = 'refund-request';
export const APP_NAME = 'hitl-web-app';
export const ROLE_AGENT = 'APP-HITL-AGENT';
export const ROLE_MANAGER = 'APP-HITL-MANAGER';
export const ROLE_REFUND = 'APP-HITL-REFUND';
export const DIALOG_ADD = 'dialog-add';
export const DIALOG_EDIT = 'dialog-edit';
export const DIALOG_DELETE = 'dialog-delete';
export const DIALOG_SUBMIT = 'dialog-submit';
export const DIALOG_UPDATE = 'dialog-update';
export const ERROR_PERMISSIONS =
	'Missing permissions to access HITL. Go to my7access.7-eleven.com, request for APP-HITL-AGENT or APP-HITL-MANAGER and try again';
export const ACCESS_TOKEN = 'access_token';
export const EMAIL = 'email';
export const DISPLAY_NAME = 'displayName';
export const EXPIRATION = 'exp';
export const IAT = 'iat';
export const ROLE = 'role';
