<div class="row">
	<h3 class="title">RACK CAMERAS</h3>
</div>

<div class="row">
	<div
		*ngFor="let camera of currentCameras | async"
		class="cams col-6"
		[ngClass]="camera.id === selectedCamera ? 'highlighted' : null"
	>
		<app-camera
			[cam]="camera"
			[height]="14"
			(onSelected)="updateCameraId(camera, $event[0], $event[1])"
		></app-camera>
	</div>
</div>
