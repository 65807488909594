import { Component, OnInit } from '@angular/core';
import { TicketQuery } from 'src/app/ticket/state/ticket.query';
import { Observable, Subject } from 'rxjs';
import { REFUND, SHOPPING_SESSION } from 'src/app/utils/constants';
import { TicketService } from 'src/app/ticket/state/ticket.service';
import { Router } from '@angular/router';
import { HOME, CASE_COMPLETE } from '../../utils/routes.constants';
import { CameraService } from '../camera/camera.service';

@Component({
	selector: 'app-shopping-session',
	templateUrl: './shopping-session.component.html',
	styleUrls: ['./shopping-session.component.css'],
})
export class ShoppingSessionComponent implements OnInit {
	refund: string = REFUND;
	shoppingSession: string = SHOPPING_SESSION;
	ticketType$: Observable<string> = this.ticketQuery.ticketType$;

	constructor(
		private ticketQuery: TicketQuery,
		private ticketService: TicketService,
		private cameraService: CameraService,
		private router: Router
	) {}

	ngOnInit(): void {
		sessionStorage.setItem(this.cameraService.CAMERA_SYNC_KEY, '0');
		const hitlSessionId = this.ticketService.getAccessToken();
		if (!hitlSessionId) {
			this.router.navigate([HOME]);
		}

		if (hitlSessionId && this.ticketService.getSubmittedCart()) {
			this.router.navigate([CASE_COMPLETE]);
		}
		if (hitlSessionId && !this.ticketService.getAccepted()) {
			this.router.navigate([HOME]);
		}
	}
}
