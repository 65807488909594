import { Injectable } from '@angular/core';
import { AuthStore } from './auth.store';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IUser } from '../models/user.model';
import { EnvService } from 'src/app/Services/env-service.service';
import {
	ROLE_AGENT,
	ROLE_REFUND,
	REFUND,
	ERROR_PERMISSIONS,
	ACCESS_TOKEN,
	EMAIL,
	DISPLAY_NAME,
	EXPIRATION,
	IAT,
	ROLE,
	ROLE_MANAGER,
} from 'src/app/utils/constants';
import { HOME, ROUTE_REFUNDS } from 'src/app/utils/routes.constants';
import LogRocket from 'logrocket';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { ErrorDialogService } from 'src/app/error-dialog/error-dialog.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
	private baseUrl = `${this.env.hitlServiceUrl}`;
	private hasStartedLogin = false;

	private config = {
		headers: new HttpHeaders({
			'x-api-key': this.env.authApiKey,
			'Content-Type': 'application/xml',
		}),
	};

	constructor(
		private authStore: AuthStore,
		private router: Router,
		private http: HttpClient,
		private env: EnvService,
		public oauthService: OAuthService,
		private errDialog: ErrorDialogService
	) {
		// Useful for debugging:
		this.oauthService.events.subscribe((event) => {
			if (event instanceof OAuthErrorEvent) {
				console.error('OAuthErrorEvent Object:', event);
			} else {
				console.warn('OAuthEvent Object:', event);
			}
		});
	}

	getUser(): IUser {
		return this.authStore.getValue().user;
	}

	navigateByPermissions(userInfo: IUser) {
		const roles = userInfo?.roles || [];
		// const roles = ['APP-HITL-AGENT'];
		const route =
			roles.includes(ROLE_AGENT) || roles.includes(ROLE_MANAGER)
				? HOME
				: roles.includes(ROLE_REFUND)
				? ROUTE_REFUNDS
				: '/';
		this.router.navigateByUrl(route);
	}

	public async loginWithSSO() {
		if (this.hasStartedLogin) return;

		sessionStorage.setItem('loggedIn', 'true');
		const alreadyLoggedInUser = this.authStore.getValue().user;
		if (alreadyLoggedInUser)
			return this.navigateByPermissions(alreadyLoggedInUser);

		this.hasStartedLogin = true;
		this.oauthService.configure(this.getClientConfig());

		this.oauthService.tokenValidationHandler = new JwksValidationHandler();
		await this.oauthService.loadDiscoveryDocumentAndLogin();
		this.oauthService.setupAutomaticSilentRefresh();

		const claims = this.oauthService.getIdentityClaims();
		const userInfo = this.createUserFromClaims(claims);
		this.authStore.updateUser(userInfo);

		this.navigateByPermissions(userInfo);
	}

	public createUserFromClaims(claims: any): IUser | undefined {
		if (!claims) return;

		const roles =
			(typeof claims[ROLE] === 'string'
				? claims[ROLE].split(',')
				: claims[ROLE]) ?? [];
		return {
			accessToken: sessionStorage.getItem(ACCESS_TOKEN),
			email: claims[EMAIL],
			firstName: claims[DISPLAY_NAME]?.split(',')[1] ?? '',
			lastName: claims[DISPLAY_NAME]?.split(',')[0] ?? '',
			exp: claims[EXPIRATION],
			iat: claims[IAT],
			roles,
		};
	}

	public async logout() {
		sessionStorage.removeItem('loggedIn');
		this.authStore.logout();
		await this.oauthService.revokeTokenAndLogout();
	}

	getClientConfig(): AuthConfig {
		return this.env.ssoConfig as AuthConfig;
	}
}
