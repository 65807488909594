import { Component, OnInit } from '@angular/core';
import { SHOPPING_SESSION, REFUND } from '../utils/constants';
import { TicketService } from '../ticket/state/ticket.service';
import { Router } from '@angular/router';
import { HOME } from '../utils/routes.constants';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-case-type-selection',
	templateUrl: './case-type-selection.component.html',
	styleUrls: ['./case-type-selection.component.css'],
})
export class CaseTypeSelectionComponent {
	customerSession = SHOPPING_SESSION;
	refund = REFUND;

	constructor(
		private ticketService: TicketService,
		private router: Router,
		public dialogRef: MatDialogRef<CaseTypeSelectionComponent>
	) {}

	setTicketType(ticketType: string) {
		sessionStorage.setItem('ticketType', ticketType.toString());
		this.ticketService.setTicketType(ticketType);

		this.dialogRef.close();
	}
}
