import { Store, StoreConfig } from '@datorama/akita';
import { User } from './models/user.model';
import { Role } from './models/role.model';
import { Team } from './models/team.model';
import { Refund } from './models/refund.model';

export type ManagementState = {
	userProfiles: User[];
	roles: Role[];
	teams: Team[];
	refunds: Refund[];
};

export const initialState: ManagementState = {
	userProfiles: [],
	roles: [],
	teams: [],
	refunds: [],
};

@StoreConfig({ name: 'management', resettable: true })
export class ManagementStore extends Store<ManagementState> {
	constructor() {
		super(initialState);
	}

	setUserProfiles(userProfiles: User[]) {
		this.update({ userProfiles });
	}

	setRoles(roles: Role[]) {
		this.update({ roles });
	}

	setTeams(teams: Team[]) {
		this.update({ teams });
	}

	setRefunds(refunds: Refund[]) {
		this.update({ refunds });
	}
}
