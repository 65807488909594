export const layout = {
	layoutUrl:
		'https://birds-eye-assets.s3.amazonaws.com/store-layouts/cw_layout_new.png',
	gondolas: [
		{
			id: 44,
			racks: [
				{
					id: 66,
					rackIndex: 'Rack 01',
					description: 'Rack number 1',
					left: 570.27,
					top: 190.166,
					width: 30,
					height: 75.1149,
					angle: 0,
					createdAt: '2019-10-17T19:42:20.000Z',
					updatedAt: '2019-10-17T19:42:20.000Z',
					gondolaId: 44,
					cameras: [
						{
							id: 59,
						},
						{
							id: 61,
						},
					],
				},
				{
					id: 67,
					rackIndex: 'Rack 02',
					description: 'Rack number 1',
					left: 541.538,
					top: 193.941,
					width: 26.891,
					height: 73.1001,
					angle: 0,
					createdAt: '2019-10-17T19:43:02.000Z',
					updatedAt: '2019-10-17T19:43:02.000Z',
					gondolaId: 44,
					cameras: [
						{
							id: 64,
						},
						{
							id: 66,
						},
					],
				},
			],
		},
		{
			id: 45,
			racks: [
				{
					id: 68,
					rackIndex: 'Rack 01',
					description: 'Rack number 1',
					left: 252.359,
					top: 149.983,
					width: 32.0073,
					height: 107.205,
					angle: 90.3501,
					createdAt: '2019-10-17T19:43:33.000Z',
					updatedAt: '2020-06-04T01:29:27.000Z',
					gondolaId: 45,
					cameras: [
						{
							id: 70,
						},
						{
							id: 84,
						},
						{
							id: 68,
						},
					],
				},
				{
					id: 69,
					rackIndex: 'Rack 02',
					description: 'Rack number 1',
					left: 266.728,
					top: 311.938,
					width: 25.5723,
					height: 136.587,
					angle: 270.438,
					createdAt: '2019-10-17T19:43:55.000Z',
					updatedAt: '2020-06-04T01:20:59.000Z',
					gondolaId: 45,
					cameras: [
						{
							id: 71,
						},
						{
							id: 72,
						},
					],
				},
			],
		},
		{
			id: 46,
			racks: [
				{
					id: 70,
					rackIndex: 'Rack 01',
					description: 'Rack number 1',
					left: 264.921,
					top: 401.08,
					width: 30,
					height: 129.051,
					angle: 90.3157,
					createdAt: '2019-10-17T19:46:19.000Z',
					updatedAt: '2020-06-04T01:27:03.000Z',
					gondolaId: 46,
					cameras: [
						{
							id: 74,
						},
						{
							id: 73,
						},
					],
				},
				{
					id: 71,
					rackIndex: 'Rack 02',
					description: 'Enter Description Here',
					left: 266.032,
					top: 437.007,
					width: 35.9653,
					height: 130.6,
					angle: 269.324,
					createdAt: '2019-10-17T19:46:31.000Z',
					updatedAt: '2020-06-04T01:26:37.000Z',
					gondolaId: 46,
					cameras: [
						{
							id: 76,
						},
						{
							id: 84,
						},
					],
				},
			],
		},
		{
			id: 47,
			racks: [
				{
					id: 72,
					rackIndex: 'Rack 01',
					description: 'Rack number 1',
					left: 265.67,
					top: 553.754,
					width: 30,
					height: 136.819,
					angle: 269.799,
					createdAt: '2019-10-17T19:47:06.000Z',
					updatedAt: '2020-06-04T01:14:00.000Z',
					gondolaId: 47,
					cameras: [
						{
							id: 80,
						},
						{
							id: 78,
						},
					],
				},
				{
					id: 73,
					rackIndex: 'Rack 02',
					description: 'Rack number 1',
					left: 268.867,
					top: 517.733,
					width: 30.1156,
					height: 134.536,
					angle: 90.1643,
					createdAt: '2019-10-17T19:47:57.000Z',
					updatedAt: '2020-06-04T01:25:51.000Z',
					gondolaId: 47,
					cameras: [
						{
							id: 79,
						},
						{
							id: 81,
						},
					],
				},
			],
		},
		{
			id: 48,
			racks: [
				{
					id: 74,
					rackIndex: 'Rack 01',
					description: 'Rack number 1',
					left: 788.924,
					top: 132.666,
					width: 55.6333,
					height: 60.061,
					angle: 0,
					createdAt: '2019-10-17T19:49:46.000Z',
					updatedAt: '2019-10-17T19:49:46.000Z',
					gondolaId: 48,
					cameras: [
						{
							id: 60,
						},
					],
				},
				{
					id: 75,
					rackIndex: 'Rack 02',
					description: 'Rack number 1',
					left: 788.331,
					top: 194.536,
					width: 60.9807,
					height: 60.0584,
					angle: 0,
					createdAt: '2019-10-17T19:50:13.000Z',
					updatedAt: '2019-10-17T19:50:13.000Z',
					gondolaId: 48,
					cameras: [
						{
							id: 60,
						},
					],
				},
			],
		},
		{
			id: 49,
			racks: [
				{
					id: 77,
					rackIndex: 'Rack 01',
					description: 'Rack number 1',
					left: 692.643,
					top: 50.172,
					width: 116.677,
					height: 56.0901,
					angle: 0,
					createdAt: '2019-10-17T19:50:55.000Z',
					updatedAt: '2019-10-17T19:50:55.000Z',
					gondolaId: 49,
					cameras: [
						{
							id: 77,
						},
					],
				},
			],
		},
		{
			id: 50,
			racks: [
				{
					id: 79,
					rackIndex: 'Rack 01',
					description: 'Rack number 1',
					left: 570.547,
					top: 51.1597,
					width: 102.829,
					height: 58.8683,
					angle: 0,
					createdAt: '2019-10-17T19:52:39.000Z',
					updatedAt: '2019-10-17T19:52:39.000Z',
					gondolaId: 50,
					cameras: [
						{
							id: 75,
						},
						{
							id: 77,
						},
					],
				},
			],
		},
		{
			id: 51,
			racks: [
				{
					id: 80,
					rackIndex: 'Rack 01',
					description: 'Rack number 1',
					left: 266.842,
					top: 276.844,
					width: 37.7333,
					height: 129.105,
					angle: 89.5057,
					createdAt: '2019-10-17T19:53:14.000Z',
					updatedAt: '2020-06-04T01:27:43.000Z',
					gondolaId: 51,
					cameras: [
						{
							id: 69,
						},
						{
							id: 77,
						},
						{
							id: 67,
						},
					],
				},
				{
					id: 81,
					rackIndex: 'Rack 02',
					description: 'Rack number 1',
					left: 249.904,
					top: 188.336,
					width: 37.8002,
					height: 107.278,
					angle: 269.857,
					createdAt: '2019-10-17T19:53:32.000Z',
					updatedAt: '2020-06-04T01:29:31.000Z',
					gondolaId: 51,
					cameras: [
						{
							id: 67,
						},
						{
							id: 69,
						},
					],
				},
			],
		},
		{
			id: 52,
			racks: [
				{
					id: 83,
					rackIndex: 'Rack 01',
					description: 'Enter Description Here',
					left: 219.316,
					top: 28.5054,
					width: 37.1717,
					height: 148.318,
					angle: 269.63,
					createdAt: '2019-10-17T19:54:34.000Z',
					updatedAt: '2020-06-04T01:37:18.000Z',
					gondolaId: 52,
					cameras: [
						{
							id: 65,
						},
						{
							id: 62,
						},
					],
				},
			],
		},
		{
			id: 53,
			racks: [
				{
					id: 85,
					rackIndex: 'Rack 01',
					description: 'Rack number 1',
					left: 100.325,
					top: 436.066,
					width: 53.2223,
					height: 117.448,
					angle: 180.135,
					createdAt: '2019-10-17T19:55:21.000Z',
					updatedAt: '2020-06-04T01:32:33.000Z',
					gondolaId: 53,
					cameras: [
						{
							id: 62,
						},
					],
				},
			],
		},
	],
};
