import { Component, OnInit, OnDestroy } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { TicketService } from '../ticket/state/ticket.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/state/auth.service';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';
import { HOME, CUSTOMER } from '../utils/routes.constants';
import { MatDialogRef } from '@angular/material/dialog';

const chimeAudio = new Audio('../assets/chime.mp3');
chimeAudio.load();

@Component({
	selector: 'app-accept-case',
	templateUrl: './accept-case.component.html',
	styleUrls: ['./accept-case.component.css'],
})
export class AcceptCaseComponent implements OnInit, OnDestroy {
	countdown: number = 10;
	sub: Subscription;
	isLastChanceToAcceptCase: boolean = false;
	favIcon: HTMLLinkElement = document.querySelector('#favIcon');

	constructor(
		private authService: AuthService,
		private ticketService: TicketService,
		private router: Router,
		private errDialog: ErrorDialogService,
		public dialogRef: MatDialogRef<AcceptCaseComponent>
	) {}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

	ngOnInit() {
		const accessToken = this.ticketService.getAccessToken();
		if (!accessToken) {
			this.router.navigate([HOME]);
		}

		if (accessToken && this.ticketService.getAccepted()) {
			this.router.navigate([CUSTOMER]);
		}

		const ticketRejects = this.ticketService.getCaseRejects();
		if (ticketRejects === 2) {
			this.isLastChanceToAcceptCase = true;
		}

		this.startCountdown();
	}

	startCountdown() {
		try {
			chimeAudio.play();
		} catch {}

		this.sub = interval(1400)
			.pipe(
				take(10),
				map(() => {
					this.countdown -= 1;
					if (this.countdown >= 1) {
						this.favIcon.href = `./assets/img/countdown/${this.countdown}.png`;
					} else {
						this.favIcon.href = './assets/img/logo.png';
					}
					if (this.countdown === 0) {
						this.didNotAccept();
					}
				})
			)
			.subscribe();
	}

	async didNotAccept() {
		this.favIcon.href = './assets/img/logo.png';
		if (this.isLastChanceToAcceptCase) {
			await this.signOut();
		} else {
			this.ticketService.clearAccessToken();
			this.ticketService.clearAckId();
			this.ticketService.addCaseReject();
			await this.router.navigate([HOME]);
		}
	}

	acceptTicket() {
		this.favIcon.href = './assets/img/logo.png';
		this.ticketService.acceptTicket().subscribe(
			(data) => {
				this.ticketService.acceptedCase(true);
				sessionStorage.setItem('accepted', 'true');
				this.ticketService.clearCaseRejection();
				this.router.navigate([CUSTOMER]);
			},
			(error) => {
				this.ticketService.clearCase();

				this.router.navigate([HOME]);
			}
		);
	}

	async signOut() {
		this.ticketService.clearCase();
		await this.authService.logout();
	}
}
