import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	ChangeDetectorRef,
	AfterViewChecked,
	HostListener,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ProductViewService } from './product-view.service';
import { ShoppingSessionService } from '../shopping-session/shopping-session-state/shopping-session.service';
import { ShoppingSessionQuery } from '../shopping-session/shopping-session-state/shopping-session.query';
import { Rack } from './Models/rack.model';
import { ProductZone } from './Models/product-zone.model';

@Component({
	selector: 'app-product-view',
	templateUrl: './product-view.component.html',
	styleUrls: ['./product-view.component.css'],
})
export class ProductViewComponent implements OnInit, AfterViewChecked {
	rack$: Observable<Rack> = this.shoppingSessionQuery.rack$;

	@ViewChild('wrapper')
	wrapper: ElementRef;

	productWidth: number;

	constructor(
		private productService: ProductViewService,
		private shoppingSessionService: ShoppingSessionService,
		private shoppingSessionQuery: ShoppingSessionQuery,
		private cdref: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		//this.productService.getStoreProducts();
		this.shoppingSessionService.checkinHitlSession();
	}

	ngAfterViewChecked() {
		this.cdref.detectChanges();
	}

	decreaseQty(unitId: number, shelfIndex: number, productZoneIndex: number) {
		this.shoppingSessionService.decreaseQty(
			unitId,
			shelfIndex,
			productZoneIndex
		);
	}

	increaseQty(unitId: number, shelfIndex: number, productZoneIndex: number) {
		this.shoppingSessionService.increaseQty(
			unitId,
			shelfIndex,
			productZoneIndex
		);
	}

	calculateWidth(pz: ProductZone[], j) {
		const total = pz.filter((p: any) => p.layerId == j).length;
		const margin = 6;
		this.productWidth =
			this.wrapper?.nativeElement?.offsetWidth - total * margin;
		return this.productWidth / total + 'px';
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.productWidth = this.wrapper?.nativeElement?.offsetWidth;
	}
}
