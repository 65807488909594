<div class="rectangle">
	<div class="parent-banner">
		<img class="patterns" src="./assets/img/Patterns.png" alt="" />
		<img class="logo" src="./assets/img/Logo.svg" />
		<p class="waiting-for-cases">
			Please select which flow you are willing to take.
		</p>
		<button
			class="refund-button"
			mat-raised-button
			(click)="setTicketType(refund)"
		>
			REFUND
		</button>
		<button
			class="hitl-button"
			mat-raised-button
			(click)="setTicketType(customerSession)"
		>
			SHOPPING SESSION
		</button>
	</div>
</div>
