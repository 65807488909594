<div
	*ngFor="let camera of overviewCameras$ | async"
	class="cams"
	[ngClass]="camera.id === selectedCamera ? 'highlighted' : null"
>
	<app-camera
		[cam]="camera"
		[height]="14"
		(onSelected)="updateCameraId(camera, $event[0], $event[1])"
	></app-camera>
</div>
