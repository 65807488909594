import { Component, OnInit, HostListener } from '@angular/core';
import LogRocket from 'logrocket';
import { EnvService } from './Services/env-service.service';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	constructor(private titleService: Title, private env: EnvService) {
		this.titleService.setTitle(this.env.title);
	}

	ngOnInit() {
		LogRocket.init(this.env.logrocket);
	}
}
