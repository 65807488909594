export const overviewCameras = [
	{
		id: 48,
		liveStreamUrl:
			'https://5da5a37eb4a8d.streamlock.net/CW_STORE/ACCC8ED66DB3/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66DB3_2020-05-28-08.10.13.925-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED66DB3_2020-05-29-08.00.04.481-CDT_1.mp4',
			},
		],
	},
	{
		id: 54,
		liveStreamUrl:
			'https://5da5a37eb4a8d.streamlock.net/CW_STORE/ACCC8ED663F9/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED663F9_2020-05-28-08.10.16.886-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED663F9_2020-05-29-08.00.04.406-CDT_1.mp4',
			},
		],
	},
	{
		id: 55,
		liveStreamUrl:
			'https://5da5a37eb4a8d.streamlock.net/CW_STORE/ACCC8ED66E3D/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66E3D_2020-05-28-08.10.13.994-CDT_1.mp4',
			},
		],
	},
	{
		id: 52,
		liveStreamUrl:
			'https://5da5a37eb4a8d.streamlock.net/CW_STORE/ACCC8ED66413/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66413_2020-05-29-08.00.03.479-CDT_1.mp4',
			},
		],
	},
	{
		id: 49,
		liveStreamUrl:
			'https://5da5a37eb4a8d.streamlock.net/CW_STORE/ACCC8ED66F1B/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66F1B_2020-05-29-08.00.07.193-CDT_1.mp4',
			},
		],
	},
	{
		id: 51,
		liveStreamUrl:
			'https://5da5a37eb4a8d.streamlock.net/CW_STORE/ACCC8ED66409/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66409_2020-05-28-08.10.16.378-CDT_1.mp4',
			},
		],
	},
];
