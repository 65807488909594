import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseTypeSelectionComponent } from './case-type-selection.component';
import { NavModule } from '../nav/nav.module';
import { AppMaterialModule } from '../app-material/app-material.module';
import { CaseTypeSelectionWrapperComponent } from './case-type-selection-wrapper/case-type-selection-wrapper.component';

@NgModule({
	declarations: [
		CaseTypeSelectionComponent,
		CaseTypeSelectionWrapperComponent,
	],
	imports: [CommonModule, NavModule, AppMaterialModule],
	exports: [CaseTypeSelectionComponent],
})
export class CaseTypeSelectionModule {}
