<h5>SHOPPING CART</h5>

<h3 class="title">ITEM</h3>
<h3 class="quantity-title">Qty</h3>

<hr />
<div class="product-wrapper">
	<div *ngIf="(cart$ | async).length === 0">
		<p class="empty-cart">EMPTY CART</p>
	</div>
	<div *ngFor="let zone of cart$ | async" class="product">
		<div class="row">
			<div class="col-8 product-name">
				<p>{{ zone.product?.name }}</p>
			</div>
			<div class="col-4">
				<div
					class="input-button-group input-group button-group justify-content-end"
				>
					<input
						type="button"
						value="-"
						class="button-minus"
						data-field="quantity"
						[disabled]="zone.cartQty === 0"
						(click)="decreaseQty(zone)"
					/>
					<input
						type="number"
						disabled="disabled"
						[value]="zone.cartQty"
						name="quantity"
						class="quantity-field"
					/>
					<input
						type="button"
						value="+"
						class="button-plus"
						data-field="quantity"
						(click)="increaseQty(zone)"
					/>
				</div>
			</div>
		</div>
	</div>
</div>

<hr class="bottom-hr" />
<div class="row">
	<div class="col-4">
		<button mat-raised-button class="dark-button" (click)="nonShopper()">
			Non-Shopper
		</button>
	</div>
	<div class="col-4">
		<button mat-raised-button class="dark-button" (click)="clearAll()">
			Cear All
		</button>
	</div>
	<div class="col-4">
		<button mat-raised-button class="checkout-button" (click)="checkout()">
			Checkout
		</button>
	</div>
</div>
