<div class="page-wrapper">
	<div class="login-wrapper">
		<div class="rectangle">
			<div class="parent-banner">
				<img class="patterns" src="./assets/img/Patterns.png" alt="" />
				<img class="logo" src="./assets/img/Logo.svg" />
				<p class="hitl-tool-log-in">HITL TOOL LOG IN</p>
			</div>

			<button
				class="sso"
				mat-raised-button
				*ngIf="!this.authStore.getValue()?.user"
				(click)="login()"
			>
				Login with SSO
			</button>

			<button
				class="sso"
				mat-raised-button
				*ngIf="this.authStore.getValue()?.user"
				(click)="logout()"
			>
				LOGOUT
			</button>

			<div class="error" *ngIf="this.errMsg">
				<p>{{ this.errMsg }}</p>
			</div>
		</div>
	</div>
</div>
