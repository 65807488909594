import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogRef } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { EnvServiceProvider } from './Providers/env.service.provider';
import { environment } from 'src/environments/environment';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AuthModule } from './auth/auth.module';
import { TicketModule } from './ticket/ticket.module';
import { AgentWaitingModule } from './agent-waiting/agent-waiting.module';
import { AcceptCaseModule } from './accept-case/accept-case.module';
import { CaseCompleteModule } from './case-complete/case-complete.module';
import { ErrorDialogModule } from './error-dialog/error-dialog.module';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';
import { ShoppingSessionModule } from './ShoppingSession/shopping-session/shopping-session.module';
import { CaseTypeSelectionModule } from './case-type-selection/case-type-selection.module';
import { HttpMockInterceptor } from './Interceptors/http-mock-interceptor';
import { HttpRequestInterceptor } from './Interceptors/http-request.interceptor';
import { HttpErrorInterceptor } from './Interceptors/http-error.interceptor';
import { ManagementModule } from './management/management.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpAuthHeaderInterceptor } from './Interceptors/http-auth-header.interceptor';
import { AuthService } from './auth/state/auth.service';
import { HttpLogRocketNewRelicInterceptor } from './Interceptors/http-log-rocket-new-relic.interceptor';
export const isMock = environment.mock;
@NgModule({
	declarations: [AppComponent],
	imports: [
		environment.production ? [] : AkitaNgDevtools.forRoot(),
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		AgentWaitingModule,
		AcceptCaseModule,
		CaseCompleteModule,
		CaseTypeSelectionModule,
		ErrorDialogModule,
		ConfirmDialogModule,
		AuthModule.forRoot(),
		TicketModule.forRoot(),
		ShoppingSessionModule.forRoot(),
		ManagementModule.forRoot(),
		OAuthModule.forRoot(),
		NgxChartsModule,
		ScrollingModule,
	],
	providers: [
		{ provide: MatDialogRef, useValue: {} },
		EnvServiceProvider,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: isMock ? HttpMockInterceptor : HttpRequestInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpAuthHeaderInterceptor,
			multi: true,
		},
		// {
		// 	provide: HTTP_INTERCEPTORS,
		// 	useClass: HttpLogRocketNewRelicInterceptor,
		// 	multi: true,
		// },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private authService: AuthService, private oAuth: OAuthService) {
		//TODO: Improve the way that we handle pre-authorized logins
		if (sessionStorage.getItem('loggedIn')) authService.loginWithSSO();
	}
}
