import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { AuthQuery } from './state/auth.query';
import { AuthService } from './state/auth.service';
import { AuthStore } from './state/auth.store';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from '../app-material/app-material.module';
import { OAuthService } from 'angular-oauth2-oidc';

@NgModule({
	declarations: [LoginComponent],
	imports: [
		AppMaterialModule,
		CommonModule,
		RouterModule.forChild([{ path: '', component: LoginComponent }]),
	],
})
export class AuthModule {
	static forRoot(): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [
				AuthService,
				AuthGuard,
				AuthStore,
				AuthQuery,
				OAuthService,
			],
		};
	}
}
