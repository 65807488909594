import { Injectable } from '@angular/core';
import { TicketState, TicketStore } from './ticket.store';
import { Query, toBoolean } from '@datorama/akita';

@Injectable()
export class TicketQuery extends Query<TicketState> {
	isOnline$ = this.select((state) => toBoolean(state.status === 'online'));
	hasAccessToken$ = this.select((state) =>
		toBoolean(state.accessToken !== null)
	);

	ticketType$ = this.select((state) => state.ticketType);

	customerNotes$ = this.select((state) => state.payload?.refundData.comment);
	refundData$ = this.select((state) => state.payload?.refundData);

	constructor(protected store: TicketStore) {
		super(store);
	}
}
