import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-case-type-selection-wrapper',
	templateUrl: './case-type-selection-wrapper.component.html',
	styleUrls: ['./case-type-selection-wrapper.component.css'],
})
export class CaseTypeSelectionWrapperComponent {
	constructor() {}
}
