<app-nav></app-nav>
<div class="page-wrapper">
	<div class="login-wrapper">
		<div class="rectangle">
			<div class="parent-banner">
				<img class="patterns" src="./assets/img/Patterns.png" alt="" />
				<img class="logo" src="./assets/img/Logo.svg" />
				<p class="waiting-for-cases">Waiting for Cases</p>
				<div class="loader">
					<div class="bubble"></div>
					<div class="bubble"></div>
					<div class="bubble"></div>
					<div class="bubble"></div>
				</div>
			</div>
		</div>
	</div>
</div>
