import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { AppMaterialModule } from '../app-material/app-material.module';

@NgModule({
	declarations: [ConfirmDialogComponent],
	imports: [CommonModule, AppMaterialModule],
})
export class ConfirmDialogModule {}
