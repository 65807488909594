import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainCameraComponent } from './Components/main-camera/main-camera.component';
import { CameraComponent } from './Components/camera/camera.component';
import { CameraSidebarComponent } from './Components/camera-sidebar/camera-sidebar.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { LivestreamPipe } from './Pipes/livestream.pipe';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { NgxPanZoomModule } from 'ngx-panzoom';

@NgModule({
	declarations: [
		MainCameraComponent,
		CameraComponent,
		CameraSidebarComponent,
		LivestreamPipe,
	],
	imports: [
		CommonModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		VgStreamingModule,
		AppMaterialModule,
		NgxPanZoomModule,
	],
	exports: [MainCameraComponent, CameraComponent, CameraSidebarComponent],
})
export class CameraModule {}
