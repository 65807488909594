import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShoppingCartComponent } from './shopping-cart.component';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
	declarations: [ShoppingCartComponent],
	imports: [CommonModule, AppMaterialModule, FontAwesomeModule],
	exports: [ShoppingCartComponent],
})
export class ShoppingCartModule {}
