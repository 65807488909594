import {
	ShoppingSessionState,
	ShoppingSessionStore,
} from './shopping-session.store';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Rack } from './Models/rack.model';

@Injectable()
export class ShoppingSessionQuery extends Query<ShoppingSessionState> {
	currentUnitId$ = this.select((state) => state.unitId);
	shoppingCart$ = this.select((state) => state.shoppingCart);
	overviewCameras$ = this.select((state) => state.overviewCameras);

	rack$ = this.select(
		(state) =>
			state.racks?.filter((rack: Rack) => rack.id === state.unitId)[0]
	);

	allRacks = this.select((state) => state.racks);
	selectedRackCameras$ = this.select((state) => {
		const selectedRackCameras = state.racks?.filter(
			(rack: Rack) => rack.id === state.unitId
		)[0]?.cameras;

		if (selectedRackCameras?.length > 0) {
			return selectedRackCameras
				?.map((src) =>
					state.rackCameras.filter((rc) => rc.id === src.id)
				)
				.reduce((acc, val) => acc.concat(val), []);
		}
		return [];
	});

	selectRackCamerasByUnitId$ = this.select((state) => {
		const newState = state;
		if (!newState.racks || !newState.rackCameras) {
			return;
		}

		const currentRack = newState.racks.filter(
			(rack) => rack.id == newState.unitId
		)[0];
		if (!currentRack) {
			return [];
		}
		const currentRackDescription = currentRack.description;
		if (!currentRackDescription) {
			return;
		}
		const rcCameras = newState.rackCameras.filter((rc) => {
			return rc.description.includes(currentRackDescription);
		});
		return rcCameras;
	});

	selectedCamera$ = this.select((state) => {
		const cameraId: number = state.cameraId;
		const rackCameras = state.rackCameras.filter(
			(rc) => rc.id === cameraId
		);
		const overviewCameras = state.overviewCameras.filter(
			(oc) => oc.id === cameraId
		);
		return rackCameras.length > 0 ? rackCameras[0] : overviewCameras[0];
	});

	constructor(protected store: ShoppingSessionStore) {
		super(store);
	}
}
