<h1 mat-dialog-title>Confirm Action</h1>

<div mat-dialog-content>
	<p>{{ message }}</p>
</div>

<div mat-dialog-actions>
	<button mat-button (click)="onDismiss()">Cancel</button>
	<button mat-raised-button color="primary" (click)="onConfirm()">
		Confirm
	</button>
</div>
