import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ManagementState, ManagementStore } from './management.store';

@Injectable()
export class ManagementSessionQuery extends Query<ManagementState> {
	userProfiles$ = this.select((state) => state.userProfiles);
	roles$ = this.select((state) => state.roles);
	teams$ = this.select((state) => state.teams);
	refunds$ = this.select((state) => state.refunds);

	constructor(protected store: ManagementStore) {
		super(store);
	}
}
