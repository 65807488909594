import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/state/auth.service';
import { TicketService } from '../ticket/state/ticket.service';

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.css'],
})
export class NavComponent {
	isMenuSelected: boolean = false;

	constructor(
		private authService: AuthService,
		private ticketService: TicketService
	) {}

	clickMenu($event) {
		if (!$event.relatedTarget) {
			this.isMenuSelected = false;
		}
	}

	signOut() {
		this.ticketService.clearAccessToken();
		this.ticketService.clearStatus();
		this.ticketService.clearCase();
		this.ticketService.clearTicketType();
		this.authService.logout();
	}
}
