<div class="page-wrapper">
	<div class="row">
		<div class="col-3">
			<mat-card class="mat-elevation-z6 dark-card map-row">
				<app-store-layout></app-store-layout>
			</mat-card>
			<mat-card class="mat-elevation-z7 dark-card rack-camera-row">
				<app-rack-camera></app-rack-camera>
			</mat-card>
		</div>
		<div class="col-7">
			<div class="col-13">
				<mat-card class="mat-elevation-z6 dark-card top-row" style="overflow:hidden;">
					<app-main-camera></app-main-camera>
				</mat-card>
			</div>

			<div class="row camera-test">
				<ng-container *ngIf="(ticketType$ | async) === shoppingSession">
					<div class="col-5 light-card">
						<app-product-view></app-product-view>
					</div>
					<div class="col-3">
						<mat-card
							class="
								mat-elevation-z6
								product-list
								card-height
								dark-card
								bottom-row
							"
						>
							<app-product-list></app-product-list>
						</mat-card>
					</div>
					<div class="col-4">
						<mat-card
							class="
								mat-elevation-z6
								card-height
								dark-card
								bottom-row
							"
						>
							<app-shopping-cart></app-shopping-cart>
						</mat-card>
					</div>
				</ng-container>
				<ng-container *ngIf="(ticketType$ | async) === refund">
					<div class="col-4">
						<mat-card
							class="
								mat-elevation-z6
								product-list
								card-height
								dark-card
								bottom-row
								notes
							"
						>
							<app-customer-notes></app-customer-notes>
						</mat-card>
					</div>
					<div class="col-6">
						<mat-card
							class="
								mat-elevation-z6
								product-list
								card-height
								dark-card
								bottom-row
							"
						>
							<app-picked-products></app-picked-products>
						</mat-card>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="col-2">
			<div class="row">
				<div class="col">
					<mat-card
						class="
							customer-wrapper
							mat-elevation-z6
							dark-card
							customer-image
						"
					>
						<app-customer-image></app-customer-image>
					</mat-card>
				</div>
			</div>
			<div class="row">
				<mat-card class="mat-elevation-z6 dark-card sidebar">
					<app-camera-sidebar></app-camera-sidebar>
				</mat-card>
			</div>
		</div>
	</div>
</div>
