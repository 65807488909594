<h5>PRODUCTS LIST</h5>
<div class="empty-list" *ngIf="(productZones$ | async).length === 0">
	<p>EMPTY LIST</p>
</div>
<div class="list-wrapper" *ngIf="(productZones$ | async).length > 0">
	<div
		*ngFor="let zone of productZones$ | async"
		(click)="updateRack(zone.unitId)"
		class="product-wrapper"
	>
		<div class="algo" *ngIf="zone.isAlgo">
			<p class="algo-text">ALGO</p>
		</div>
		<div class="hitl" *ngIf="zone.isHitl">
			<p class="hitl-text">HITL</p>
		</div>

		<img
			src="{{ zone.product?.imageUrl }}"
			onerror="this.src='./assets/img/no_product.png'"
			class="product-list-image"
		/>
		<label class="name">{{ zone.product?.name }}</label>
		<p class="qty">Qty: {{ zone.cartQty }}</p>
	</div>
</div>
