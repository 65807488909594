import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketService } from './state/ticket.service';
import { TicketStore } from './state/ticket.store';
import { TicketQuery } from './state/ticket.query';
import { AppMaterialModule } from '../app-material/app-material.module';
import { NavModule } from '../nav/nav.module';

@NgModule({
	declarations: [],
	imports: [CommonModule, AppMaterialModule, NavModule],
})
export class TicketModule {
	static forRoot(): ModuleWithProviders<TicketModule> {
		return {
			ngModule: TicketModule,
			providers: [TicketService, TicketStore, TicketQuery],
		};
	}
}
