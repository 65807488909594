<div #wrapper *ngIf="rack$ | async; let rack">
	<h5>Gondola: {{ rack.gondolaName }}</h5>
	<h5>
		Gondola Index: {{ rack.gondolaIndex }} Rack Index: {{ rack.rackIndex }}
	</h5>
	<div *ngFor="let layer of rack.layers; let i = index" class="rack-wrapper">
		<div class="row shelf">
			<ng-container
				*ngFor="let productZone of layer.productZones; let j = index"
			>
				<div
					*ngIf="productZone && productZone.product"
					class="product-wrapper"
					[ngClass]="{
						highlighted: productZone.isHitl === true,
						algoHighlighted: productZone.isAlgo === true
					}"
				>
					<div>
						<div
							class="product-type hitl"
							[ngStyle]="{
								'background-color':
									productZone.isHitl === true
										? '#ff811d'
										: '#00825c'
							}"
							*ngIf="productZone?.isHitl || productZone?.isAlgo"
						>
							<p class="product-type-text">
								{{
									productZone.isHitl === true
										? 'HITL'
										: 'ALGO'
								}}
							</p>
						</div>
						<div class="row">
							<img
								[src]="productZone.product?.imageUrl"
								onerror="this.src='./assets/img/no_product.png'"
								class="img-fluid shelf-image col"
							/>
						</div>
						<p class="product-name">
							{{ productZone.product.name }}
						</p>
						<div
							class="input-button-group input-group button-group"
						>
							<input
								type="button"
								value="-"
								class="button-minus"
								[disabled]="productZone.cartQty === 0"
								(click)="decreaseQty(rack.id, i, productZone.zoneId)"
							/>
							<input
								type="number"
								disabled="disabled"
								[value]="productZone.cartQty"
								name="quantity"
								class="quantity-field"
							/>
							<input
								type="button"
								value="+"
								class="button-plus"
								(click)="increaseQty(rack.id, i, productZone.zoneId)"
							/>
						</div>
					</div>
				</div>
				<div
					*ngIf="productZone === null"
					class="product-wrapper empty-product-wrapper"
					[style.width]="calculateWidth(layer.productZones)"
				>
					<div class="empty-product">
						<p class="empty-product-name">Empty Product Zone</p>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
