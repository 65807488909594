import {
	NgModule,
	ModuleWithProviders,
	CUSTOM_ELEMENTS_SCHEMA,
	NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShoppingSessionService } from './shopping-session-state/shopping-session.service';
import { ShoppingSessionStore } from './shopping-session-state/shopping-session.store';
import { ShoppingSessionQuery } from './shopping-session-state/shopping-session.query';
import { ShoppingSessionComponent } from './shopping-session.component';
import { CustomerImageModule } from '../customer-image/customer-image.module';
import { StoreLayoutModule } from '../store-layout/store-layout.module';
import { ProductViewModule } from '../product-view/product-view.module';
import { ProductListModule } from '../product-list/product-list.module';
import { ShoppingCartModule } from '../shopping-cart/shopping-cart.module';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { ShoppingSessionRoutingModule } from './shopping-session.routing.module';
import { CameraModule } from '../camera/camera.module';
import { CustomerNotesModule } from '../Refund/customer-notes/customer-notes.module';
import { PickedProductsModule } from '../Refund/picked-products/picked-products.module';
import { RackCamerasModule } from '../rack-cameras/rack-cameras.module';

@NgModule({
	declarations: [ShoppingSessionComponent],
	imports: [
		CommonModule,
		AppMaterialModule,
		ShoppingSessionRoutingModule,
		CustomerImageModule,
		StoreLayoutModule,
		ProductViewModule,
		ProductListModule,
		ShoppingCartModule,
		CustomerNotesModule,
		PickedProductsModule,
		RackCamerasModule,
		CameraModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ShoppingSessionModule {
	static forRoot(): ModuleWithProviders<ShoppingSessionModule> {
		return {
			ngModule: ShoppingSessionModule,
			providers: [
				ShoppingSessionService,
				ShoppingSessionStore,
				ShoppingSessionQuery,
			],
		};
	}
}
