<div class="video-div" (click)="onSelected()">
	<vg-player
		#vgPlayer
		(onPlayerReady)="onPlayerReady($event)"
		[style.max-height.vh]="height"
	>
		<vg-controls #controls style="height: 16px; align-items: center;">
			<vg-time-display
				[vgProperty]="'current'"
				[vgFormat]="'mm:ss'"
			></vg-time-display>
			<vg-time-display
				[vgProperty]="'total'"
				[vgFormat]="'mm:ss'"
			></vg-time-display>
		</vg-controls>
		<video
			#video
			#media
			[vgMedia]="media"
			[vgHls]="cam?.liveStreamUrl"
			id="{{ cam?.id }}"
			preload="auto"
			playsinline
			webkit-playsinline
			crossorigin
		></video>
	</vg-player>
</div>
