export const rackCameras = [
	{
		id: 61,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED4C8F1/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED4C8F1_2020-05-29-08.00.03.658-CDT_1.mp4',
			},
		],
	},
	{
		id: 66,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED66E73/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66E73_2020-05-29-08.00.07.395-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED66E73_2020-05-29-08.02.47.460-CDT_1.mp4',
			},
		],
	},
	{
		id: 84,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED4C877/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED4C877_2020-05-29-08.05.43.913-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED4C877_2020-05-29-08.00.06.722-CDT_1.mp4',
			},
		],
	},
	{
		id: 70,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED66E48/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66E48_2020-05-29-08.05.48.108-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED66E48_2020-05-29-08.02.43.065-CDT_1.mp4',
			},
		],
	},
	{
		id: 68,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED66E49/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66E49_2020-05-29-08.03.58.623-CDT_1.mp4',
			},
		],
	},
	{
		id: 71,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED4C871/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED4C871_2020-05-29-08.00.07.454-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED4C871_2020-05-29-08.03.57.419-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED4C871_2020-05-29-08.03.12.897-CDT_1.mp4',
			},
		],
	},
	{
		id: 74,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED50D70/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED50D70_2020-05-29-08.05.56.477-CDT_1.mp4',
			},
		],
	},
	{
		id: 73,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED66E58/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66E58_2020-05-29-08.04.43.259-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED66E58_2020-05-29-08.00.03.910-CDT_1.mp4',
			},
		],
	},
	{
		id: 76,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED4C828/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED4C828_2020-05-29-08.00.04.701-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED4C828_2020-05-29-08.05.50.840-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED4C828_2020-05-29-08.02.33.278-CDT_1.mp4',
			},
		],
	},
	{
		id: 78,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED66E1C/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66E1C_2020-05-29-08.03.56.834-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED66E1C_2020-05-29-08.00.06.759-CDT_1.mp4',
			},
		],
	},
	{
		id: 80,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED66C28/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66C28_2020-05-29-08.05.45.284-CDT_1.mp4',
			},
		],
	},
	{
		id: 79,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED66EBA/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66EBA_2020-05-29-08.03.08.267-CDT_1.mp4',
			},
		],
	},
	{
		id: 81,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED66E3E/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66E3E_2020-05-29-08.04.11.069-CDT_1.mp4',
			},
		],
	},
	{
		id: 60,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED66E0D/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66E0D_2020-05-29-07.08.25.509-CDT_1.mp4',
			},
		],
	},
	{
		id: 77,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED50DF5/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED50DF5_2020-05-29-08.04.13.034-CDT_1.mp4',
			},
		],
	},
	{
		id: 75,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED4C805/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED4C805_2020-05-29-08.02.15.257-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED4C805_2020-05-29-08.05.48.057-CDT_1.mp4',
			},
		],
	},
	{
		id: 69,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED66E2A/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66E2A_2020-05-29-08.02.50.096-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED66E2A_2020-05-29-08.00.05.848-CDT_1.mp4',
			},
		],
	},
	{
		id: 65,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED4C163/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED4C163_2020-05-29-08.00.06.647-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED4C163_2020-05-29-08.03.54.454-CDT_1.mp4',
			},
			{
				recordingUrl: 'ACCC8ED4C163_2020-05-29-08.03.13.721-CDT_1.mp4',
			},
		],
	},
	{
		id: 62,
		liveStreamUrl:
			'https://5da7580ebe547.streamlock.net/CW_STORE/ACCC8ED66C77/playlist.m3u8',
		ndvrRecording: [
			{
				recordingUrl: 'ACCC8ED66C77_2020-05-29-08.03.53.904-CDT_1.mp4',
			},
		],
	},
];
