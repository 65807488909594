<mat-toolbar color="primary">
	<mat-toolbar-row>
		<span class="flexExpand"></span>
		<p class="toggle-text">Assign Cases</p>
		<mat-slide-toggle
			class="toggle"
			labelPosition="before"
			(change)="isTakingCases($event)"
		>
		</mat-slide-toggle>
		<div class="space">
			<p class="username">
				{{ this.authService.getUser()?.firstName }}
				{{ this.authService.getUser()?.lastName }}
			</p>
		</div>
		<img
			src="./assets/img/user-placeholder.svg"
			class="avatar-image"
			(click)="isMenuSelected = !isMenuSelected"
		/>
		<div *ngIf="isMenuSelected" class="menu">
			<button mat-icon-button (click)="signOut()">SIGN OUT</button>
		</div>
	</mat-toolbar-row>
</mat-toolbar>
