export const algoCart = {
	id: 10,
	source: 'Main Fusion Server',
	version: '0.9',
	precision: 1,
	createdAt: '2020-05-29T14:59:59.000Z',
	updatedAt: '2020-05-29T14:59:59.000Z',
	sessionId: 'dut4QoQw411',
	algoCartItems: [
		{
			id: 1,
			quantity: 1,
			isPoseEstimationTrigger: null,
			isFreshFoodTrigger: null,
			algoCartId: 10,
			productId: '301082-0-1',
		},
		{
			id: 2,
			quantity: 2,
			isPoseEstimationTrigger: null,
			isFreshFoodTrigger: null,
			algoCartId: 10,
			productId: '300355-0-1',
		},
		{
			id: 3,
			quantity: 3,
			isPoseEstimationTrigger: null,
			isFreshFoodTrigger: null,
			algoCartId: 10,
			productId: '301144-0-1',
		},
	],
};
