import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from './env-service.service';

@Injectable({
	providedIn: 'root',
})
export class SmpService {
	private baseUrl = `${this.env.hitlServiceUrl}`;
	//private baseUrl = 'http://localhost:3000/v1';

	constructor(private env: EnvService, private http: HttpClient) {}

	async getStoreIdByStoreCode(storeCode: string): Promise<any> {
		return await this.http
			.get(`${this.baseUrl}/store/store-id/${storeCode}`)
			.toPromise();
	}
}
