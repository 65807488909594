import { Injectable, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/Services/env-service.service';
import { TicketService } from 'src/app/ticket/state/ticket.service';
import { ShoppingSessionService } from '../shopping-session/shopping-session-state/shopping-session.service';
import { Camera } from './Models/camera.model';
import { Subject } from 'rxjs';
import { IPlayable } from '@videogular/ngx-videogular/core';

@Injectable({
	providedIn: 'root',
})
export class CameraService {
	private baseUrl = `${this.env.hitlServiceUrl}`;
	CAMERA_SYNC_KEY = 'sync';
	cameraAPI: any = new Array();
	apiCount = 0;
	isPaused = false;
	initialLoad = true;
	duration: number;
	private cams: Camera[] = new Array();

	constructor(
		private env: EnvService,
		private http: HttpClient,
		private ticketService: TicketService,
		private shoppingSessionService: ShoppingSessionService
	) {
		sessionStorage.setItem(this.CAMERA_SYNC_KEY, '0');
	}

	private mainVideoElementSubject = new Subject<
		[HTMLVideoElement, IPlayable, Camera]
	>();
	mainVideoElement$ = this.mainVideoElementSubject.asObservable();

	setMainVideoElement(
		videoElement: HTMLVideoElement,
		playable: IPlayable,
		camera: Camera
	) {
		this.mainVideoElementSubject.next([videoElement, playable, camera]);
	}

	getOverviewCameras(): void {
		const storeId = this.ticketService.getStoreId();
		const hitlSessionId = this.ticketService.getAccessToken();
		this.http
			.get<Camera[]>(
				`${this.baseUrl}/store/${storeId}/hitlSessionId/${hitlSessionId}/overview-cameras`
			)
			.subscribe((cameras: Camera[]) => {
				if (cameras.length > 0) {
					this.shoppingSessionService.setCameraId(cameras[0].id);
					this.shoppingSessionService.setOverviewCameras(cameras);
				}
			});
	}

	getRackCameras(): void {
		// const storeId = this.ticketService.getStoreId();
		// const hitlSessionId = this.ticketService.getAccessToken();
		// this.http
		// 	.get<Camera[]>(
		// 		`${this.baseUrl}/store/${storeId}/hitlSessionId/${hitlSessionId}/area-cameras`
		// 	)
		// 	.subscribe((cameras: Camera[]) => {
		// 		this.shoppingSessionService.setRackCameras(cameras);
		// 	});
	}

	setCameraAPI(api: any) {
		this.cameraAPI.push(api);
	}

	getCameraAPI() {
		return this.cameraAPI;
	}

	playing = false;
	playPauseAllVideos() {
		this.playing = !this.playing;
		if (this.playing) this.playAllVideos();
		else this.pauseAllVideos();
		return this.playing;
	}

	playAllVideos() {
		this.cameraAPI.forEach((api) => api.play());
		this.sync();
	}

	pauseAllVideos() {
		this.cameraAPI.forEach((api) => api.pause());
	}

	syncAllVideos(time: number) {
		this.cameraAPI.forEach((api) => (api.currentTime = time));
	}

	sync() {
		const time = Number(sessionStorage.getItem(this.CAMERA_SYNC_KEY));
		this.cameraAPI.forEach((api) => api.seekTime(time));
	}

	changePlaybackRate(playbackRate: number) {
		this.cameraAPI.forEach((api) => {
			api.playbackRate = playbackRate;
		});
	}

	keyboardPause() {
		this.apiCount += 1;
		if (this.apiCount === this.cameraAPI.length - 1) {
			this.isPaused = true;
			this.apiCount = 0;
			this.cameraAPI.forEach((api) => api.pause());
		}
	}

	keyboardPlay() {
		this.apiCount += 1;
		if (this.apiCount === this.cameraAPI.length - 1) {
			this.isPaused = false;
			this.apiCount = 0;
			this.cameraAPI.forEach((api) => api.play());
		}
	}

	setDuration(duration: number) {
		if (this.initialLoad === true) {
			this.duration = duration;
			this.initialLoad = false;
		}
	}

	addOrReplaceCam(item) {
		const found = this.cams.findIndex((cam) => cam.id === item.id);
		found > -1 ? (this.cams[found] = item) : this.cams.push(item);
	}
}
