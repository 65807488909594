import { Injectable } from '@angular/core';
import {
	ErrorDialogComponent,
	ErrorDialogData,
} from './error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root',
})
export class ErrorDialogService {
	constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

	showError(errorData: ErrorDialogData): void {
		this.snackBar
			.open(errorData.title, 'view', {
				duration: 3000,
				horizontalPosition: 'start',
				verticalPosition: 'top',
			})
			.onAction()
			.subscribe(() => {
				this.dialog.open(ErrorDialogComponent, {
					width: '732px',
					height: '494px',
					data: errorData,
					closeOnNavigation: false, // Required for SSO login - The SSO library does lots of internal hash-redirects which may close any dialog boxes
				});
			});
	}
}
