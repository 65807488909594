import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerNotesComponent } from './customer-notes.component';

@NgModule({
	declarations: [CustomerNotesComponent],
	imports: [CommonModule],
	exports: [CustomerNotesComponent],
})
export class CustomerNotesModule {}
