import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/state/auth.service';

@Injectable()
export class HttpAuthHeaderInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		const accessToken = this.authService.getUser()?.accessToken;

		const contentType =
			request.headers.get('Content-Type') ?? 'application/json';
		const xApiKey =
			request.headers.get('x-api-key') ??
			(accessToken ? `Bearer ${accessToken}` : null);

		let headers = request.headers.set('Content-Type', contentType);

		if (xApiKey) headers = headers.set('x-api-key', xApiKey);

		const authorizedRequest = request.clone({ headers });
		return next.handle(authorizedRequest);
	}
}
