import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-accept-case-wrapper',
	templateUrl: './accept-case-wrapper.component.html',
	styleUrls: ['./accept-case-wrapper.component.css'],
})
export class AcceptCaseWrapperComponent {
	constructor() {}
}
