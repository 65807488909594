<div class="row">
	<div class="col-6">
		<img
			class="logo img-fluid"
			src="../../../../assets/img/Hitl-logo.svg"
		/>
	</div>
</div>
<div class="row">
	<button
		mat-icon-button
		(click)="reloadAllVideos()"
		class="more-time-btn">
		<mat-icon matTooltip="reload all videos">refresh</mat-icon></button>
	<button
		mat-icon-button
		(click)="requestMoreMinutes(1)"
		class="more-time-btn">+1</button>
	<button
		mat-icon-button
		(click)="requestMoreMinutes(5)"
		class="more-time-btn">+5</button>
	<button
		mat-icon-button
		(click)="requestMoreMinutes(30)"
		class="more-time-btn">+30</button>
	<div class="col-4">
		<button *ngIf="(ticketType$ | async) === refund" class="refund-button">
			REFUND
		</button>
	</div>
</div>

<div class="row" *ngIf="customerImages$ | async as customerImages">
	<div>
		<img
			src="{{ customerImages.entranceSnapshotUrl }}"
			(error)="handleError($event)"
			class="img-fluid"
			alt="customer entrance image"
			width="100%"
		/>
	</div>
</div>
