export const products = [
	{
		id: 71,
		rackIndex: 'Rack 02',
		description: 'Enter Description Here',
		left: 266.032,
		top: 437.007,
		width: 35.9653,
		height: 130.6,
		angle: 269.324,
		createdAt: '2019-10-17T19:46:31.000Z',
		updatedAt: '2020-06-04T01:26:37.000Z',
		gondolaId: 46,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 125,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2019-11-04T05:06:30.000Z',
					updatedAt: '2019-11-04T05:06:30.000Z',
					rackId: 71,
					storeProduct: {
						id: 22,
						totalStoreQuantity: 2,
						productId: 21,
						storeId: 1,
						product: {
							id: 21,
							productId: '303942-0-1',
							productName:
								'Lenny & Larry Chocolate Chip Cookie 4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/303942_chocolatechip_lenny_larry_scroll1.png',
							productWeight: '125',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00787692834617',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 126,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-04T05:10:06.000Z',
					updatedAt: '2019-11-04T05:10:06.000Z',
					rackId: 71,
					storeProduct: {
						id: 23,
						totalStoreQuantity: 2000,
						productId: 22,
						storeId: 1,
						product: {
							id: 22,
							productId: '301144-0-1',
							productName:
								'Lenny & Larry Double Chocolate Cookie 4oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/303942_chocolatechip_lenny_larry_scroll1.png',
							productWeight: '114',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00787692835331',
							createdAt: null,
							updatedAt: '2020-06-03T03:06:54.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 127,
					shelfIndex: '1',
					zoneId: '3',
					createdAt: '2019-11-04T05:11:39.000Z',
					updatedAt: '2019-11-04T05:11:39.000Z',
					rackId: 71,
					storeProduct: {
						id: 24,
						totalStoreQuantity: 0,
						productId: 23,
						storeId: 1,
						product: {
							id: 23,
							productId: '308144-0-1',
							productName:
								'Quest Protein Cookie Peanut Butter  2.04oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/308144_COOKIE_PRTN_PNT_BTTR_scroll1.jpg',
							productWeight: '64',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00888849006038',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 351,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-07T03:07:06.000Z',
					updatedAt: '2019-11-07T03:07:06.000Z',
					rackId: 71,
					storeProduct: {
						id: 182,
						totalStoreQuantity: 0,
						productId: 181,
						storeId: 1,
						product: {
							id: 181,
							productId: '248536-0-1',
							productName: 'Gatorade Zero Berry 28oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/248536_GatoradeZeroBerry_28oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052000043143',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 352,
					shelfIndex: '1',
					zoneId: '5',
					createdAt: '2019-11-07T03:07:27.000Z',
					updatedAt: '2019-11-07T03:07:27.000Z',
					rackId: 71,
					storeProduct: {
						id: 183,
						totalStoreQuantity: 14,
						productId: 182,
						storeId: 1,
						product: {
							id: 182,
							productId: '243294-0-1',
							productName: 'Gatorade ZERO Orange 28oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/243294_GatoradeZeroOrange_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052000042320',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 317,
					shelfIndex: '1',
					zoneId: '6',
					createdAt: '2019-11-06T01:40:45.000Z',
					updatedAt: '2019-11-06T01:40:45.000Z',
					rackId: 71,
					storeProduct: {
						id: 153,
						totalStoreQuantity: 23,
						productId: 152,
						storeId: 1,
						product: {
							id: 152,
							productId: '242778-0-1',
							productName: 'Ozarka Spring Water 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/242778_OzarkaSpringWater_20oz_scroll1.png',
							productWeight: '620',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00022592008578',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 129,
					shelfIndex: '1',
					zoneId: '7',
					createdAt: '2019-11-04T05:13:32.000Z',
					updatedAt: '2019-11-04T05:13:32.000Z',
					rackId: 71,
					storeProduct: {
						id: 25,
						totalStoreQuantity: 16,
						productId: 24,
						storeId: 1,
						product: {
							id: 24,
							productId: '308142-0-1',
							productName:
								'Quest Protein Cookie Chocolate Chip 2.08oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/308142_COOKIE_PRTN_CHOC_CHIP_scroll1.jpg',
							productWeight: '63',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '10888849006097',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 130,
					shelfIndex: '1',
					zoneId: '8',
					createdAt: '2019-11-04T05:13:58.000Z',
					updatedAt: '2019-11-04T05:13:58.000Z',
					rackId: 71,
					storeProduct: {
						id: 26,
						totalStoreQuantity: 0,
						productId: 25,
						storeId: 1,
						product: {
							id: 25,
							productId: '305970-0-1',
							productName:
								'Rhythm SuperFoods Crunchy Pineapple Chips 0.42oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/305970_RhythmSuprFoodsCrunchyPinapplChips0.42oz_scroll1.jpg',
							productWeight: '12',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00829739820839',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 134,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2019-11-04T05:17:05.000Z',
					updatedAt: '2019-11-04T05:17:05.000Z',
					rackId: 71,
					storeProduct: {
						id: 29,
						totalStoreQuantity: 0,
						productId: 28,
						storeId: 1,
						product: {
							id: 28,
							productId: '170299-0-1',
							productName: '7-Select Mini Powdered Donut 6 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/170299_CDonuts_PwdrSgr_6Pk_scroll1.jpg',
							productWeight: '94',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548514297',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 133,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-04T05:15:56.000Z',
					updatedAt: '2019-11-04T05:15:56.000Z',
					rackId: 71,
					storeProduct: {
						id: 28,
						totalStoreQuantity: 0,
						productId: 27,
						storeId: 1,
						product: {
							id: 27,
							productId: '170289-0-1',
							productName:
								'7-Select Mini Chocolate Donut 6 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/170289_CDonuts_Choc_6Pk_scroll1.jpg',
							productWeight: '108',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548514273',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:53.000Z',
						},
					},
				},
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 131,
					shelfIndex: '2',
					zoneId: '4',
					createdAt: '2019-11-04T05:14:29.000Z',
					updatedAt: '2019-11-04T05:14:29.000Z',
					rackId: 71,
					storeProduct: {
						id: 27,
						totalStoreQuantity: 2,
						productId: 26,
						storeId: 1,
						product: {
							id: 26,
							productId: '170286-0-1',
							productName: '7-Select Crunch Mini Donuts 6 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/170286_CDonuts_Crunch_6Pk_scroll1.jpg',
							productWeight: '106',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548514242',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 197,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-04T11:21:04.000Z',
					updatedAt: '2019-11-04T11:21:04.000Z',
					rackId: 71,
					storeProduct: {
						id: 71,
						totalStoreQuantity: 44,
						productId: 70,
						storeId: 1,
						product: {
							id: 70,
							productId: '140034-0-1',
							productName: "Reese's PB King Size 2.8oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140034_reeses_king_size_scroll1.png',
							productWeight: '60',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '20034000480009',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 325,
					shelfIndex: '3',
					zoneId: '2',
					createdAt: '2019-11-06T01:46:31.000Z',
					updatedAt: '2019-11-06T01:46:31.000Z',
					rackId: 71,
					storeProduct: {
						id: 160,
						totalStoreQuantity: 19,
						productId: 159,
						storeId: 1,
						product: {
							id: 159,
							productId: '246032-0-1',
							productName:
								'7-Select Sparkling Cherry Lime Water 16.9oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/246032_7SSpklngWtrCherryLime_16.9oz_scroll1.png',
							productWeight: '530',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548601423',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 135,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-04T05:17:44.000Z',
					updatedAt: '2019-11-04T05:17:44.000Z',
					rackId: 71,
					storeProduct: {
						id: 30,
						totalStoreQuantity: 8,
						productId: 29,
						storeId: 1,
						product: {
							id: 29,
							productId: '212104-0-1',
							productName:
								'Quaker Express Maple & Brown Sugar 1.69oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/212104_QuakerExpress_MapleBrnSgr_scroll1.png',
							productWeight: '61',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00030000562048',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 136,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2019-11-04T05:18:11.000Z',
					updatedAt: '2019-11-04T05:18:11.000Z',
					rackId: 71,
					storeProduct: {
						id: 31,
						totalStoreQuantity: 0,
						productId: 30,
						storeId: 1,
						product: {
							id: 30,
							productId: '173691-0-1',
							productName: '7-Select Mantecada Muffin 4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/173691_7_S_HspBkry_MantecadaMuffin_4z_scroll1.png',
							productWeight: '142',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548592417',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 140,
					shelfIndex: '4',
					zoneId: '1',
					createdAt: '2019-11-04T05:20:32.000Z',
					updatedAt: '2019-11-04T05:20:32.000Z',
					rackId: 71,
					storeProduct: {
						id: 33,
						totalStoreQuantity: 0,
						productId: 32,
						storeId: 1,
						product: {
							id: 32,
							productId: '172864-0-1',
							productName:
								'7-Select Strawberry Cheese Danish 4.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/172864_Danishes_StrawberryCheese_scroll1.jpg',
							productWeight: '130',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548619268',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 223,
					shelfIndex: '4',
					zoneId: '2',
					createdAt: '2019-11-04T14:31:06.000Z',
					updatedAt: '2019-11-04T14:31:06.000Z',
					rackId: 71,
					storeProduct: {
						id: 85,
						totalStoreQuantity: 74,
						productId: 84,
						storeId: 1,
						product: {
							id: 84,
							productId: '141098-0-1',
							productName: 'Snickers Bar 1.86oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/141098_SnickersBar_Scroll1.png',
							productWeight: '52',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00040000001027',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 224,
					shelfIndex: '4',
					zoneId: '3',
					createdAt: '2019-11-04T14:31:51.000Z',
					updatedAt: '2019-11-04T14:31:51.000Z',
					rackId: 71,
					storeProduct: {
						id: 86,
						totalStoreQuantity: 45,
						productId: 85,
						storeId: 1,
						product: {
							id: 85,
							productId: '141477-0-1',
							productName:
								"Hershey's Cookies & Cream King Size 1.79oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/141477_Hersheys_Cookies_Cream_scroll1.png',
							productWeight: '76',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00034000196067',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 137,
					shelfIndex: '4',
					zoneId: '4',
					createdAt: '2019-11-04T05:19:16.000Z',
					updatedAt: '2019-11-04T05:19:16.000Z',
					rackId: 71,
					storeProduct: {
						id: 32,
						totalStoreQuantity: 0,
						productId: 31,
						storeId: 1,
						product: {
							id: 31,
							productId: '172831-0-1',
							productName: "7-Select Apple n' Maple Danish 4.5oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/172831_7SApplenMapleDanish_SCROLL1.jpg',
							productWeight: '107',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548619251',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
		],
	},
	{
		id: 70,
		rackIndex: 'Rack 01',
		description: 'Rack number 1',
		left: 264.921,
		top: 401.08,
		width: 30,
		height: 129.051,
		angle: 90.3157,
		createdAt: '2019-10-17T19:46:19.000Z',
		updatedAt: '2020-06-04T01:27:03.000Z',
		gondolaId: 46,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 293,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2019-11-06T01:26:21.000Z',
					updatedAt: '2019-11-06T01:26:21.000Z',
					rackId: 70,
					storeProduct: {
						id: 130,
						totalStoreQuantity: 30,
						productId: 129,
						storeId: 1,
						product: {
							id: 129,
							productId: '174192-0-1',
							productName: '7-Select Hard Boiled Eggs',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/174192_Eggs_film_HR_scroll1.png',
							productWeight: '87',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '149',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48423,
							upc: '00052548556952',
							createdAt: null,
							updatedAt: '2020-06-03T21:26:49.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 296,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-06T01:27:22.000Z',
					updatedAt: '2019-11-06T01:27:22.000Z',
					rackId: 70,
					storeProduct: {
						id: 133,
						totalStoreQuantity: 0,
						productId: 132,
						storeId: 1,
						product: {
							id: 132,
							productId: '170929-0-1',
							productName: 'Mango Cup',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/170929_MangoCup_scroll1.jpg',
							productWeight: '230',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548520700',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 142,
					shelfIndex: '1',
					zoneId: '3',
					createdAt: '2019-11-04T05:22:57.000Z',
					updatedAt: '2019-11-04T05:22:57.000Z',
					rackId: 70,
					storeProduct: {
						id: 34,
						totalStoreQuantity: 0,
						productId: 33,
						storeId: 1,
						product: {
							id: 33,
							productId: '172896-0-1',
							productName:
								'7-Select Cherry and Cheese Danish 4.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/172896_7SCherrynCheeseDanish_SCROLL1.jpg',
							productWeight: '124',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548619275',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 170,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-04T10:34:40.000Z',
					updatedAt: '2019-11-04T10:34:40.000Z',
					rackId: 70,
					storeProduct: {
						id: 53,
						totalStoreQuantity: 0,
						productId: 52,
						storeId: 1,
						product: {
							id: 52,
							productId: '303863-0-1',
							productName:
								'Kellogg’s Rice Krispies Treats Big Bar 2.2oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/303863_KLG_RKT_BigBarOrig_2.20z_scroll1.jpg',
							productWeight: '57',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00038000590962',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 143,
					shelfIndex: '1',
					zoneId: '5',
					createdAt: '2019-11-04T05:23:35.000Z',
					updatedAt: '2019-11-04T05:23:35.000Z',
					rackId: 70,
					storeProduct: {
						id: 35,
						totalStoreQuantity: 7,
						productId: 34,
						storeId: 1,
						product: {
							id: 34,
							productId: '302156-0-1',
							productName:
								'BelVita Blueberry Breakfast Biscuits 1.76oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/302156_Belvita_BlBry_Brkfst_Bsct1.76z_scroll1.jpg',
							productWeight: '50',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00044000028282',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 303,
					shelfIndex: '1',
					zoneId: '6',
					createdAt: '2019-11-06T01:33:36.000Z',
					updatedAt: '2019-11-06T01:33:36.000Z',
					rackId: 70,
					storeProduct: {
						id: 140,
						totalStoreQuantity: 0,
						productId: 139,
						storeId: 1,
						product: {
							id: 139,
							productId: '243388-0-1',
							productName: 'Perrier Sparkling Lime .5L',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/243388_perrier_lime_.5L_scroll1.png',
							productWeight: '545',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00074780439961',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 304,
					shelfIndex: '1',
					zoneId: '7',
					createdAt: '2019-11-06T01:33:53.000Z',
					updatedAt: '2019-11-06T01:33:53.000Z',
					rackId: 70,
					storeProduct: {
						id: 141,
						totalStoreQuantity: 0,
						productId: 140,
						storeId: 1,
						product: {
							id: 140,
							productId: '172421-0-1',
							productName: 'CaliCobbSaladwChkn',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '319',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 120,
					shelfIndex: '1',
					zoneId: '8',
					createdAt: '2019-10-29T05:21:44.000Z',
					updatedAt: '2020-06-03T21:06:19.000Z',
					rackId: 70,
					storeProduct: {
						id: 21,
						totalStoreQuantity: 2,
						productId: 20,
						storeId: 1,
						product: {
							id: 20,
							productId: '305899-0-1',
							productName:
								'Lenny & Larry White Chocolate Macadamia Cookie 4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/305899_lenny_larry_whitechooclate_mac_scroll1.png',
							productWeight: '124',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00787692835607',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 168,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2019-11-04T10:30:21.000Z',
					updatedAt: '2019-11-04T10:30:21.000Z',
					rackId: 70,
					storeProduct: {
						id: 52,
						totalStoreQuantity: 0,
						productId: 51,
						storeId: 1,
						product: {
							id: 51,
							productId: '303821-0-1',
							productName:
								"Kellogg's Rice Krispies Treat Double Chocolatey Chunk Big Bar",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/303821_KLGRKTBigBarDblChoc_scroll1.png',
							productWeight: '86',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00038000590948',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 144,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-04T05:25:11.000Z',
					updatedAt: '2019-11-04T05:25:11.000Z',
					rackId: 70,
					storeProduct: {
						id: 36,
						totalStoreQuantity: 7,
						productId: 35,
						storeId: 1,
						product: {
							id: 35,
							productId: '304189-0-1',
							productName:
								'BelVita Cinnamon and Brown Sugar 1.8oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/304189_BelVita_Cinnamon_and_Brown_Sugar_1.8oz_scroll1.png',
							productWeight: '50',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00044000031930',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 306,
					shelfIndex: '2',
					zoneId: '3',
					createdAt: '2019-11-06T01:35:29.000Z',
					updatedAt: '2019-11-06T01:35:29.000Z',
					rackId: 70,
					storeProduct: {
						id: 142,
						totalStoreQuantity: 0,
						productId: 141,
						storeId: 1,
						product: {
							id: 141,
							productId: '174939-0-1',
							productName:
								'Mediterranean Style Salad with Chicken',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/174939_MedSaladwithChicken_scroll1.jpg',
							productWeight: '293',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 308,
					shelfIndex: '2',
					zoneId: '4',
					createdAt: '2019-11-06T01:36:07.000Z',
					updatedAt: '2019-11-06T01:36:07.000Z',
					rackId: 70,
					storeProduct: {
						id: 144,
						totalStoreQuantity: 12,
						productId: 143,
						storeId: 1,
						product: {
							id: 143,
							productId: '241401-0-1',
							productName: 'Perrier Sparkling Original 500ml',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241401_perrier_original_500ml_scroll1.png',
							productWeight: '545',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00074780911382',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 310,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-06T01:37:10.000Z',
					updatedAt: '2019-11-06T01:37:10.000Z',
					rackId: 70,
					storeProduct: {
						id: 146,
						totalStoreQuantity: 3,
						productId: 145,
						storeId: 1,
						product: {
							id: 145,
							productId: '242052-0-1',
							productName: '7-Select Water 20oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/242052_7Select_Water_20oz_480_scroll1.jpg',
							productWeight: '620',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548575243',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 311,
					shelfIndex: '3',
					zoneId: '2',
					createdAt: '2019-11-06T01:37:23.000Z',
					updatedAt: '2019-11-06T01:37:23.000Z',
					rackId: 70,
					storeProduct: {
						id: 147,
						totalStoreQuantity: 0,
						productId: 146,
						storeId: 1,
						product: {
							id: 146,
							productId: '241846-0-1',
							productName: '7-Select Pure Water 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241846_7_SelectPureWtr_20z_scroll1.png',
							productWeight: '645',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548575236',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 312,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-06T01:37:35.000Z',
					updatedAt: '2019-11-06T01:37:35.000Z',
					rackId: 70,
					storeProduct: {
						id: 148,
						totalStoreQuantity: 0,
						productId: 147,
						storeId: 1,
						product: {
							id: 147,
							productId: '171795-0-1',
							productName:
								'Smoked Turkey & Monterey Jack Cheese Sandwich',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/171795_Smoked_Turkey___Jack_CheeseSouthwest_Mayo_scroll1.png',
							productWeight: '270',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00471101717959',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 314,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2019-11-06T01:37:49.000Z',
					updatedAt: '2019-11-06T01:37:49.000Z',
					rackId: 70,
					storeProduct: {
						id: 150,
						totalStoreQuantity: 0,
						productId: 149,
						storeId: 1,
						product: {
							id: 149,
							productId: '171804-0-1',
							productName: 'ChickenSalad',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '238',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 145,
					shelfIndex: '4',
					zoneId: '1',
					createdAt: '2019-11-04T05:27:45.000Z',
					updatedAt: '2019-11-04T05:27:45.000Z',
					rackId: 70,
					storeProduct: {
						id: 37,
						totalStoreQuantity: 0,
						productId: 36,
						storeId: 1,
						product: {
							id: 36,
							productId: '210191-0-1',
							productName: 'Honey Nut Cheerios Cereal Cup 1.8oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/210191_hn_cheerios_cup_scroll1.png',
							productWeight: '56',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00016000138988',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 147,
					shelfIndex: '4',
					zoneId: '2',
					createdAt: '2019-11-04T05:30:24.000Z',
					updatedAt: '2019-11-04T05:30:24.000Z',
					rackId: 70,
					storeProduct: {
						id: 39,
						totalStoreQuantity: 6,
						productId: 38,
						storeId: 1,
						product: {
							id: 38,
							productId: '210876-0-1',
							productName:
								"Kellogg's PopTart Frosted Blueberry 3.67oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/210876_KLGPopTartFrostedBLberry_scroll1.png',
							productWeight: '104',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00038000310324',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 146,
					shelfIndex: '4',
					zoneId: '3',
					createdAt: '2019-11-04T05:28:26.000Z',
					updatedAt: '2019-11-04T05:28:26.000Z',
					rackId: 70,
					storeProduct: {
						id: 38,
						totalStoreQuantity: 0,
						productId: 37,
						storeId: 1,
						product: {
							id: 37,
							productId: '210874-0-1',
							productName:
								"Kellogg's PopTart Frosted Brown Sugar 3.52oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/210874_KLGPopTartFrsdBnSgr_scroll1.png',
							productWeight: '100',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00038000311314',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 148,
					shelfIndex: '4',
					zoneId: '4',
					createdAt: '2019-11-04T05:32:54.000Z',
					updatedAt: '2019-11-04T05:32:54.000Z',
					rackId: 70,
					storeProduct: {
						id: 40,
						totalStoreQuantity: 0,
						productId: 39,
						storeId: 1,
						product: {
							id: 39,
							productId: '210878-0-1',
							productName:
								"Kellogg's PopTart Frosted Strawberry 3.67oz",
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/210878_Kellogg_s_PopTart_Frosted_Strawberry_scroll1.jpg',
							productWeight: '104',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00038000317316',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
		],
	},
	{
		id: 80,
		rackIndex: 'Rack 01',
		description: 'Rack number 1',
		left: 266.842,
		top: 276.844,
		width: 37.7333,
		height: 129.105,
		angle: 89.5057,
		createdAt: '2019-10-17T19:53:14.000Z',
		updatedAt: '2020-06-04T01:27:43.000Z',
		gondolaId: 51,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 150,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2019-11-04T05:37:16.000Z',
					updatedAt: '2019-11-04T05:37:16.000Z',
					rackId: 80,
					storeProduct: {
						id: 42,
						totalStoreQuantity: 6,
						productId: 41,
						storeId: 1,
						product: {
							id: 41,
							productId: '210303-0-1',
							productName:
								"Kellogg's Frosted Flakes Cereal Cup 2.1oz",
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/210303_frosted_flakes_cup_2.1_scroll1.jpg',
							productWeight: '60',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00038000124686',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 171,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-04T10:37:20.000Z',
					updatedAt: '2019-11-04T10:37:20.000Z',
					rackId: 80,
					storeProduct: {
						id: 54,
						totalStoreQuantity: 0,
						productId: 53,
						storeId: 1,
						product: {
							id: 53,
							productId: '171161-0-1',
							productName: 'Hostess Ding Dong 2 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/171161_Hst_Ding_Dong_2.55z2ct_scroll1.png',
							productWeight: '72',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00888109010027',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 178,
					shelfIndex: '1',
					zoneId: '3',
					createdAt: '2019-11-04T10:52:17.000Z',
					updatedAt: '2019-11-04T10:52:17.000Z',
					rackId: 80,
					storeProduct: {
						id: 56,
						totalStoreQuantity: 4,
						productId: 55,
						storeId: 1,
						product: {
							id: 55,
							productId: '173225-0-1',
							productName: '7-Select GoYum Madeleines 3 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/173225_7Select_Madeleines_scroll1.jpg',
							productWeight: '65',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548565060',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 177,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-04T10:49:39.000Z',
					updatedAt: '2019-11-04T10:49:39.000Z',
					rackId: 80,
					storeProduct: {
						id: 55,
						totalStoreQuantity: 0,
						productId: 54,
						storeId: 1,
						product: {
							id: 54,
							productId: '173739-0-1',
							productName: '7-Select Chocolate Cupcakes 2 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/173739_Cupcake-Chocolate_scroll1.jpg',
							productWeight: '125',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548519612',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 507,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2020-02-26T03:37:31.000Z',
					updatedAt: '2020-02-26T03:37:31.000Z',
					rackId: 80,
					storeProduct: {
						id: 307,
						totalStoreQuantity: 0,
						productId: 312,
						storeId: 1,
						product: {
							id: 312,
							productId: '308109-0-1',
							productName: 'Cheetos Flamin Hot 3.25 oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/308109_cheetos_hot_3.25oz_SCROLL1.png',
							productWeight: '102',
							calibrationFactor: '',
							weightScaleInfo: '',
							price: '189',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400329491',
							createdAt: '2020-06-03T19:33:27.000Z',
							updatedAt: '2020-06-03T19:34:53.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 404,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-07T03:48:20.000Z',
					updatedAt: '2019-11-07T03:48:20.000Z',
					rackId: 80,
					storeProduct: {
						id: 231,
						totalStoreQuantity: 14,
						productId: 230,
						storeId: 1,
						product: {
							id: 230,
							productId: '240676-0-1',
							productName: 'Mountain Dew Code Red 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240676_mountain_dew_code_red_20z_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000002243',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 477,
					shelfIndex: '2',
					zoneId: '3',
					createdAt: '2019-11-12T00:18:54.000Z',
					updatedAt: '2020-06-03T19:57:38.000Z',
					rackId: 80,
					storeProduct: {
						id: 288,
						totalStoreQuantity: 50,
						productId: 293,
						storeId: 1,
						product: {
							id: 293,
							productId: '220406-0-1',
							productName: 'ChapStick Regular Lip Balm .15oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/220406_chapstick_regular_lipbalm_.15z_scroll1.png',
							productWeight: '12',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '199',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48400,
							upc: '00036600813313',
							createdAt: null,
							updatedAt: '2020-06-03T22:36:35.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 165,
					shelfIndex: '2',
					zoneId: '4',
					createdAt: '2019-11-04T05:58:45.000Z',
					updatedAt: '2019-11-04T05:58:45.000Z',
					rackId: 80,
					storeProduct: {
						id: 50,
						totalStoreQuantity: 0,
						productId: 49,
						storeId: 1,
						product: {
							id: 49,
							productId: '300880-0-1',
							productName: '7-Select Lemon Crème Cookies 3.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/300880_7_SLemonCremeCks_scroll1.png',
							productWeight: '106',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548522360',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 322,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-06T01:45:17.000Z',
					updatedAt: '2019-11-06T01:45:17.000Z',
					rackId: 80,
					storeProduct: {
						id: 157,
						totalStoreQuantity: 0,
						productId: 156,
						storeId: 1,
						product: {
							id: 156,
							productId: '242200-0-1',
							productName: 'Smartwater 1L',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/242200_smartwater_scroll1.jpg',
							productWeight: '1040',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00786162002815',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 321,
					shelfIndex: '3',
					zoneId: '2',
					createdAt: '2019-11-06T01:44:59.000Z',
					updatedAt: '2019-11-06T01:44:59.000Z',
					rackId: 80,
					storeProduct: {
						id: 156,
						totalStoreQuantity: 0,
						productId: 155,
						storeId: 1,
						product: {
							id: 155,
							productId: '173504-0-1',
							productName: 'Yoplait Strawberry Parfait',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/173504_StrawberryYogurt_scroll1300.png',
							productWeight: '210',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548621438',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 323,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-06T01:45:39.000Z',
					updatedAt: '2019-11-06T01:45:39.000Z',
					rackId: 80,
					storeProduct: {
						id: 158,
						totalStoreQuantity: 0,
						productId: 157,
						storeId: 1,
						product: {
							id: 157,
							productId: '172629-0-1',
							productName: 'Chobani Greek Yogurt',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '160',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 324,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2019-11-06T01:46:00.000Z',
					updatedAt: '2019-11-06T01:46:00.000Z',
					rackId: 80,
					storeProduct: {
						id: 159,
						totalStoreQuantity: 5,
						productId: 158,
						storeId: 1,
						product: {
							id: 158,
							productId: '174560-0-1',
							productName: '7-Select Dill Pickle Bites 3.5oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/174560_7S_DillPickleBites_scroll1_copy.png',
							productWeight: '103',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548613709',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 179,
					shelfIndex: '4',
					zoneId: '1',
					createdAt: '2019-11-04T10:54:51.000Z',
					updatedAt: '2019-11-04T10:54:51.000Z',
					rackId: 80,
					storeProduct: {
						id: 57,
						totalStoreQuantity: 0,
						productId: 56,
						storeId: 1,
						product: {
							id: 56,
							productId: '175407-0-1',
							productName: 'Hostess Twinkies 2 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/175407_Hostess_Twinkies_2ct_scroll1.jpg',
							productWeight: '77',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00888109010102',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 180,
					shelfIndex: '4',
					zoneId: '2',
					createdAt: '2019-11-04T10:55:25.000Z',
					updatedAt: '2019-11-04T10:55:25.000Z',
					rackId: 80,
					storeProduct: {
						id: 58,
						totalStoreQuantity: 0,
						productId: 57,
						storeId: 1,
						product: {
							id: 57,
							productId: '173824-0-1',
							productName: '7-Select Snack Pie Cherry 4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/173824_JTM_SnackPie_Cherry_scroll1.jpg',
							productWeight: '125',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548519704',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 181,
					shelfIndex: '4',
					zoneId: '3',
					createdAt: '2019-11-04T10:55:50.000Z',
					updatedAt: '2019-11-04T10:55:50.000Z',
					rackId: 80,
					storeProduct: {
						id: 59,
						totalStoreQuantity: 0,
						productId: 58,
						storeId: 1,
						product: {
							id: 58,
							productId: '175065-0-1',
							productName: 'Mini Chocolate Brownie Bites',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/175065_MiniChocBrownieBites_scroll1.jpg',
							productWeight: '330',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 406,
					shelfIndex: '4',
					zoneId: '4',
					createdAt: '2019-11-07T03:49:12.000Z',
					updatedAt: '2019-11-07T03:49:12.000Z',
					rackId: 80,
					storeProduct: {
						id: 233,
						totalStoreQuantity: 100,
						productId: 232,
						storeId: 1,
						product: {
							id: 232,
							productId: '241128-0-1',
							productName: 'Diet Coke 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241128_diet_coke_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '239',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48395,
							upc: '00049000000450',
							createdAt: null,
							updatedAt: '2020-06-05T02:17:30.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 407,
					shelfIndex: '5',
					zoneId: '1',
					createdAt: '2019-11-07T03:49:46.000Z',
					updatedAt: '2019-11-07T03:49:46.000Z',
					rackId: 80,
					storeProduct: {
						id: 234,
						totalStoreQuantity: 13,
						productId: 233,
						storeId: 1,
						product: {
							id: 233,
							productId: '241134-0-1',
							productName: 'Coke 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241134_coke_20oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00049000400441',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 326,
					shelfIndex: '5',
					zoneId: '2',
					createdAt: '2019-11-06T01:46:51.000Z',
					updatedAt: '2019-11-06T01:46:51.000Z',
					rackId: 80,
					storeProduct: {
						id: 161,
						totalStoreQuantity: 5,
						productId: 160,
						storeId: 1,
						product: {
							id: 160,
							productId: '171330-0-1',
							productName: '7S Macaron ChocPstch',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '63',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548627102',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 409,
					shelfIndex: '5',
					zoneId: '3',
					createdAt: '2019-11-07T03:51:10.000Z',
					updatedAt: '2019-11-07T03:51:10.000Z',
					rackId: 80,
					storeProduct: {
						id: 236,
						totalStoreQuantity: 25,
						productId: 235,
						storeId: 1,
						product: {
							id: 235,
							productId: '241282-0-1',
							productName: 'Sprite 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241282_sprite20oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00049000407648',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 182,
					shelfIndex: '5',
					zoneId: '4',
					createdAt: '2019-11-04T10:57:03.000Z',
					updatedAt: '2019-11-04T10:57:03.000Z',
					rackId: 80,
					storeProduct: {
						id: 60,
						totalStoreQuantity: 0,
						productId: 59,
						storeId: 1,
						product: {
							id: 59,
							productId: '175066-0-1',
							productName: 'Mini Chocolate Chip Cookies',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/175066_MiniChocChipCookies_scroll1.jpg',
							productWeight: '264',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
			],
		],
	},
	{
		id: 81,
		rackIndex: 'Rack 02',
		description: 'Rack number 1',
		left: 249.904,
		top: 188.336,
		width: 37.8002,
		height: 107.278,
		angle: 269.857,
		createdAt: '2019-10-17T19:53:32.000Z',
		updatedAt: '2020-06-04T01:29:31.000Z',
		gondolaId: 51,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 487,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2020-02-25T06:40:19.000Z',
					updatedAt: '2020-02-25T06:40:19.000Z',
					rackId: 81,
					storeProduct: {
						id: 295,
						totalStoreQuantity: 6,
						productId: 300,
						storeId: 1,
						product: {
							id: 300,
							productId: '246507-0-1',
							productName:
								'7- Select Replenish Zero Orange Mango 28z',
							productImageUrl:
								'https://birds-eye-assets.s3.amazonaws.com/CW_STORE_PRODUCTS/replenish_zero_orange_mango.png',
							productWeight: '902',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548704131',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 199,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-04T11:44:27.000Z',
					updatedAt: '2019-11-04T11:44:27.000Z',
					rackId: 81,
					storeProduct: {
						id: 72,
						totalStoreQuantity: 49,
						productId: 71,
						storeId: 1,
						product: {
							id: 71,
							productId: '140037-0-1',
							productName: 'Twix Caramel King Size 3.02oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140037_twix_king_size_scroll1.png',
							productWeight: '60',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00040000004059',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 200,
					shelfIndex: '1',
					zoneId: '3',
					createdAt: '2019-11-04T11:44:51.000Z',
					updatedAt: '2019-11-04T11:44:51.000Z',
					rackId: 81,
					storeProduct: {
						id: 73,
						totalStoreQuantity: 390,
						productId: 72,
						storeId: 1,
						product: {
							id: 72,
							productId: '140041-0-1',
							productName: 'Baby Ruth 2.1oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140041_babyruth_2.1oz_scroll1.png',
							productWeight: '60',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '159',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48398,
							upc: '00028000010775',
							createdAt: null,
							updatedAt: '2020-06-03T21:06:19.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 201,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-04T11:45:16.000Z',
					updatedAt: '2019-11-04T11:45:16.000Z',
					rackId: 81,
					storeProduct: {
						id: 74,
						totalStoreQuantity: 44,
						productId: 73,
						storeId: 1,
						product: {
							id: 73,
							productId: '140044-0-1',
							productName: 'Butterfinger 1.9oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140044_Butterfinger_scroll1.png',
							productWeight: '53',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028000011598',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 488,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2020-02-25T06:40:59.000Z',
					updatedAt: '2020-02-25T06:40:59.000Z',
					rackId: 81,
					storeProduct: {
						id: 296,
						totalStoreQuantity: 4,
						productId: 301,
						storeId: 1,
						product: {
							id: 301,
							productId: '171214-0-1',
							productName: 'BEVeggie&ChsTray7z',
							productImageUrl:
								'https://birds-eye-assets.s3.amazonaws.com/CW_STORE_PRODUCTS/veggie_and_cheese_tray.png',
							productWeight: '236',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 238,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-05T02:09:32.000Z',
					updatedAt: '2019-11-05T02:09:32.000Z',
					rackId: 81,
					storeProduct: {
						id: 89,
						totalStoreQuantity: 117,
						productId: 88,
						storeId: 1,
						product: {
							id: 88,
							productId: '142416-0-1',
							productName: "Reese's Outrageous King Size 2.95oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/142416_ReesesOutrageousKS_scroll1.png',
							productWeight: '90',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00034000412013',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 203,
					shelfIndex: '2',
					zoneId: '3',
					createdAt: '2019-11-04T11:48:07.000Z',
					updatedAt: '2019-11-04T11:48:07.000Z',
					rackId: 81,
					storeProduct: {
						id: 75,
						totalStoreQuantity: 510,
						productId: 74,
						storeId: 1,
						product: {
							id: 74,
							productId: '140051-0-1',
							productName: "Hershey's Milk Chocolate 1.55oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140051_Hershey_MilkChocolate_scroll1.png',
							productWeight: '45',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '159',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48398,
							upc: '00034000002405',
							createdAt: null,
							updatedAt: '2020-06-05T01:27:54.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 204,
					shelfIndex: '2',
					zoneId: '4',
					createdAt: '2019-11-04T11:48:43.000Z',
					updatedAt: '2019-11-04T11:48:43.000Z',
					rackId: 81,
					storeProduct: {
						id: 76,
						totalStoreQuantity: 200,
						productId: 75,
						storeId: 1,
						product: {
							id: 75,
							productId: '140057-0-1',
							productName: 'Snickers Almond 1.76oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140057_SnickersAlmond_scroll1.png',
							productWeight: '50',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '159',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48398,
							upc: '00040000001058',
							createdAt: null,
							updatedAt: '2020-06-05T01:21:11.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 432,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-07T05:08:38.000Z',
					updatedAt: '2019-11-07T05:08:38.000Z',
					rackId: 81,
					storeProduct: {
						id: 256,
						totalStoreQuantity: 9,
						productId: 255,
						storeId: 1,
						product: {
							id: 255,
							productId: '245804-0-1',
							productName:
								'Starbucks Cold Brew Black Coffee 11oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/245804_Starbucks_Cold_Brew_Black_Unsweetened_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000162527',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 239,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-05T02:10:37.000Z',
					updatedAt: '2019-11-05T02:10:37.000Z',
					rackId: 81,
					storeProduct: {
						id: 90,
						totalStoreQuantity: 32,
						productId: 89,
						storeId: 1,
						product: {
							id: 89,
							productId: '145683-0-1',
							productName: 'Starburst Minis Sours 1.85oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/145683_starburst_minisours_scroll1.png',
							productWeight: '56',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00022000001122',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 500,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2020-02-26T01:57:49.000Z',
					updatedAt: '2020-02-26T01:57:49.000Z',
					rackId: 81,
					storeProduct: {
						id: 303,
						totalStoreQuantity: 0,
						productId: 308,
						storeId: 1,
						product: {
							id: 308,
							productId: '151519-0-1',
							productName:
								'Yoplait Original Strawberry Banana Yogurt 6 oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/151519_YoplaitOriginalStrwbrryBnnYgrt_scroll1.png',
							productWeight: '178',
							calibrationFactor: '',
							weightScaleInfo: '',
							price: '129',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00070470003139',
							createdAt: '2020-06-03T19:20:48.000Z',
							updatedAt: '2020-06-03T19:23:11.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 489,
					shelfIndex: '4',
					zoneId: '1',
					createdAt: '2020-02-25T06:42:02.000Z',
					updatedAt: '2020-02-25T06:42:02.000Z',
					rackId: 81,
					storeProduct: {
						id: 297,
						totalStoreQuantity: 4,
						productId: 302,
						storeId: 1,
						product: {
							id: 302,
							productId: '177549-0-1',
							productName: '7S SnackTrioSlmePrvl',
							productImageUrl:
								'https://birds-eye-assets.s3.amazonaws.com/CW_STORE_PRODUCTS/7S_gourmet_snack_trio_genoa_salami_provolone_olives.png',
							productWeight: '106',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548636227',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 490,
					shelfIndex: '4',
					zoneId: '2',
					createdAt: '2020-02-25T06:42:26.000Z',
					updatedAt: '2020-02-25T06:42:26.000Z',
					rackId: 81,
					storeProduct: {
						id: 298,
						totalStoreQuantity: 5,
						productId: 303,
						storeId: 1,
						product: {
							id: 303,
							productId: '247128-0-1',
							productName:
								'Red Bull Yellow Edition, Tropical 12oz',
							productImageUrl:
								'https://birds-eye-assets.s3.amazonaws.com/CW_STORE_PRODUCTS/red_bull_yellow.png',
							productWeight: null,
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '349',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00611269113570',
							createdAt: null,
							updatedAt: null,
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 491,
					shelfIndex: '4',
					zoneId: '3',
					createdAt: '2020-02-25T06:43:23.000Z',
					updatedAt: '2020-02-25T06:43:23.000Z',
					rackId: 81,
					storeProduct: {
						id: 299,
						totalStoreQuantity: 0,
						productId: 304,
						storeId: 1,
						product: {
							id: 304,
							productId: '308050-0-1',
							productName: 'Chesters Hot Fries 3.625 oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/308050_Chesters_Flamin_Hot_Fries_3.625oz_SCROLL1.png',
							productWeight: '',
							calibrationFactor: '',
							weightScaleInfo: '',
							price: '189',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400331524',
							createdAt: '2020-06-02T23:55:35.000Z',
							updatedAt: '2020-06-02T23:55:35.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 510,
					shelfIndex: '4',
					zoneId: '4',
					createdAt: '2020-02-26T04:26:16.000Z',
					updatedAt: '2020-02-26T04:26:16.000Z',
					rackId: 81,
					storeProduct: {
						id: 309,
						totalStoreQuantity: 0,
						productId: 314,
						storeId: 1,
						product: {
							id: 314,
							productId: '307890-0-1',
							productName: 'Doritos Nacho 2.75 oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/307890_doritos_nacho_cheese_2.75oz_SCROLL1.png',
							productWeight: '93',
							calibrationFactor: '',
							weightScaleInfo: '',
							price: '189',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400325059',
							createdAt: '2020-06-03T19:49:00.000Z',
							updatedAt: '2020-06-03T19:50:07.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 211,
					shelfIndex: '5',
					zoneId: '1',
					createdAt: '2019-11-04T12:30:47.000Z',
					updatedAt: '2019-11-04T12:30:47.000Z',
					rackId: 81,
					storeProduct: {
						id: 77,
						totalStoreQuantity: 99,
						productId: 76,
						storeId: 1,
						product: {
							id: 76,
							productId: '140061-0-1',
							productName: 'M&M Peanut 1.74oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140061_M_MPeanut_scroll1.png',
							productWeight: '52',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00040000000327',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 327,
					shelfIndex: '5',
					zoneId: '2',
					createdAt: '2019-11-06T01:47:55.000Z',
					updatedAt: '2019-11-06T01:47:55.000Z',
					rackId: 81,
					storeProduct: {
						id: 162,
						totalStoreQuantity: 0,
						productId: 161,
						storeId: 1,
						product: {
							id: 161,
							productId: '171809-0-1',
							productName: 'TunaSalad',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '243',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 509,
					shelfIndex: '5',
					zoneId: '3',
					createdAt: '2020-02-26T04:25:35.000Z',
					updatedAt: '2020-02-26T04:25:35.000Z',
					rackId: 81,
					storeProduct: {
						id: 308,
						totalStoreQuantity: 0,
						productId: 313,
						storeId: 1,
						product: {
							id: 313,
							productId: '307888-0-1',
							productName: 'Doritos Cool Ranch 2.75 oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/307888_doritos_coolranch_2.75oz_SCROLL1.png',
							productWeight: '93',
							calibrationFactor: '',
							weightScaleInfo: '',
							price: '189',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400325042',
							createdAt: '2020-06-03T19:35:41.000Z',
							updatedAt: '2020-06-03T19:36:30.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 503,
					shelfIndex: '5',
					zoneId: '4',
					createdAt: '2020-02-26T02:10:43.000Z',
					updatedAt: '2020-02-26T02:10:43.000Z',
					rackId: 81,
					storeProduct: {
						id: 305,
						totalStoreQuantity: 0,
						productId: 310,
						storeId: 1,
						product: {
							id: 310,
							productId: '307871-0-1',
							productName: 'Ruffles Cheddar&SourCream 2.5 oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/307871_ruffles_cheddar_sour_cream_SCROLL1.png',
							productWeight: '86',
							calibrationFactor: '',
							weightScaleInfo: '',
							price: '189',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400324427',
							createdAt: '2020-06-03T19:29:39.000Z',
							updatedAt: '2020-06-03T19:30:42.000Z',
						},
					},
				},
			],
		],
	},
	{
		id: 72,
		rackIndex: 'Rack 01',
		description: 'Rack number 1',
		left: 265.67,
		top: 553.754,
		width: 30,
		height: 136.819,
		angle: 269.799,
		createdAt: '2019-10-17T19:47:06.000Z',
		updatedAt: '2020-06-04T01:14:00.000Z',
		gondolaId: 47,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 154,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2019-11-04T05:47:04.000Z',
					updatedAt: '2019-11-04T05:47:04.000Z',
					rackId: 72,
					storeProduct: {
						id: 43,
						totalStoreQuantity: 60,
						productId: 42,
						storeId: 1,
						product: {
							id: 42,
							productId: '210321-0-1',
							productName:
								"Kellogg's Froot Loops Cereal Cup 1.5oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/210321_KLGFrootLoopsCerealCup_scroll1.png',
							productWeight: '42',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '159',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48402,
							upc: '00038000124655',
							createdAt: null,
							updatedAt: '2020-06-03T20:31:05.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 155,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-04T05:47:39.000Z',
					updatedAt: '2019-11-04T05:47:39.000Z',
					rackId: 72,
					storeProduct: {
						id: 44,
						totalStoreQuantity: 6,
						productId: 43,
						storeId: 1,
						product: {
							id: 43,
							productId: '210376-0-1',
							productName: 'Cinnamon Toast Crunch Cereal Cup 2oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/210376__Cinnamon_Toast_Crunch_Cereal_2_Ouncecups_v2_scroll1.png',
							productWeight: '56',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00016000141544',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 158,
					shelfIndex: '1',
					zoneId: '5',
					createdAt: '2019-11-04T05:49:18.000Z',
					updatedAt: '2019-11-04T05:49:18.000Z',
					rackId: 72,
					storeProduct: {
						id: 45,
						totalStoreQuantity: 14,
						productId: 44,
						storeId: 1,
						product: {
							id: 44,
							productId: '300108-0-1',
							productName:
								"Kellogg's Nutri Grain Strawberry 1.3oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/300108_KLG_Nutri_Grain_Strawberry_1.3z_scroll1.png',
							productWeight: '37',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00038000359217',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 217,
					shelfIndex: '1',
					zoneId: '6',
					createdAt: '2019-11-04T12:38:56.000Z',
					updatedAt: '2019-11-04T12:38:56.000Z',
					rackId: 72,
					storeProduct: {
						id: 80,
						totalStoreQuantity: 68,
						productId: 79,
						storeId: 1,
						product: {
							id: 79,
							productId: '140152-0-1',
							productName: "Reese's Big Cups King Size 2.8oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140152_ReesesBigCupsKS_scroll1.png',
							productWeight: '84',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00034000430963',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 218,
					shelfIndex: '1',
					zoneId: '7',
					createdAt: '2019-11-04T12:39:44.000Z',
					updatedAt: '2019-11-04T12:39:44.000Z',
					rackId: 72,
					storeProduct: {
						id: 81,
						totalStoreQuantity: 49,
						productId: 80,
						storeId: 1,
						product: {
							id: 80,
							productId: '140053-0-1',
							productName: 'Kit Kat 1.5oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/140025_kitkat_kingsize_scroll1.png',
							productWeight: '44',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00034000002467',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 219,
					shelfIndex: '1',
					zoneId: '8',
					createdAt: '2019-11-04T12:40:12.000Z',
					updatedAt: '2019-11-04T12:40:12.000Z',
					rackId: 72,
					storeProduct: {
						id: 82,
						totalStoreQuantity: 39,
						productId: 81,
						storeId: 1,
						product: {
							id: 81,
							productId: '140501-0-1',
							productName: 'Snickers King Size 3.29oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140501_snickers_scroll1.png',
							productWeight: '95',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00040000002635',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 220,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2019-11-04T12:42:02.000Z',
					updatedAt: '2019-11-04T12:42:02.000Z',
					rackId: 72,
					storeProduct: {
						id: 83,
						totalStoreQuantity: 86,
						productId: 82,
						storeId: 1,
						product: {
							id: 82,
							productId: '140519-0-1',
							productName: 'Snickers Almond King Size 2 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140519_SnickersAlmondKingSize_2Piece_scroll1.png',
							productWeight: '95',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00040000006275',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 353,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-07T03:09:37.000Z',
					updatedAt: '2019-11-07T03:09:37.000Z',
					rackId: 72,
					storeProduct: {
						id: 184,
						totalStoreQuantity: 0,
						productId: 183,
						storeId: 1,
						product: {
							id: 183,
							productId: '244764-0-1',
							productName: 'Body Armor Strawberry Banana  28oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/244764_BA_Strawberry_Banana_28oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00858176002355',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 354,
					shelfIndex: '2',
					zoneId: '3',
					createdAt: '2019-11-07T03:09:51.000Z',
					updatedAt: '2019-11-07T03:09:51.000Z',
					rackId: 72,
					storeProduct: {
						id: 185,
						totalStoreQuantity: 0,
						productId: 184,
						storeId: 1,
						product: {
							id: 184,
							productId: '244832-0-1',
							productName: 'Body Armor Blackout Berry 28oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/244832_BA_Blackout_Berry_28oz_scroll1.jpg',
							productWeight: '0',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00858176002331',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
				null,
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 160,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-04T05:50:47.000Z',
					updatedAt: '2019-11-04T05:50:47.000Z',
					rackId: 72,
					storeProduct: {
						id: 46,
						totalStoreQuantity: 0,
						productId: 45,
						storeId: 1,
						product: {
							id: 45,
							productId: '176371-0-1',
							productName: 'Bon Appetit Sliced Marble Cake 4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/176371_BnAp_SlcdCk_Marble_4z_scroll1.png',
							productWeight: '130',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00035751114294',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 161,
					shelfIndex: '3',
					zoneId: '2',
					createdAt: '2019-11-04T05:51:33.000Z',
					updatedAt: '2019-11-04T05:51:33.000Z',
					rackId: 72,
					storeProduct: {
						id: 47,
						totalStoreQuantity: 0,
						productId: 46,
						storeId: 1,
						product: {
							id: 46,
							productId: '176611-0-1',
							productName: '7-Select Snack Pie Lemon 4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/176611_JTM_SnackPie_Lemon_scroll1.jpg',
							productWeight: '113',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548581282',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 221,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-04T12:43:16.000Z',
					updatedAt: '2019-11-04T12:43:16.000Z',
					rackId: 72,
					storeProduct: {
						id: 84,
						totalStoreQuantity: 320,
						productId: 83,
						storeId: 1,
						product: {
							id: 83,
							productId: '140538-0-1',
							productName:
								'M&M Peanut Butter Sharing Size 2.83oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140538_MMPeanutButterShare_scroll1.jfif.jpg',
							productWeight: '84',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '219',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48398,
							upc: '00040000002239',
							createdAt: null,
							updatedAt: '2020-06-05T02:10:12.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 355,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2019-11-07T03:10:30.000Z',
					updatedAt: '2019-11-07T03:10:30.000Z',
					rackId: 72,
					storeProduct: {
						id: 186,
						totalStoreQuantity: 0,
						productId: 185,
						storeId: 1,
						product: {
							id: 185,
							productId: '244238-0-1',
							productName: 'Body Armor Orange Mango 28oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/244238_BA_Orange_Mango_28oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00858176002348',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 442,
					shelfIndex: '4',
					zoneId: '1',
					createdAt: null,
					updatedAt: null,
					rackId: 72,
					storeProduct: {
						id: 265,
						totalStoreQuantity: 10,
						productId: 264,
						storeId: 1,
						product: {
							id: 264,
							productId: '246949-0-1',
							productName: 'Monster Ultra Sunrise 16oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/246949_Monster_Sunrise_16z_Can_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00070847020905',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 439,
					shelfIndex: '4',
					zoneId: '2',
					createdAt: null,
					updatedAt: null,
					rackId: 72,
					storeProduct: {
						id: 262,
						totalStoreQuantity: 8,
						productId: 261,
						storeId: 1,
						product: {
							id: 261,
							productId: '244487-0-1',
							productName: 'Celsius Sparkling Grape 12oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/244487_Celsius_Sparkling_Grape_12z_Can_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00889392000917',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 440,
					shelfIndex: '4',
					zoneId: '3',
					createdAt: null,
					updatedAt: null,
					rackId: 72,
					storeProduct: {
						id: 263,
						totalStoreQuantity: 10,
						productId: 262,
						storeId: 1,
						product: {
							id: 262,
							productId: '245068-0-1',
							productName:
								'7-Select Cold Brew Coffee Vanilla 11oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/245068_7_Select_Cold_Brew_Coffee_Vanilla_11z_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548589035',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 441,
					shelfIndex: '4',
					zoneId: '4',
					createdAt: null,
					updatedAt: null,
					rackId: 72,
					storeProduct: {
						id: 264,
						totalStoreQuantity: 18,
						productId: 263,
						storeId: 1,
						product: {
							id: 263,
							productId: '246630-0-1',
							productName: 'Monster Zero Ultra 16oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/246630_monster_zero_ultra_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00070847012481',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
		],
	},
	{
		id: 73,
		rackIndex: 'Rack 02',
		description: 'Rack number 1',
		left: 268.867,
		top: 517.733,
		width: 30.1156,
		height: 134.536,
		angle: 90.1643,
		createdAt: '2019-10-17T19:47:57.000Z',
		updatedAt: '2020-06-04T01:25:51.000Z',
		gondolaId: 47,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 359,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2019-11-07T03:13:03.000Z',
					updatedAt: '2019-11-07T03:13:03.000Z',
					rackId: 73,
					storeProduct: {
						id: 187,
						totalStoreQuantity: 20,
						productId: 186,
						storeId: 1,
						product: {
							id: 186,
							productId: '244149-0-1',
							productName: '7-Select Replenish Lemonade 28oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/244149_7SelectReplenLemonade_28oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548630997',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 360,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-07T03:13:44.000Z',
					updatedAt: '2019-11-07T03:13:44.000Z',
					rackId: 73,
					storeProduct: {
						id: 188,
						totalStoreQuantity: 24,
						productId: 187,
						storeId: 1,
						product: {
							id: 187,
							productId: '244160-0-1',
							productName: '7-Select Replenish Guava Splash 28oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/244160_7SelectReplenGuavaSplash_28oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548631000',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 213,
					shelfIndex: '1',
					zoneId: '3',
					createdAt: '2019-11-04T12:34:26.000Z',
					updatedAt: '2020-06-03T19:17:13.000Z',
					rackId: 73,
					storeProduct: {
						id: 78,
						totalStoreQuantity: 75,
						productId: 77,
						storeId: 1,
						product: {
							id: 77,
							productId: '140063-0-1',
							productName: 'M&M Plain 1.69oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140063_M_MPlain_scroll1.png',
							productWeight: '40',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00040000000310',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 361,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-07T03:14:36.000Z',
					updatedAt: '2019-11-07T03:14:36.000Z',
					rackId: 73,
					storeProduct: {
						id: 189,
						totalStoreQuantity: 19,
						productId: 188,
						storeId: 1,
						product: {
							id: 188,
							productId: '244165-0-1',
							productName: '7-Select Replenish Orange Mango 28oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/244165_7SelectReplenOrgMango_28oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548631017',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 307,
					shelfIndex: '1',
					zoneId: '5',
					createdAt: '2019-11-06T01:36:05.000Z',
					updatedAt: '2019-11-06T01:36:05.000Z',
					rackId: 73,
					storeProduct: {
						id: 143,
						totalStoreQuantity: 0,
						productId: 142,
						storeId: 1,
						product: {
							id: 142,
							productId: '178373-0-1',
							productName: 'WB ChcknCsrPastaSld',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '382',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 309,
					shelfIndex: '1',
					zoneId: '6',
					createdAt: '2019-11-06T01:36:31.000Z',
					updatedAt: '2019-11-06T01:36:31.000Z',
					rackId: 73,
					storeProduct: {
						id: 145,
						totalStoreQuantity: 0,
						productId: 144,
						storeId: 1,
						product: {
							id: 144,
							productId: '175781-0-1',
							productName: 'Chicken Caesar Salad',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/175781_ChickenCaesarSalad_scroll1.jpg',
							productWeight: '257',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 362,
					shelfIndex: '1',
					zoneId: '7',
					createdAt: '2019-11-07T03:15:00.000Z',
					updatedAt: '2019-11-07T03:15:00.000Z',
					rackId: 73,
					storeProduct: {
						id: 190,
						totalStoreQuantity: 12,
						productId: 189,
						storeId: 1,
						product: {
							id: 189,
							productId: '244167-0-1',
							productName:
								'7-Select Replenish Cucumber Mint 28oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/244167_7SelectReplenCucumMint_28oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548631024',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 363,
					shelfIndex: '1',
					zoneId: '8',
					createdAt: '2019-11-07T03:15:17.000Z',
					updatedAt: '2019-11-07T03:15:17.000Z',
					rackId: 73,
					storeProduct: {
						id: 191,
						totalStoreQuantity: 0,
						productId: 190,
						storeId: 1,
						product: {
							id: 190,
							productId: '302557-0-1',
							productName:
								'7-Select Ripple Cheddar & Sour Cream Chips  2.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/302557_7s_ripple_chddar_srcream_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 313,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2019-11-06T01:37:38.000Z',
					updatedAt: '2019-11-06T01:37:38.000Z',
					rackId: 73,
					storeProduct: {
						id: 149,
						totalStoreQuantity: 0,
						productId: 148,
						storeId: 1,
						product: {
							id: 148,
							productId: '170416-0-1',
							productName: 'WBEggSldSndwch',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '212',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 315,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-06T01:38:01.000Z',
					updatedAt: '2019-11-06T01:38:01.000Z',
					rackId: 73,
					storeProduct: {
						id: 151,
						totalStoreQuantity: 0,
						productId: 150,
						storeId: 1,
						product: {
							id: 150,
							productId: '175028-0-1',
							productName: 'Pineapple Spears',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/175028_PineappleSpears_scroll1.jpg',
							productWeight: '376',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548630409',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 364,
					shelfIndex: '2',
					zoneId: '3',
					createdAt: '2019-11-07T03:16:39.000Z',
					updatedAt: '2019-11-07T03:16:39.000Z',
					rackId: 73,
					storeProduct: {
						id: 192,
						totalStoreQuantity: 0,
						productId: 191,
						storeId: 1,
						product: {
							id: 191,
							productId: '303164-0-1',
							productName: 'Ruffles Cheddar & Sour Cream 2.6oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/303164_ruffles_cheddar_sour_cream_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400028158',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 162,
					shelfIndex: '2',
					zoneId: '4',
					createdAt: '2019-11-04T05:53:11.000Z',
					updatedAt: '2019-11-04T05:53:11.000Z',
					rackId: 73,
					storeProduct: {
						id: 48,
						totalStoreQuantity: 0,
						productId: 47,
						storeId: 1,
						product: {
							id: 47,
							productId: '177245-0-1',
							productName: '7-Select Panquecitos 3.53oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/177245_hspbkry_panquecitos_3.53oz_scroll1.png',
							productWeight: '113',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548615161',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 316,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-06T01:40:38.000Z',
					updatedAt: '2019-11-06T01:40:38.000Z',
					rackId: 73,
					storeProduct: {
						id: 152,
						totalStoreQuantity: 0,
						productId: 151,
						storeId: 1,
						product: {
							id: 151,
							productId: '178306-0-1',
							productName: 'Carrot & Ranch',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '136',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 365,
					shelfIndex: '3',
					zoneId: '2',
					createdAt: '2019-11-07T03:17:17.000Z',
					updatedAt: '2019-11-07T03:17:17.000Z',
					rackId: 73,
					storeProduct: {
						id: 193,
						totalStoreQuantity: 0,
						productId: 192,
						storeId: 1,
						product: {
							id: 192,
							productId: '303160-0-1',
							productName: "Lay's Classic 2.75oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/303160_lays_regular_2.75oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400027960',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 366,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-07T03:17:39.000Z',
					updatedAt: '2019-11-07T03:17:39.000Z',
					rackId: 73,
					storeProduct: {
						id: 194,
						totalStoreQuantity: 0,
						productId: 193,
						storeId: 1,
						product: {
							id: 193,
							productId: '302507-0-1',
							productName: '7-Select Original Chips 2.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/302507_7SelectOriginalChips_2.5oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 318,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2019-11-06T01:40:57.000Z',
					updatedAt: '2019-11-06T01:40:57.000Z',
					rackId: 73,
					storeProduct: {
						id: 154,
						totalStoreQuantity: 0,
						productId: 153,
						storeId: 1,
						product: {
							id: 153,
							productId: '172065-0-1',
							productName: 'Apple Chs Snk Tray7z',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '213',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 216,
					shelfIndex: '4',
					zoneId: '1',
					createdAt: '2019-11-04T12:36:58.000Z',
					updatedAt: '2019-11-04T12:36:58.000Z',
					rackId: 73,
					storeProduct: {
						id: 79,
						totalStoreQuantity: 28,
						productId: 78,
						storeId: 1,
						product: {
							id: 78,
							productId: '140075-0-1',
							productName: 'Twix Caramel 1.79oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140075_TwixCaramel_scroll1.png',
							productWeight: '54',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00040000004356',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 367,
					shelfIndex: '4',
					zoneId: '2',
					createdAt: '2019-11-07T03:18:16.000Z',
					updatedAt: '2019-11-07T03:18:16.000Z',
					rackId: 73,
					storeProduct: {
						id: 195,
						totalStoreQuantity: 0,
						productId: 194,
						storeId: 1,
						product: {
							id: 194,
							productId: '307373-0-1',
							productName:
								'7-Select Sour Cream & Onion Potato Chips 2.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/307373_7SBagSourCrmOnion2.5oz_SCROLL1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548511821',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 368,
					shelfIndex: '4',
					zoneId: '3',
					createdAt: '2019-11-07T03:18:35.000Z',
					updatedAt: '2019-11-07T03:18:35.000Z',
					rackId: 73,
					storeProduct: {
						id: 196,
						totalStoreQuantity: 0,
						productId: 195,
						storeId: 1,
						product: {
							id: 195,
							productId: '303169-0-1',
							productName: 'Cheetos Crunchy 3.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/303169_cheetos_crunchy_cheese_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400028165',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 369,
					shelfIndex: '4',
					zoneId: '4',
					createdAt: '2019-11-07T03:19:19.000Z',
					updatedAt: '2019-11-07T03:19:19.000Z',
					rackId: 73,
					storeProduct: {
						id: 197,
						totalStoreQuantity: 0,
						productId: 196,
						storeId: 1,
						product: {
							id: 196,
							productId: '303172-0-1',
							productName: 'Doritos Cool Ranch 3.1oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/303172_doritos_coolranch_3oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400028202',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
		],
	},
	{
		id: 68,
		rackIndex: 'Rack 01',
		description: 'Rack number 1',
		left: 252.359,
		top: 149.983,
		width: 32.0073,
		height: 107.205,
		angle: 90.3501,
		createdAt: '2019-10-17T19:43:33.000Z',
		updatedAt: '2020-06-04T01:29:27.000Z',
		gondolaId: 45,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 187,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2019-11-04T11:03:56.000Z',
					updatedAt: '2019-11-04T11:03:56.000Z',
					rackId: 68,
					storeProduct: {
						id: 64,
						totalStoreQuantity: 0,
						productId: 63,
						storeId: 1,
						product: {
							id: 63,
							productId: '175882-0-1',
							productName: 'Bon Appetit Banana Nut Muffin 5.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/175882_bonAp_banana_nut_muffin_5.5oz_scroll1.png',
							productWeight: '156',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00035751113044',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 337,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-07T02:56:31.000Z',
					updatedAt: '2019-11-07T02:56:31.000Z',
					rackId: 68,
					storeProduct: {
						id: 170,
						totalStoreQuantity: 2,
						productId: 169,
						storeId: 1,
						product: {
							id: 169,
							productId: '241137-0-1',
							productName: '7-Select Mango Juice 23.9oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241137_7SMangoJuice_24oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548590307',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 236,
					shelfIndex: '1',
					zoneId: '3',
					createdAt: '2019-11-05T02:00:27.000Z',
					updatedAt: '2019-11-05T02:00:27.000Z',
					rackId: 68,
					storeProduct: {
						id: 88,
						totalStoreQuantity: 27,
						productId: 87,
						storeId: 1,
						product: {
							id: 87,
							productId: '141754-0-1',
							productName: 'M&M Peanut Butter 1.63oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/141754_M_MPeanutButter_scroll1.png',
							productWeight: '49',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00040000001447',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 186,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-04T11:03:20.000Z',
					updatedAt: '2019-11-04T11:03:20.000Z',
					rackId: 68,
					storeProduct: {
						id: 63,
						totalStoreQuantity: 0,
						productId: 62,
						storeId: 1,
						product: {
							id: 62,
							productId: '175529-0-1',
							productName: 'Bon Appetit Cream Cheese Cake 4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/175529_BonAppetitCreamCheeseCake4oz_scroll1.jpg',
							productWeight: '124',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00035751114324',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 338,
					shelfIndex: '1',
					zoneId: '6',
					createdAt: '2019-11-07T02:57:07.000Z',
					updatedAt: '2019-11-07T02:57:07.000Z',
					rackId: 68,
					storeProduct: {
						id: 171,
						totalStoreQuantity: 0,
						productId: 170,
						storeId: 1,
						product: {
							id: 170,
							productId: '241173-0-1',
							productName: '7-Select Fruit Punch Juice 23.9oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241173_7S_FruitPunchJuice_24oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548590451',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 184,
					shelfIndex: '1',
					zoneId: '7',
					createdAt: '2019-11-04T10:58:47.000Z',
					updatedAt: '2019-11-04T10:58:47.000Z',
					rackId: 68,
					storeProduct: {
						id: 62,
						totalStoreQuantity: 0,
						productId: 61,
						storeId: 1,
						product: {
							id: 61,
							productId: '175408-0-1',
							productName: 'Hostess Chocolate Cupcakes 2 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/175408_Hostess_Chocolate_Cupcakes_scroll1.jpg',
							productWeight: '90',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00888109010010',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 183,
					shelfIndex: '1',
					zoneId: '8',
					createdAt: '2019-11-04T10:58:20.000Z',
					updatedAt: '2019-11-04T10:58:20.000Z',
					rackId: 68,
					storeProduct: {
						id: 61,
						totalStoreQuantity: 0,
						productId: 60,
						storeId: 1,
						product: {
							id: 60,
							productId: '173823-0-1',
							productName: '7-Select Snack Pie Apple 4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/173823_JTM_SnackPie_Apple_scroll1.jpg',
							productWeight: '128',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548519674',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 339,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2019-11-07T02:57:32.000Z',
					updatedAt: '2019-11-07T02:57:32.000Z',
					rackId: 68,
					storeProduct: {
						id: 172,
						totalStoreQuantity: 0,
						productId: 171,
						storeId: 1,
						product: {
							id: 171,
							productId: '248228-0-1',
							productName: '7-Select Organic Orange Juice 12oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/248228_7S_OrgaincOJ12oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548592844',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 340,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-07T02:58:05.000Z',
					updatedAt: '2019-11-07T02:58:05.000Z',
					rackId: 68,
					storeProduct: {
						id: 173,
						totalStoreQuantity: 1,
						productId: 172,
						storeId: 1,
						product: {
							id: 172,
							productId: '248229-0-1',
							productName: '7-Select Organic Apple Juice 12oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/248229_OrganicJuice_Apple_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548592813',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 149,
					shelfIndex: '2',
					zoneId: '3',
					createdAt: '2019-11-04T05:35:01.000Z',
					updatedAt: '2019-11-04T05:35:01.000Z',
					rackId: 68,
					storeProduct: {
						id: 41,
						totalStoreQuantity: 5,
						productId: 40,
						storeId: 1,
						product: {
							id: 40,
							productId: '210266-0-1',
							productName: "Kellogg's PopTart Smores 3.67oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/210266_KLGPopTartSmores_scroll1.png',
							productWeight: '104',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00038000058172',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 341,
					shelfIndex: '2',
					zoneId: '4',
					createdAt: '2019-11-07T02:59:28.000Z',
					updatedAt: '2019-11-07T02:59:28.000Z',
					rackId: 68,
					storeProduct: {
						id: 174,
						totalStoreQuantity: 8,
						productId: 173,
						storeId: 1,
						product: {
							id: 173,
							productId: '244732-0-1',
							productName:
								'7-Select Organic Cold Pressed Tropical Glow 14oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/244732_7Eleven_ColdPressed_TropicalGlow_14oz_LR_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548613099',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:53.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 285,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-06T01:21:23.000Z',
					updatedAt: '2019-11-06T01:21:23.000Z',
					rackId: 68,
					storeProduct: {
						id: 122,
						totalStoreQuantity: 6,
						productId: 121,
						storeId: 1,
						product: {
							id: 121,
							productId: '221719-0-1',
							productName:
								'7-Select Energy Shot Strawberry Lemonade 2oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/221719_StrawberryLemonade_Sleeve_HR_SCROLL1.jpg',
							productWeight: '70',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548588717',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 342,
					shelfIndex: '3',
					zoneId: '2',
					createdAt: '2019-11-07T03:00:16.000Z',
					updatedAt: '2019-11-07T03:00:16.000Z',
					rackId: 68,
					storeProduct: {
						id: 175,
						totalStoreQuantity: 16,
						productId: 174,
						storeId: 1,
						product: {
							id: 174,
							productId: '245803-0-1',
							productName: '7-Select Strawberry Lemonade 23.9oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/245803_7SStrwbrryLemonade_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548613334',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 286,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-06T01:22:03.000Z',
					updatedAt: '2019-11-06T01:22:03.000Z',
					rackId: 68,
					storeProduct: {
						id: 123,
						totalStoreQuantity: 12,
						productId: 122,
						storeId: 1,
						product: {
							id: 122,
							productId: '222026-0-1',
							productName:
								'5-Hour Energy Extra Strength Sour Apple 1.93oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/222026_5hour_energy_extra_strength_sour_apple_1.93z_scroll1.png',
							productWeight: '69',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00719410730123',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 287,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2019-11-06T01:22:30.000Z',
					updatedAt: '2019-11-06T01:22:30.000Z',
					rackId: 68,
					storeProduct: {
						id: 124,
						totalStoreQuantity: 2,
						productId: 123,
						storeId: 1,
						product: {
							id: 123,
							productId: '221105-0-1',
							productName: 'Aveeno Moisturizing Lotion 2.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/221105_Aveeno_Moisturizing_Lotion_2.5z_scroll1.jpg',
							productWeight: '84',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00381370036029',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 288,
					shelfIndex: '4',
					zoneId: '1',
					createdAt: '2019-11-06T01:22:56.000Z',
					updatedAt: '2019-11-06T01:22:56.000Z',
					rackId: 68,
					storeProduct: {
						id: 125,
						totalStoreQuantity: 0,
						productId: 124,
						storeId: 1,
						product: {
							id: 124,
							productId: '221307-0-1',
							productName:
								'Visine Advanced Relief On-The-Go .28oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/221307_visine_advanced_relief_onthego_.28z_scroll1.png',
							productWeight: '20',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00074300081489',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 289,
					shelfIndex: '4',
					zoneId: '2',
					createdAt: '2019-11-06T01:23:38.000Z',
					updatedAt: '2020-06-03T20:31:05.000Z',
					rackId: 68,
					storeProduct: {
						id: 126,
						totalStoreQuantity: 0,
						productId: 125,
						storeId: 1,
						product: {
							id: 125,
							productId: '221317-0-1',
							productName: '7-Select Floss Picks 36 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/221317_FlossPick_scroll1.jpg',
							productWeight: '27',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548586997',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 290,
					shelfIndex: '4',
					zoneId: '3',
					createdAt: '2019-11-06T01:24:01.000Z',
					updatedAt: '2019-11-06T01:24:01.000Z',
					rackId: 68,
					storeProduct: {
						id: 127,
						totalStoreQuantity: 0,
						productId: 126,
						storeId: 1,
						product: {
							id: 126,
							productId: '177252-0-1',
							productName: 'GoSmart Turkey Sandwich',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/177252_GoSmart_TurkeySandwich_Plated_scroll1.png',
							productWeight: '367',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548587802',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 343,
					shelfIndex: '4',
					zoneId: '4',
					createdAt: '2019-11-07T03:00:48.000Z',
					updatedAt: '2019-11-07T03:00:48.000Z',
					rackId: 68,
					storeProduct: {
						id: 176,
						totalStoreQuantity: 8,
						productId: 175,
						storeId: 1,
						product: {
							id: 175,
							productId: '243651-0-1',
							productName:
								'7-Select Organic Cold Pressed Clean and Green 14oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/243651_7Eleven_ColdPressed_PurifyGreen_14oz_LR_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548613082',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
		],
	},
	{
		id: 69,
		rackIndex: 'Rack 02',
		description: 'Rack number 1',
		left: 266.728,
		top: 311.938,
		width: 25.5723,
		height: 136.587,
		angle: 270.438,
		createdAt: '2019-10-17T19:43:55.000Z',
		updatedAt: '2020-06-04T01:20:59.000Z',
		gondolaId: 45,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 189,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2019-11-04T11:08:42.000Z',
					updatedAt: '2019-11-04T11:08:42.000Z',
					rackId: 69,
					storeProduct: {
						id: 65,
						totalStoreQuantity: 0,
						productId: 64,
						storeId: 1,
						product: {
							id: 64,
							productId: '175984-0-1',
							productName:
								'Bon Appetit Cheese & Berries Danish 5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/175984_bonAp_cheese_berries_danish_5oz_scroll1.png',
							productWeight: '144',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00035751111095',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 344,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-07T03:01:33.000Z',
					updatedAt: '2019-11-07T03:01:33.000Z',
					rackId: 69,
					storeProduct: {
						id: 177,
						totalStoreQuantity: 15,
						productId: 176,
						storeId: 1,
						product: {
							id: 176,
							productId: '244876-0-1',
							productName:
								'7-Select Organic Cold Pressed Berry and Bright 14oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/244876_7Eleven_ColdPressed_PurpleGlow_14oz_LR_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548613150',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 511,
					shelfIndex: '1',
					zoneId: '3',
					createdAt: '2020-02-26T21:50:26.000Z',
					updatedAt: '2020-02-26T21:50:26.000Z',
					rackId: 69,
					storeProduct: {
						id: 310,
						totalStoreQuantity: 0,
						productId: 315,
						storeId: 1,
						product: {
							id: 315,
							productId: '307323-0-1',
							productName: '7-S SmkdGoudChp2.5z',
							productImageUrl:
								'https://lunchbox-product-images.s3.amazonaws.com/image_broken_black.png',
							productWeight: '67',
							calibrationFactor: '',
							weightScaleInfo: '',
							price: '149',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548565848',
							createdAt: '2020-06-03T19:51:39.000Z',
							updatedAt: '2020-06-03T19:55:05.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 291,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-06T01:24:48.000Z',
					updatedAt: '2019-11-06T01:24:48.000Z',
					rackId: 69,
					storeProduct: {
						id: 128,
						totalStoreQuantity: 0,
						productId: 127,
						storeId: 1,
						product: {
							id: 127,
							productId: '176191-0-1',
							productName:
								'English Muffin with Sausage, Egg and Cheese',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/176191_English_Muffin_with_Sausage_Egg_and_Cheese_plated_scroll1.png',
							productWeight: '167',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548585624',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 292,
					shelfIndex: '1',
					zoneId: '5',
					createdAt: '2019-11-06T01:25:25.000Z',
					updatedAt: '2019-11-06T01:25:25.000Z',
					rackId: 69,
					storeProduct: {
						id: 129,
						totalStoreQuantity: 0,
						productId: 128,
						storeId: 1,
						product: {
							id: 128,
							productId: '178675-0-1',
							productName: 'WB Ham Egg&ChsCrssnt',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '155',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 294,
					shelfIndex: '1',
					zoneId: '6',
					createdAt: '2019-11-06T01:26:30.000Z',
					updatedAt: '2019-11-06T01:26:30.000Z',
					rackId: 69,
					storeProduct: {
						id: 131,
						totalStoreQuantity: 0,
						productId: 130,
						storeId: 1,
						product: {
							id: 130,
							productId: '171767-0-1',
							productName: 'Strawberry Kiwi Mango Cup',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/171767_StrwbryKiwiMangoCup_scroll1.jpg',
							productWeight: '234',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 295,
					shelfIndex: '1',
					zoneId: '7',
					createdAt: '2019-11-06T01:27:01.000Z',
					updatedAt: '2019-11-06T01:27:01.000Z',
					rackId: 69,
					storeProduct: {
						id: 132,
						totalStoreQuantity: 0,
						productId: 131,
						storeId: 1,
						product: {
							id: 131,
							productId: '179490-0-1',
							productName: 'Fruit Blend',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/179490_fruit_blend_fruit_cup_outside_HR_480_hero_scroll1.png',
							productWeight: '146',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548555443',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 297,
					shelfIndex: '1',
					zoneId: '8',
					createdAt: '2019-11-06T01:27:31.000Z',
					updatedAt: '2019-11-06T01:27:31.000Z',
					rackId: 69,
					storeProduct: {
						id: 134,
						totalStoreQuantity: 0,
						productId: 133,
						storeId: 1,
						product: {
							id: 133,
							productId: '171994-0-1',
							productName: 'Chicken Alfredo',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/171994__chicken_alfredo_HR_480_hero_scroll1.png',
							productWeight: '419',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548611231',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 345,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2019-11-07T03:02:46.000Z',
					updatedAt: '2019-11-07T03:02:46.000Z',
					rackId: 69,
					storeProduct: {
						id: 178,
						totalStoreQuantity: 0,
						productId: 177,
						storeId: 1,
						product: {
							id: 177,
							productId: '243225-0-1',
							productName:
								'Vitamin Water XXX Acai Blueberry 32oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/243225_vitamin_water_xxx_32z_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00786162120328',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 235,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-04T15:25:47.000Z',
					updatedAt: '2019-11-04T15:25:47.000Z',
					rackId: 69,
					storeProduct: {
						id: 87,
						totalStoreQuantity: 29,
						productId: 86,
						storeId: 1,
						product: {
							id: 86,
							productId: '141478-0-1',
							productName: "Reese's Sticks King Size 3oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/141478_reesesstixking_SCROLL1.jpg',
							productWeight: '92',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00034000002030',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 298,
					shelfIndex: '2',
					zoneId: '3',
					createdAt: '2019-11-06T01:28:07.000Z',
					updatedAt: '2019-11-06T01:28:07.000Z',
					rackId: 69,
					storeProduct: {
						id: 135,
						totalStoreQuantity: 0,
						productId: 134,
						storeId: 1,
						product: {
							id: 134,
							productId: '176944-0-1',
							productName: 'HHSmMeatyLasagna',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '369',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 346,
					shelfIndex: '2',
					zoneId: '4',
					createdAt: '2019-11-07T03:03:19.000Z',
					updatedAt: '2019-11-07T03:03:19.000Z',
					rackId: 69,
					storeProduct: {
						id: 179,
						totalStoreQuantity: 0,
						productId: 178,
						storeId: 1,
						product: {
							id: 178,
							productId: '244124-0-1',
							productName: 'Powerade Zero Mixed Berry 32oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/244124_POWERADE_Zero_Mixed_Berry_Bottle__32_fl_oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
				null,
				null,
				null,
				null,
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 299,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-06T01:28:58.000Z',
					updatedAt: '2019-11-06T01:28:58.000Z',
					rackId: 69,
					storeProduct: {
						id: 136,
						totalStoreQuantity: 0,
						productId: 135,
						storeId: 1,
						product: {
							id: 135,
							productId: '177327-0-1',
							productName: 'Hot Held AllAmrcBfst',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '286',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 496,
					shelfIndex: '3',
					zoneId: '2',
					createdAt: '2020-02-26T01:52:07.000Z',
					updatedAt: '2020-02-26T01:52:07.000Z',
					rackId: 69,
					storeProduct: {
						id: 301,
						totalStoreQuantity: 0,
						productId: 306,
						storeId: 1,
						product: {
							id: 306,
							productId: '306535-0-1',
							productName: 'ON Protein Drk Choc Truffle Almonds',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/306535_ONProteinDrkChocTruffleAlmonds_scroll1.jpg',
							productWeight: '',
							calibrationFactor: '',
							weightScaleInfo: '',
							price: '249',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00748927058420',
							createdAt: '2020-06-03T18:52:15.000Z',
							updatedAt: '2020-06-03T18:52:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 497,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2020-02-26T01:52:44.000Z',
					updatedAt: '2020-02-26T01:52:44.000Z',
					rackId: 69,
					storeProduct: {
						id: 302,
						totalStoreQuantity: 0,
						productId: 307,
						storeId: 1,
						product: {
							id: 307,
							productId: '220943-0-1',
							productName:
								'Tampax Pearl Regular Unscented 8 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/220943_tampax_pearl_regular_scroll1.png',
							productWeight: '64',
							calibrationFactor: '',
							weightScaleInfo: '',
							price: '399',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00073010710511',
							createdAt: '2020-06-03T19:15:47.000Z',
							updatedAt: '2020-06-03T19:17:13.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 300,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2019-11-06T01:30:32.000Z',
					updatedAt: '2019-11-06T01:30:32.000Z',
					rackId: 69,
					storeProduct: {
						id: 137,
						totalStoreQuantity: 6,
						productId: 136,
						storeId: 1,
						product: {
							id: 136,
							productId: '243666-0-1',
							productName: 'Topo Chico Mineral Water 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/243666_topo_chico.png',
							productWeight: '620',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00021136010541',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 349,
					shelfIndex: '4',
					zoneId: '1',
					createdAt: '2019-11-07T03:05:26.000Z',
					updatedAt: '2019-11-07T03:05:26.000Z',
					rackId: 69,
					storeProduct: {
						id: 180,
						totalStoreQuantity: 0,
						productId: 179,
						storeId: 1,
						product: {
							id: 179,
							productId: '243604-0-1',
							productName: 'Powerade Mountain Blast 32oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/243604_POWERADE_Mountain_Berry_Blast_Bottle__32_fl_oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 301,
					shelfIndex: '4',
					zoneId: '2',
					createdAt: '2019-11-06T01:31:01.000Z',
					updatedAt: '2019-11-06T01:31:01.000Z',
					rackId: 69,
					storeProduct: {
						id: 138,
						totalStoreQuantity: 0,
						productId: 137,
						storeId: 1,
						product: {
							id: 137,
							productId: '173498-0-1',
							productName: 'Yoplait Strawberry Blueberry Parfait',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/173498_StrawberryBlueberryYogurt_scroll1300.png',
							productWeight: '209',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548621421',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 350,
					shelfIndex: '4',
					zoneId: '3',
					createdAt: '2019-11-07T03:05:57.000Z',
					updatedAt: '2019-11-07T03:05:57.000Z',
					rackId: 69,
					storeProduct: {
						id: 181,
						totalStoreQuantity: 14,
						productId: 180,
						storeId: 1,
						product: {
							id: 180,
							productId: '243285-0-1',
							productName: 'Gatorade ZERO Lemon Lime 28oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/243285_GatoradeZeroLemonLime_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052000042313',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 302,
					shelfIndex: '4',
					zoneId: '4',
					createdAt: '2019-11-06T01:31:33.000Z',
					updatedAt: '2019-11-06T01:31:33.000Z',
					rackId: 69,
					storeProduct: {
						id: 139,
						totalStoreQuantity: 12,
						productId: 138,
						storeId: 1,
						product: {
							id: 138,
							productId: '242397-0-1',
							productName: 'Topo Chico Twist of Lime 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/242397_TopoChicoTwistofLime_20z_scroll1.png',
							productWeight: '630',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00021136110180',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				null,
				null,
			],
		],
	},
	{
		id: 67,
		rackIndex: 'Rack 02',
		description: 'Rack number 1',
		left: 541.538,
		top: 193.941,
		width: 26.891,
		height: 73.1001,
		angle: 0,
		createdAt: '2019-10-17T19:43:02.000Z',
		updatedAt: '2019-10-17T19:43:02.000Z',
		gondolaId: 44,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 274,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2019-11-06T01:17:53.000Z',
					updatedAt: '2019-11-06T01:17:53.000Z',
					rackId: 67,
					storeProduct: {
						id: 111,
						totalStoreQuantity: 0,
						productId: 110,
						storeId: 1,
						product: {
							id: 110,
							productId: '141458-0-1',
							productName: 'Mentos Pure Fresh Mint Gum 50 Count',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/141458_mentos_purefreshmint_scroll1.png',
							productWeight: '127',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00073390014049',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 276,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-06T01:18:17.000Z',
					updatedAt: '2019-11-06T01:18:17.000Z',
					rackId: 67,
					storeProduct: {
						id: 113,
						totalStoreQuantity: 5,
						productId: 112,
						storeId: 1,
						product: {
							id: 112,
							productId: '220948-0-1',
							productName: 'Pepto Bismol Max Strength 4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/220948_pepto_bismol_scroll1.png',
							productWeight: '156',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00301490039304',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 277,
					shelfIndex: '1',
					zoneId: '3',
					createdAt: '2019-11-06T01:18:37.000Z',
					updatedAt: '2019-11-06T01:18:37.000Z',
					rackId: 67,
					storeProduct: {
						id: 114,
						totalStoreQuantity: 18,
						productId: 113,
						storeId: 1,
						product: {
							id: 113,
							productId: '220275-0-1',
							productName: 'BC Headache Powder 6 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/220275_BC_headache_powder_scroll1.png',
							productWeight: '11',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00042037103644',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 278,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-06T01:18:55.000Z',
					updatedAt: '2019-11-06T01:18:55.000Z',
					rackId: 67,
					storeProduct: {
						id: 115,
						totalStoreQuantity: 7,
						productId: 114,
						storeId: 1,
						product: {
							id: 114,
							productId: '220391-0-1',
							productName: 'ChapStick Moist Lip Blam .15oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/220391_chapstick_moist_balm_.15z_scroll1.png',
							productWeight: '12',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00036600828010',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 280,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2019-11-06T01:19:15.000Z',
					updatedAt: '2020-06-03T20:08:29.000Z',
					rackId: 67,
					storeProduct: {
						id: 117,
						totalStoreQuantity: 12,
						productId: 116,
						storeId: 1,
						product: {
							id: 116,
							productId: '220926-0-1',
							productName:
								'5-Hour Energy Extra Strength Grape 1.93oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/220926_5hour_energy_extra_strength_grape_1.93z_scroll1.png',
							productWeight: '69',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00719410720018',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 281,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-06T01:19:35.000Z',
					updatedAt: '2020-06-03T20:09:34.000Z',
					rackId: 67,
					storeProduct: {
						id: 118,
						totalStoreQuantity: 12,
						productId: 117,
						storeId: 1,
						product: {
							id: 117,
							productId: '220260-0-1',
							productName: '5-Hour Energy Pomegranate 1.93oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/220260_5hour_energy_pomegranate_1.93z_scroll1.png',
							productWeight: '67',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00719410800017',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 283,
					shelfIndex: '2',
					zoneId: '3',
					createdAt: '2019-11-06T01:19:52.000Z',
					updatedAt: '2020-06-03T20:11:21.000Z',
					rackId: 67,
					storeProduct: {
						id: 120,
						totalStoreQuantity: 12,
						productId: 119,
						storeId: 1,
						product: {
							id: 119,
							productId: '221463-0-1',
							productName: '7-Select Energy Shot XS Berry 2oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/221463_Berry_Sleeve_HR_scroll1.jpg',
							productWeight: '70',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548568009',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 284,
					shelfIndex: '2',
					zoneId: '4',
					createdAt: '2019-11-06T01:20:09.000Z',
					updatedAt: '2020-06-03T20:11:21.000Z',
					rackId: 67,
					storeProduct: {
						id: 121,
						totalStoreQuantity: 12,
						productId: 120,
						storeId: 1,
						product: {
							id: 120,
							productId: '221718-0-1',
							productName:
								'7-Select Energy Shot Pineapple Passion 2oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/221718_PineapplePassionFruit_Sleeve_HR_SCROLL1.jpg',
							productWeight: '70',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548588700',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 194,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-04T11:15:27.000Z',
					updatedAt: '2019-11-04T11:15:27.000Z',
					rackId: 67,
					storeProduct: {
						id: 68,
						totalStoreQuantity: 38,
						productId: 67,
						storeId: 1,
						product: {
							id: 67,
							productId: '140025-0-1',
							productName: 'Kit Kat King Size 3oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140025_kitkat_kingsize_scroll1.png',
							productWeight: '88',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00034000226009',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 195,
					shelfIndex: '3',
					zoneId: '2',
					createdAt: '2019-11-04T11:16:19.000Z',
					updatedAt: '2019-11-04T11:16:19.000Z',
					rackId: 67,
					storeProduct: {
						id: 69,
						totalStoreQuantity: 11,
						productId: 68,
						storeId: 1,
						product: {
							id: 68,
							productId: '140028-0-1',
							productName: 'M&M Plain Sharing Size 3.14oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140028_M_M_Plain_KingS_scroll1.jpg',
							productWeight: '92',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00040000002680',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 196,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-04T11:16:48.000Z',
					updatedAt: '2019-11-04T11:16:48.000Z',
					rackId: 67,
					storeProduct: {
						id: 70,
						totalStoreQuantity: 180,
						productId: 69,
						storeId: 1,
						product: {
							id: 69,
							productId: '140027-0-1',
							productName: 'M&M Peanut Sharing Size 3.27oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140027_mm_king_size_scroll1.png',
							productWeight: '95',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '219',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48449,
							upc: '00040000004325',
							createdAt: null,
							updatedAt: '2020-06-05T01:58:46.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 334,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2019-11-07T02:52:55.000Z',
					updatedAt: '2020-06-03T03:06:54.000Z',
					rackId: 67,
					storeProduct: {
						id: 167,
						totalStoreQuantity: 0,
						productId: 166,
						storeId: 1,
						product: {
							id: 166,
							productId: '241673-0-1',
							productName: 'LifeWtr 1L',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241673_life_water_1L_scroll1.png',
							productWeight: '1040',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000161155',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 501,
					shelfIndex: '4',
					zoneId: '1',
					createdAt: '2020-02-26T01:58:13.000Z',
					updatedAt: '2020-02-26T01:58:13.000Z',
					rackId: 67,
					storeProduct: {
						id: 304,
						totalStoreQuantity: 0,
						productId: 309,
						storeId: 1,
						product: {
							id: 309,
							productId: '240175-0-1',
							productName: 'Ozarka Spring 1L',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240175_OzarkaSpring_1L_scroll1.png',
							productWeight: '',
							calibrationFactor: '',
							weightScaleInfo: '',
							price: '189',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00022592114415',
							createdAt: '2020-06-03T19:23:37.000Z',
							updatedAt: '2020-06-03T19:23:37.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 476,
					shelfIndex: '4',
					zoneId: '2',
					createdAt: '2019-11-12T00:11:43.000Z',
					updatedAt: '2019-11-12T00:11:43.000Z',
					rackId: 67,
					storeProduct: {
						id: 287,
						totalStoreQuantity: 4,
						productId: 292,
						storeId: 1,
						product: {
							id: 292,
							productId: '141760-0-1',
							productName: 'Airheads Xtremes Sour Belts 2oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/141760_AirheadsXtremeSourBelts_2oz_scroll1.png',
							productWeight: '63',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00073390008376',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 335,
					shelfIndex: '4',
					zoneId: '3',
					createdAt: '2019-11-07T02:54:24.000Z',
					updatedAt: '2019-11-07T02:54:24.000Z',
					rackId: 67,
					storeProduct: {
						id: 168,
						totalStoreQuantity: 0,
						productId: 167,
						storeId: 1,
						product: {
							id: 167,
							productId: '170981-0-1',
							productName:
								'7-Select Chicken Skewer Sweet Sriracha',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/170981_7S_Skewer_SwtSriracha_4z_scroll1.png',
							productWeight: '153',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548618339',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 336,
					shelfIndex: '4',
					zoneId: '4',
					createdAt: '2019-11-07T02:54:50.000Z',
					updatedAt: '2019-11-07T02:54:50.000Z',
					rackId: 67,
					storeProduct: {
						id: 169,
						totalStoreQuantity: 13,
						productId: 168,
						storeId: 1,
						product: {
							id: 168,
							productId: '240171-0-1',
							productName: '7-Select Water 1L',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240171_7S_water_1l_scroll1.png',
							productWeight: '1024',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548513849',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
		],
	},
	{
		id: 66,
		rackIndex: 'Rack 01',
		description: 'Rack number 1',
		left: 570.27,
		top: 190.166,
		width: 30,
		height: 75.1149,
		angle: 0,
		createdAt: '2019-10-17T19:42:20.000Z',
		updatedAt: '2019-10-17T19:42:20.000Z',
		gondolaId: 44,
		shelves: [
			[
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 332,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-07T02:51:33.000Z',
					updatedAt: '2019-11-07T02:51:33.000Z',
					rackId: 66,
					storeProduct: {
						id: 166,
						totalStoreQuantity: 6,
						productId: 165,
						storeId: 1,
						product: {
							id: 165,
							productId: '170969-0-1',
							productName:
								'7-Select Chicken Skewer Mediterranean',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/170969_7SelectChickenSkewerMediterranean_scroll1.jpg',
							productWeight: '134',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548618315',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 267,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-06T00:20:50.000Z',
					updatedAt: '2019-11-06T00:20:50.000Z',
					rackId: 66,
					storeProduct: {
						id: 104,
						totalStoreQuantity: 5,
						productId: 103,
						storeId: 1,
						product: {
							id: 103,
							productId: '142623-0-1',
							productName: '7-Select Chewy Cinnamon 4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/142623_CinnChews_scroll1.png',
							productWeight: '124',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548616953',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 482,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2020-02-25T06:33:39.000Z',
					updatedAt: '2020-02-25T06:33:39.000Z',
					rackId: 66,
					storeProduct: {
						id: 290,
						totalStoreQuantity: 6,
						productId: 295,
						storeId: 1,
						product: {
							id: 295,
							productId: '247611-0-1',
							productName: 'Bai Cocofusion Molokai Coconut 18oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/247611_BaiCocoFusionMlokai_18oz_scroll1.png',
							productWeight: '582',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00852311004273',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 167,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-04T10:29:04.000Z',
					updatedAt: '2019-11-04T10:29:04.000Z',
					rackId: 66,
					storeProduct: {
						id: 51,
						totalStoreQuantity: 8,
						productId: 50,
						storeId: 1,
						product: {
							id: 50,
							productId: '301082-0-1',
							productName:
								'Nature Valley Fruit & Nut Trail Mix 1.2oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/301082_Nature_valley_Fruit___Nut_Trail_Mix_1.2z_scroll1.png',
							productWeight: '33',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00016000151208',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 192,
					shelfIndex: '2',
					zoneId: '3',
					createdAt: '2019-11-04T11:12:58.000Z',
					updatedAt: '2019-11-04T11:12:58.000Z',
					rackId: 66,
					storeProduct: {
						id: 66,
						totalStoreQuantity: 250,
						productId: 65,
						storeId: 1,
						product: {
							id: 65,
							productId: '140023-0-1',
							productName: 'Hershey’s Almond King Size 2.6oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140023_Hershey_Almond_KS_scroll1.jpg',
							productWeight: '73',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '219',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48398,
							upc: '20034000221008',
							createdAt: null,
							updatedAt: '2020-06-05T01:28:50.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 193,
					shelfIndex: '2',
					zoneId: '4',
					createdAt: '2019-11-04T11:13:44.000Z',
					updatedAt: '2019-11-04T11:13:44.000Z',
					rackId: 66,
					storeProduct: {
						id: 67,
						totalStoreQuantity: 30,
						productId: 66,
						storeId: 1,
						product: {
							id: 66,
							productId: '140024-0-1',
							productName:
								'Hershey’s Milk Chocolate King Size 2.6oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140024_HERSHEYs_Chocolate_Candy_Bar_King_Size_scroll1.jpg',
							productWeight: '70',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00034000002207',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 279,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-06T01:19:11.000Z',
					updatedAt: '2019-11-06T01:19:11.000Z',
					rackId: 66,
					storeProduct: {
						id: 116,
						totalStoreQuantity: 1,
						productId: 115,
						storeId: 1,
						product: {
							id: 115,
							productId: '220442-0-1',
							productName: 'Halls Honey-Lemon Stick 9 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/220442_halls_honey_lemon_scroll1.png',
							productWeight: '34',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00312546628724',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 282,
					shelfIndex: '3',
					zoneId: '2',
					createdAt: '2019-11-06T01:19:40.000Z',
					updatedAt: '2019-11-06T01:19:40.000Z',
					rackId: 66,
					storeProduct: {
						id: 119,
						totalStoreQuantity: 2,
						productId: 118,
						storeId: 1,
						product: {
							id: 118,
							productId: '220994-0-1',
							productName:
								'5-Hour Energy Extra Strength Berry 1.93oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/220994_5hour_xs_berry_scroll1.png',
							productWeight: '70',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00719410792541',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 268,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-06T00:25:23.000Z',
					updatedAt: '2019-11-06T00:25:23.000Z',
					rackId: 66,
					storeProduct: {
						id: 105,
						totalStoreQuantity: 9,
						productId: 104,
						storeId: 1,
						product: {
							id: 104,
							productId: '142762-0-1',
							productName: '7-Select Peach Rings 4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/142762_7_S_Peach_Rings_4z_scroll1.png',
							productWeight: '117',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548514020',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 269,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2019-11-06T00:25:58.000Z',
					updatedAt: '2020-06-03T20:00:36.000Z',
					rackId: 66,
					storeProduct: {
						id: 106,
						totalStoreQuantity: 1,
						productId: 105,
						storeId: 1,
						product: {
							id: 105,
							productId: '142767-0-1',
							productName: '7-Select Gummi Bears 4.25oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/142767_GussetBag_LR_GummiBears_scroll1.jpg',
							productWeight: '130',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548514143',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 164,
					shelfIndex: '4',
					zoneId: '1',
					createdAt: '2019-11-04T05:56:13.000Z',
					updatedAt: '2019-11-04T05:56:13.000Z',
					rackId: 66,
					storeProduct: {
						id: 49,
						totalStoreQuantity: 7,
						productId: 48,
						storeId: 1,
						product: {
							id: 48,
							productId: '300355-0-1',
							productName:
								'7-Select Vanilla Crème Sandwich Cookies 3.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/300355_7_Select_Vanilla_Crème_Sandwich_Cookies_scroll1.jpg',
							productWeight: '107',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548522353',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 331,
					shelfIndex: '4',
					zoneId: '2',
					createdAt: '2019-11-07T02:50:30.000Z',
					updatedAt: '2019-11-07T02:50:30.000Z',
					rackId: 66,
					storeProduct: {
						id: 165,
						totalStoreQuantity: 0,
						productId: 164,
						storeId: 1,
						product: {
							id: 164,
							productId: '245590-0-1',
							productName: 'Core Natural Mineral Water 1L',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/245590_core_hydration_30oz_scroll1.png',
							productWeight: '975',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00853004004037',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 329,
					shelfIndex: '4',
					zoneId: '3',
					createdAt: '2019-11-07T02:49:40.000Z',
					updatedAt: '2019-11-07T02:49:40.000Z',
					rackId: 66,
					storeProduct: {
						id: 163,
						totalStoreQuantity: 24,
						productId: 162,
						storeId: 1,
						product: {
							id: 162,
							productId: '247710-0-1',
							productName:
								'7-Select Sparkling Cranberry Raspberry Water 16.9oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/247710_7SSpklngWtrCranRsp_16.9oz_scroll1.png',
							productWeight: '535',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548601430',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 330,
					shelfIndex: '4',
					zoneId: '4',
					createdAt: '2019-11-07T02:49:58.000Z',
					updatedAt: '2019-11-07T02:49:58.000Z',
					rackId: 66,
					storeProduct: {
						id: 164,
						totalStoreQuantity: 6,
						productId: 163,
						storeId: 1,
						product: {
							id: 163,
							productId: '241701-0-1',
							productName: '7-Select Premium Water 1L',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241701_7_SelectWaterPrm_1L_scroll1.png',
							productWeight: '1088',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548575328',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
			],
		],
	},
	{
		id: 83,
		rackIndex: 'Rack 01',
		description: 'Enter Description Here',
		left: 219.316,
		top: 28.5054,
		width: 37.1717,
		height: 148.318,
		angle: 269.63,
		createdAt: '2019-10-17T19:54:34.000Z',
		updatedAt: '2020-06-04T01:37:18.000Z',
		gondolaId: 52,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 370,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2019-11-07T03:24:16.000Z',
					updatedAt: '2019-11-07T03:24:16.000Z',
					rackId: 83,
					storeProduct: {
						id: 198,
						totalStoreQuantity: 0,
						productId: 197,
						storeId: 1,
						product: {
							id: 197,
							productId: '303173-0-1',
							productName: 'Doritos Nacho Cheese 3.1oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/303173_doritos_nacho_cheese_3oz_Resized.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400028196',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 371,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-07T03:24:57.000Z',
					updatedAt: '2019-11-07T03:24:57.000Z',
					rackId: 83,
					storeProduct: {
						id: 199,
						totalStoreQuantity: 0,
						productId: 198,
						storeId: 1,
						product: {
							id: 198,
							productId: '307868-0-1',
							productName:
								'7-Select GoSmart Sweet Potato Tortilla Chips 2.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/307868_7_S_GS_SwtPotato_TortChip2.5z_SCROLL1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548608316',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 376,
					shelfIndex: '1',
					zoneId: '3',
					createdAt: '2019-11-07T03:27:21.000Z',
					updatedAt: '2019-11-07T03:27:21.000Z',
					rackId: 83,
					storeProduct: {
						id: 204,
						totalStoreQuantity: 0,
						productId: 203,
						storeId: 1,
						product: {
							id: 203,
							productId: '307436-0-1',
							productName:
								'7-Select Kettle Salt & Vinegar Chips 2.25oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/307436_KettlePotatoChips_SaltVinegar_SCROLL1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548511708',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 379,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-07T03:29:36.000Z',
					updatedAt: '2019-11-07T03:29:36.000Z',
					rackId: 83,
					storeProduct: {
						id: 207,
						totalStoreQuantity: 0,
						productId: 206,
						storeId: 1,
						product: {
							id: 206,
							productId: '303211-0-1',
							productName: '7-Select White Cheddar Popcorn 1.4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/303211_7Eleven_Popcorn_WhiteCheddr_1oz_bg_scroll1_copy.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548605926',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 381,
					shelfIndex: '1',
					zoneId: '5',
					createdAt: '2019-11-07T03:29:56.000Z',
					updatedAt: '2019-11-07T03:29:56.000Z',
					rackId: 83,
					storeProduct: {
						id: 208,
						totalStoreQuantity: 8,
						productId: 207,
						storeId: 1,
						product: {
							id: 207,
							productId: '210209-0-1',
							productName:
								'Kraft Velveeta Shells & Cheese Cup 2.39oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/210209_KraftVelveetaCupShls_Chs_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00021000023226',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 384,
					shelfIndex: '1',
					zoneId: '6',
					createdAt: '2019-11-07T03:31:01.000Z',
					updatedAt: '2019-11-07T03:31:01.000Z',
					rackId: 83,
					storeProduct: {
						id: 211,
						totalStoreQuantity: 10,
						productId: 210,
						storeId: 1,
						product: {
							id: 210,
							productId: '210485-0-1',
							productName:
								"Campbell's Chunky Roadhouse Chili with Beans 15.25oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/210485_Campbells_Chunky_Roadhouse_Chili_Beans_15.25oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00051000159045',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 387,
					shelfIndex: '1',
					zoneId: '7',
					createdAt: '2019-11-07T03:32:07.000Z',
					updatedAt: '2019-11-07T03:32:07.000Z',
					rackId: 83,
					storeProduct: {
						id: 214,
						totalStoreQuantity: 8,
						productId: 213,
						storeId: 1,
						product: {
							id: 213,
							productId: '301181-0-1',
							productName: '7-Select Mixed Nuts 2.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/301181_7E_MixedNuts2_5oz_480_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548550585',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 390,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2019-11-07T03:33:33.000Z',
					updatedAt: '2019-11-07T03:33:33.000Z',
					rackId: 83,
					storeProduct: {
						id: 217,
						totalStoreQuantity: 3,
						productId: 216,
						storeId: 1,
						product: {
							id: 216,
							productId: '302602-0-1',
							productName: '7-Select Go Smart Rise & Shine 2.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/302602_GrabGo_TubeBag_RiseShine_480_scroll1.jpg',
							productWeight: '0',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548581701',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 431,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-07T05:06:22.000Z',
					updatedAt: '2019-11-07T05:06:22.000Z',
					rackId: 83,
					storeProduct: {
						id: 255,
						totalStoreQuantity: 7,
						productId: 254,
						storeId: 1,
						product: {
							id: 254,
							productId: '245397-0-1',
							productName: 'Starbucks Cold Brew Cocoa Honey 11oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/245397_Starbucks_Cold_Brew_Cocoa_Honey_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000162541',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 430,
					shelfIndex: '2',
					zoneId: '3',
					createdAt: '2019-11-07T05:05:36.000Z',
					updatedAt: '2019-11-07T05:05:36.000Z',
					rackId: 83,
					storeProduct: {
						id: 254,
						totalStoreQuantity: 10,
						productId: 253,
						storeId: 1,
						product: {
							id: 253,
							productId: '242015-0-1',
							productName: 'Starbucks Frappuccino Mocha 13.7oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/242015_starbucks_frap_mocha_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000004520',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 391,
					shelfIndex: '2',
					zoneId: '4',
					createdAt: '2019-11-07T03:36:24.000Z',
					updatedAt: '2019-11-07T03:36:24.000Z',
					rackId: 83,
					storeProduct: {
						id: 218,
						totalStoreQuantity: 7,
						productId: 217,
						storeId: 1,
						product: {
							id: 217,
							productId: '300325-0-1',
							productName: 'Wonderful Pistachios 1.25oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/300325_WonderfulPistachios1.25oz_scroll1.jpg',
							productWeight: '0',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00014113913454',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 392,
					shelfIndex: '2',
					zoneId: '5',
					createdAt: '2019-11-07T03:37:07.000Z',
					updatedAt: '2019-11-07T03:37:07.000Z',
					rackId: 83,
					storeProduct: {
						id: 219,
						totalStoreQuantity: 3,
						productId: 218,
						storeId: 1,
						product: {
							id: 218,
							productId: '302112-0-1',
							productName: 'Jack Links Teriyaki Jerky 3.25oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/302112_TERIYAKI_BEEF_JERKy_scroll1.png',
							productWeight: '0',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00017082007957',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 393,
					shelfIndex: '2',
					zoneId: '6',
					createdAt: '2019-11-07T03:38:23.000Z',
					updatedAt: '2019-11-07T03:38:23.000Z',
					rackId: 83,
					storeProduct: {
						id: 220,
						totalStoreQuantity: 1,
						productId: 219,
						storeId: 1,
						product: {
							id: 219,
							productId: '302450-0-1',
							productName:
								'7-Select Jack Links Mahalo Teriyaki Beef Jerky 3.25oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/302450_7SJL_MahaloTeriyakiBfJrky3.25z_scroll1.png',
							productWeight: '0',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00017082884770',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 394,
					shelfIndex: '2',
					zoneId: '7',
					createdAt: '2019-11-07T03:39:13.000Z',
					updatedAt: '2019-11-07T03:39:13.000Z',
					rackId: 83,
					storeProduct: {
						id: 221,
						totalStoreQuantity: 2,
						productId: 220,
						storeId: 1,
						product: {
							id: 220,
							productId: '302612-0-1',
							productName:
								'7-Select Jack Links Bold Cracked Pepper Beef Jerky 3.25oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/302612_7SJLBoldCrckedPpperBfJrky3.25z_scroll1.png',
							productWeight: '0',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00017082884763',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 395,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-07T03:41:00.000Z',
					updatedAt: '2019-11-07T03:41:00.000Z',
					rackId: 83,
					storeProduct: {
						id: 222,
						totalStoreQuantity: 3,
						productId: 221,
						storeId: 1,
						product: {
							id: 221,
							productId: '307259-0-1',
							productName: '7-Select Roasted Salted Cashews 3oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/307259_7E_WholeCashews3oz_480_scroll1.jpg',
							productWeight: '0',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548601560',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 397,
					shelfIndex: '3',
					zoneId: '2',
					createdAt: '2019-11-07T03:43:06.000Z',
					updatedAt: '2019-11-07T03:43:06.000Z',
					rackId: 83,
					storeProduct: {
						id: 224,
						totalStoreQuantity: 10,
						productId: 223,
						storeId: 1,
						product: {
							id: 223,
							productId: '307250-0-1',
							productName: '7-Select Dark Chocolate Almonds 8oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/307250_7S_DrkChocAlmonds_8oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548557331',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 272,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-06T01:14:04.000Z',
					updatedAt: '2019-11-06T01:14:04.000Z',
					rackId: 83,
					storeProduct: {
						id: 109,
						totalStoreQuantity: 1,
						productId: 108,
						storeId: 1,
						product: {
							id: 108,
							productId: '143081-0-1',
							productName:
								'Ice Breakers Ice Cubes Peppermint 40 Count',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/143081_Ice_Breakers_IceCube_Pemint_scroll1.jpg',
							productWeight: '115',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00034000008438',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 273,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2019-11-06T01:17:27.000Z',
					updatedAt: '2019-11-06T01:17:27.000Z',
					rackId: 83,
					storeProduct: {
						id: 110,
						totalStoreQuantity: 4,
						productId: 109,
						storeId: 1,
						product: {
							id: 109,
							productId: '143000-0-1',
							productName: 'Mentos Pure Fresh Spearmint Gum 50oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/143000_mentosgum_sprmint_scroll1.png',
							productWeight: '127',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00073390014056',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 275,
					shelfIndex: '3',
					zoneId: '5',
					createdAt: '2019-11-06T01:18:04.000Z',
					updatedAt: '2019-11-06T01:18:04.000Z',
					rackId: 83,
					storeProduct: {
						id: 112,
						totalStoreQuantity: 1,
						productId: 111,
						storeId: 1,
						product: {
							id: 111,
							productId: '220254-0-1',
							productName: 'Halls Cherry Stick 9 Count',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/220254_halls_cherry_stick_scroll1.png',
							productWeight: '34',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00312546627710',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 402,
					shelfIndex: '3',
					zoneId: '6',
					createdAt: '2019-11-07T03:45:30.000Z',
					updatedAt: '2019-11-07T03:45:30.000Z',
					rackId: 83,
					storeProduct: {
						id: 229,
						totalStoreQuantity: 11,
						productId: 228,
						storeId: 1,
						product: {
							id: 228,
							productId: '241735-0-1',
							productName: 'Pepsi Zero Sugar 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241735_PepsiZero_20oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000018800',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 403,
					shelfIndex: '3',
					zoneId: '7',
					createdAt: '2019-11-07T03:47:39.000Z',
					updatedAt: '2019-11-07T03:47:39.000Z',
					rackId: 83,
					storeProduct: {
						id: 230,
						totalStoreQuantity: 2,
						productId: 229,
						storeId: 1,
						product: {
							id: 229,
							productId: '240278-0-1',
							productName: 'Mountain Dew 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240278_MtDew_20oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000005657',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 405,
					shelfIndex: '4',
					zoneId: '1',
					createdAt: '2019-11-07T03:48:32.000Z',
					updatedAt: '2019-11-07T03:48:32.000Z',
					rackId: 83,
					storeProduct: {
						id: 232,
						totalStoreQuantity: 12,
						productId: 231,
						storeId: 1,
						product: {
							id: 231,
							productId: '240745-0-1',
							productName: 'Dr Pepper 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240745_drpepper_20oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00054900720506',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 408,
					shelfIndex: '4',
					zoneId: '2',
					createdAt: '2019-11-07T03:50:05.000Z',
					updatedAt: '2019-11-07T03:50:05.000Z',
					rackId: 83,
					storeProduct: {
						id: 235,
						totalStoreQuantity: 18,
						productId: 234,
						storeId: 1,
						product: {
							id: 234,
							productId: '242348-0-1',
							productName: 'Coke Zero 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/242348_cokezero._scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00049000140866',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 410,
					shelfIndex: '4',
					zoneId: '3',
					createdAt: '2019-11-07T03:51:31.000Z',
					updatedAt: '2019-11-07T03:51:31.000Z',
					rackId: 83,
					storeProduct: {
						id: 237,
						totalStoreQuantity: 0,
						productId: 236,
						storeId: 1,
						product: {
							id: 236,
							productId: '240934-0-1',
							productName: 'Canada Dry Ginger Ale',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240934_canada_dry_gingerale_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00016900010513',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 411,
					shelfIndex: '4',
					zoneId: '4',
					createdAt: '2019-11-07T03:52:38.000Z',
					updatedAt: '2019-11-07T03:52:38.000Z',
					rackId: 83,
					storeProduct: {
						id: 238,
						totalStoreQuantity: 27,
						productId: 237,
						storeId: 1,
						product: {
							id: 237,
							productId: '240968-0-1',
							productName: 'A&W Root Beer 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240968_AW_rootbeer_20oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00070202106152',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 480,
					shelfIndex: '4',
					zoneId: '5',
					createdAt: '2019-12-04T21:46:52.000Z',
					updatedAt: '2019-12-04T21:46:52.000Z',
					rackId: 83,
					storeProduct: {
						id: 289,
						totalStoreQuantity: 6,
						productId: 294,
						storeId: 1,
						product: {
							id: 294,
							productId: '246389-0-1',
							productName: 'Pure Leaf Unsweetened Tea 18.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/246389_purelead_unsweetend_scroll1.png',
							productWeight: '601',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000286209',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
			],
		],
	},
	{
		id: 85,
		rackIndex: 'Rack 01',
		description: 'Rack number 1',
		left: 100.325,
		top: 436.066,
		width: 53.2223,
		height: 117.448,
		angle: 180.135,
		createdAt: '2019-10-17T19:55:21.000Z',
		updatedAt: '2020-06-04T01:32:33.000Z',
		gondolaId: 53,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 372,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2019-11-07T03:25:29.000Z',
					updatedAt: '2019-11-07T03:25:29.000Z',
					rackId: 85,
					storeProduct: {
						id: 200,
						totalStoreQuantity: 0,
						productId: 199,
						storeId: 1,
						product: {
							id: 199,
							productId: '307391-0-1',
							productName:
								'7-Select Kettle Original Chips 2.25oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/301038_7SelectKettleOriginalChips_2.25oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548511692',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 396,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-07T03:42:13.000Z',
					updatedAt: '2019-11-07T03:42:13.000Z',
					rackId: 85,
					storeProduct: {
						id: 223,
						totalStoreQuantity: 0,
						productId: 222,
						storeId: 1,
						product: {
							id: 222,
							productId: '307247-0-1',
							productName:
								'7-Select Roasted & Salted Almonds 3oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/307247_7E_RstdSaltedAlmonds3oz_480_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548601485',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 398,
					shelfIndex: '1',
					zoneId: '3',
					createdAt: '2019-11-07T03:43:06.000Z',
					updatedAt: '2019-11-07T03:43:06.000Z',
					rackId: 85,
					storeProduct: {
						id: 225,
						totalStoreQuantity: 15,
						productId: 224,
						storeId: 1,
						product: {
							id: 224,
							productId: '307258-0-1',
							productName:
								'7-Select Roasted Salted Whole Cashew 6oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/307258_7_S_Rstd_Sltd_WholeCashew6z_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548550691',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 399,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-07T03:44:01.000Z',
					updatedAt: '2019-11-07T03:44:01.000Z',
					rackId: 85,
					storeProduct: {
						id: 226,
						totalStoreQuantity: 12,
						productId: 225,
						storeId: 1,
						product: {
							id: 225,
							productId: '300092-0-1',
							productName: 'Cheez-It Original Cheddar 3oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/300092_cheezit_orig_Resized_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00024100191321',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 373,
					shelfIndex: '1',
					zoneId: '5',
					createdAt: '2019-11-07T03:26:11.000Z',
					updatedAt: '2019-11-07T03:26:11.000Z',
					rackId: 85,
					storeProduct: {
						id: 201,
						totalStoreQuantity: 0,
						productId: 200,
						storeId: 1,
						product: {
							id: 200,
							productId: '305758-0-1',
							productName:
								'7-Select Kettle Smoked Gouda Cheese Chips 2.25pz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/305758_7SelectKettleSmokedGoudaCheeseChips_2.25oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 374,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2019-11-07T03:26:43.000Z',
					updatedAt: '2019-11-07T03:26:43.000Z',
					rackId: 85,
					storeProduct: {
						id: 202,
						totalStoreQuantity: 0,
						productId: 201,
						storeId: 1,
						product: {
							id: 201,
							productId: '300931-0-1',
							productName: '7-Select Kettle BBQ Chips 2.25 oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/300931_7SelectKettleBBQChips_2.25oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 271,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-06T00:27:02.000Z',
					updatedAt: '2019-11-06T00:27:02.000Z',
					rackId: 85,
					storeProduct: {
						id: 108,
						totalStoreQuantity: 12,
						productId: 107,
						storeId: 1,
						product: {
							id: 107,
							productId: '145542-0-1',
							productName:
								'Trolli Slurpee Sour Brite Crawlers Cherry, Cola & Blue Raspberry 5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/145542_TrolliSlurpeeSourBriteCrwlrChryCola_BlueRspbry_scroll1.jpg',
							productWeight: '114',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00041420025815',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 270,
					shelfIndex: '2',
					zoneId: '3',
					createdAt: '2019-11-06T00:26:21.000Z',
					updatedAt: '2019-11-06T00:26:21.000Z',
					rackId: 85,
					storeProduct: {
						id: 107,
						totalStoreQuantity: 5,
						productId: 106,
						storeId: 1,
						product: {
							id: 106,
							productId: '142967-0-1',
							productName: 'Airheads Bites White Mystery 6oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/142967_airheadsbites_scroll1.jpg',
							productWeight: '173',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00073390014605',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 375,
					shelfIndex: '2',
					zoneId: '4',
					createdAt: '2019-11-07T03:27:21.000Z',
					updatedAt: '2019-11-07T03:27:21.000Z',
					rackId: 85,
					storeProduct: {
						id: 203,
						totalStoreQuantity: 0,
						productId: 202,
						storeId: 1,
						product: {
							id: 202,
							productId: '307434-0-1',
							productName:
								'7-Select Kettle Spiced Jalapeno Chips 2.25oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/301696_KettlePotatoChips_SpicyJalapeno_SCROLL1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548511753',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 377,
					shelfIndex: '2',
					zoneId: '5',
					createdAt: '2019-11-07T03:28:45.000Z',
					updatedAt: '2019-11-07T03:28:45.000Z',
					rackId: 85,
					storeProduct: {
						id: 205,
						totalStoreQuantity: 0,
						productId: 204,
						storeId: 1,
						product: {
							id: 204,
							productId: '307866-0-1',
							productName:
								'7-Select Kettle Salt & Pepper Chips 2.25oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/307866_7SelectKettleSalt_PepperChips_2.25oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 378,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-07T03:29:17.000Z',
					updatedAt: '2019-11-07T03:29:17.000Z',
					rackId: 85,
					storeProduct: {
						id: 206,
						totalStoreQuantity: 0,
						productId: 205,
						storeId: 1,
						product: {
							id: 205,
							productId: '303205-0-1',
							productName: '7-Select Caramel Popcorn 3.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/303205_7SCarmelPopcorn_SCROLL1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548605902',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 400,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-07T03:44:46.000Z',
					updatedAt: '2019-11-07T03:44:46.000Z',
					rackId: 85,
					storeProduct: {
						id: 227,
						totalStoreQuantity: 13,
						productId: 226,
						storeId: 1,
						product: {
							id: 226,
							productId: '240275-0-1',
							productName: 'Pepsi 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240275_pepsi20oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000005312',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 382,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2019-11-07T03:30:17.000Z',
					updatedAt: '2019-11-07T03:30:17.000Z',
					rackId: 85,
					storeProduct: {
						id: 209,
						totalStoreQuantity: 14,
						productId: 208,
						storeId: 1,
						product: {
							id: 208,
							productId: '210882-0-1',
							productName: 'Kraft Easy Mac and Cheese Cup 2.05oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/210882_kraft_mac_cheese_2.1z_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00021000010875',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 401,
					shelfIndex: '3',
					zoneId: '5',
					createdAt: '2019-11-07T03:45:27.000Z',
					updatedAt: '2019-11-07T03:45:27.000Z',
					rackId: 85,
					storeProduct: {
						id: 228,
						totalStoreQuantity: 13,
						productId: 227,
						storeId: 1,
						product: {
							id: 227,
							productId: '240277-0-1',
							productName: 'Diet Pepsi 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240277_Pepsi_Dt_20z_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000001307',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 383,
					shelfIndex: '4',
					zoneId: '1',
					createdAt: '2019-11-07T03:30:49.000Z',
					updatedAt: '2019-11-07T03:30:49.000Z',
					rackId: 85,
					storeProduct: {
						id: 210,
						totalStoreQuantity: 6,
						productId: 209,
						storeId: 1,
						product: {
							id: 209,
							productId: '210831-0-1',
							productName:
								"Campbell's Chicken Noodle Soup Cup 15.4oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/210831_CampChickenNoodle_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00051000134592',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 385,
					shelfIndex: '4',
					zoneId: '2',
					createdAt: '2019-11-07T03:31:17.000Z',
					updatedAt: '2019-11-07T03:31:17.000Z',
					rackId: 85,
					storeProduct: {
						id: 212,
						totalStoreQuantity: 15,
						productId: 211,
						storeId: 1,
						product: {
							id: 211,
							productId: '210549-0-1',
							productName:
								"Campbell's Chunky Chicken Noodle Soup 15.25oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/210549_CampChunkyChickenNdl_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00051000148803',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 386,
					shelfIndex: '4',
					zoneId: '3',
					createdAt: '2019-11-07T03:31:38.000Z',
					updatedAt: '2019-11-07T03:31:38.000Z',
					rackId: 85,
					storeProduct: {
						id: 213,
						totalStoreQuantity: 1,
						productId: 212,
						storeId: 1,
						product: {
							id: 212,
							productId: '210622-0-1',
							productName:
								'Maruchan Instant Lunch Hot & Spicy Beef',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/210622_MaruchanInstLnchHot_SpicyBeef_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00041789001420',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 388,
					shelfIndex: '4',
					zoneId: '4',
					createdAt: '2019-11-07T03:32:10.000Z',
					updatedAt: '2019-11-07T03:32:10.000Z',
					rackId: 85,
					storeProduct: {
						id: 215,
						totalStoreQuantity: 0,
						productId: 214,
						storeId: 1,
						product: {
							id: 214,
							productId: '302607-0-1',
							productName:
								'7-Select Go Smart Revive & Thrive 2.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/302607_GrabGo_TubeBag_ReviveThrive_480_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548581718',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 389,
					shelfIndex: '4',
					zoneId: '5',
					createdAt: '2019-11-07T03:32:30.000Z',
					updatedAt: '2019-11-07T03:32:30.000Z',
					rackId: 85,
					storeProduct: {
						id: 216,
						totalStoreQuantity: 3,
						productId: 215,
						storeId: 1,
						product: {
							id: 215,
							productId: '302617-0-1',
							productName:
								'7-Select GO! Smart Fit & Fuel Protein Blend 2.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/302617_7_SGoSmartFitFuel_scroll1.png',
							productWeight: '0',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548581732',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
		],
	},
	{
		id: 77,
		rackIndex: 'Rack 01',
		description: 'Rack number 1',
		left: 692.643,
		top: 50.172,
		width: 116.677,
		height: 56.0901,
		angle: 0,
		createdAt: '2019-10-17T19:50:55.000Z',
		updatedAt: '2019-10-17T19:50:55.000Z',
		gondolaId: 49,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 415,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2019-11-07T04:03:08.000Z',
					updatedAt: '2019-11-07T04:03:08.000Z',
					rackId: 77,
					storeProduct: {
						id: 239,
						totalStoreQuantity: 10,
						productId: 238,
						storeId: 1,
						product: {
							id: 238,
							productId: '240812-0-1',
							productName: 'Diet Dr Pepper 20oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240812_diet_drpepper_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00054900000363',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 416,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-07T04:04:01.000Z',
					updatedAt: '2019-11-07T04:04:01.000Z',
					rackId: 77,
					storeProduct: {
						id: 240,
						totalStoreQuantity: 20,
						productId: 239,
						storeId: 1,
						product: {
							id: 239,
							productId: '241133-0-1',
							productName: '7-Select Raspberry Tea 23.9oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241133_7STeawRaspberry23.9z_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '119',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48395,
							upc: '00052548590291',
							createdAt: null,
							updatedAt: '2020-06-03T22:06:17.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 417,
					shelfIndex: '1',
					zoneId: '3',
					createdAt: '2019-11-07T04:04:50.000Z',
					updatedAt: '2019-11-07T04:04:50.000Z',
					rackId: 77,
					storeProduct: {
						id: 241,
						totalStoreQuantity: 4,
						productId: 240,
						storeId: 1,
						product: {
							id: 240,
							productId: '247479-0-1',
							productName:
								'7-Select Cold Brew Unsweet Tea 18.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/247479_OrganicTea_UnsweetBlk_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00005000005505',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 418,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-07T04:05:42.000Z',
					updatedAt: '2019-11-07T04:05:42.000Z',
					rackId: 77,
					storeProduct: {
						id: 242,
						totalStoreQuantity: 13,
						productId: 241,
						storeId: 1,
						product: {
							id: 241,
							productId: '247486-0-1',
							productName:
								'7-Select Cold Brew Honey Green Tea 18.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/247486_7s_coldbrew_honey_green_tea_18.5z_scroll1_copy.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548616502',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 419,
					shelfIndex: '1',
					zoneId: '5',
					createdAt: '2019-11-07T04:07:09.000Z',
					updatedAt: '2019-11-07T04:07:09.000Z',
					rackId: 77,
					storeProduct: {
						id: 243,
						totalStoreQuantity: 8,
						productId: 242,
						storeId: 1,
						product: {
							id: 242,
							productId: '247520-0-1',
							productName:
								'7-Select Cold Brew Mango Green Tea 18.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/247520_OrganicTea_Mango_18.5z_scroll1_copy.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548616519',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 436,
					shelfIndex: '1',
					zoneId: '6',
					createdAt: '2019-11-07T05:25:09.000Z',
					updatedAt: '2019-11-07T05:25:09.000Z',
					rackId: 77,
					storeProduct: {
						id: 259,
						totalStoreQuantity: 11,
						productId: 258,
						storeId: 1,
						product: {
							id: 258,
							productId: '242444-0-1',
							productName: 'Celsius Sparking Orange 12oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/242444_Celsius_Sparking_Orange_12z_Can_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00889392000313',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 420,
					shelfIndex: '1',
					zoneId: '7',
					createdAt: '2019-11-07T04:09:50.000Z',
					updatedAt: '2019-11-07T04:09:50.000Z',
					rackId: 77,
					storeProduct: {
						id: 244,
						totalStoreQuantity: 21,
						productId: 243,
						storeId: 1,
						product: {
							id: 243,
							productId: '240961-0-1',
							productName:
								'7-Select Green Tea Honey Ginseng 23.9oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240961_ValueTea_GreenTea_LR_SCROLL1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548590246',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 256,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2019-11-06T00:09:19.000Z',
					updatedAt: '2019-11-06T00:09:19.000Z',
					rackId: 77,
					storeProduct: {
						id: 95,
						totalStoreQuantity: 36,
						productId: 94,
						storeId: 1,
						product: {
							id: 94,
							productId: '140157-0-1',
							productName: 'Skittles Original 2.17oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140157_SkittlesOriginal_scroll1.png',
							productWeight: '64',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00040000001607',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 255,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-06T00:08:38.000Z',
					updatedAt: '2019-11-06T00:08:38.000Z',
					rackId: 77,
					storeProduct: {
						id: 94,
						totalStoreQuantity: 39,
						productId: 93,
						storeId: 1,
						product: {
							id: 93,
							productId: '140150-0-1',
							productName: 'Mamba Fruit Chew Stick Pack 2.65oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140150_MambaFrutiCewStickPack_scroll1.png',
							productWeight: '85',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00072799035112',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 254,
					shelfIndex: '2',
					zoneId: '5',
					createdAt: '2019-11-06T00:07:05.000Z',
					updatedAt: '2019-11-06T00:07:05.000Z',
					rackId: 77,
					storeProduct: {
						id: 93,
						totalStoreQuantity: 61,
						productId: 92,
						storeId: 1,
						product: {
							id: 92,
							productId: '140067-0-1',
							productName: 'Nestle Crunch 1.55oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140067_NestleCrunchBar_scroll1.png',
							productWeight: '47',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028000131708',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 253,
					shelfIndex: '2',
					zoneId: '6',
					createdAt: '2019-11-06T00:06:01.000Z',
					updatedAt: '2019-11-06T00:06:01.000Z',
					rackId: 77,
					storeProduct: {
						id: 92,
						totalStoreQuantity: 44,
						productId: 91,
						storeId: 1,
						product: {
							id: 91,
							productId: '140070-0-1',
							productName: "Reese's Peanut Butter Cup 1.5oz",
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140070_Reese_s_PeanutButterCup_Scroll1.png',
							productWeight: '46',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00034000440009',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 252,
					shelfIndex: '2',
					zoneId: '7',
					createdAt: '2019-11-06T00:03:44.000Z',
					updatedAt: '2019-11-06T00:03:44.000Z',
					rackId: 77,
					storeProduct: {
						id: 91,
						totalStoreQuantity: 31,
						productId: 90,
						storeId: 1,
						product: {
							id: 90,
							productId: '145855-0-1',
							productName: 'Kit Kat Dark King Size 3oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/145855_KitKatDarkChocKS_scroll1.png',
							productWeight: '92',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00034000236237',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 263,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-06T00:14:43.000Z',
					updatedAt: '2019-11-06T00:14:43.000Z',
					rackId: 77,
					storeProduct: {
						id: 101,
						totalStoreQuantity: 28,
						productId: 100,
						storeId: 1,
						product: {
							id: 100,
							productId: '141439-0-1',
							productName: 'Sour Patch Kids 5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/141439_sourpatch_assorted_scroll1.png',
							productWeight: '146',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00070462062250',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 262,
					shelfIndex: '3',
					zoneId: '2',
					createdAt: '2019-11-06T00:14:08.000Z',
					updatedAt: '2019-11-06T00:14:08.000Z',
					rackId: 77,
					storeProduct: {
						id: 100,
						totalStoreQuantity: 20,
						productId: 99,
						storeId: 1,
						product: {
							id: 99,
							productId: '141309-0-1',
							productName: 'Trolli Sour Brite Crawlers 5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/141309_TrolliSourBriteCrwlr_5oz_scroll1.jpg',
							productWeight: '152',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00020709012302',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 261,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-06T00:13:34.000Z',
					updatedAt: '2019-11-06T00:13:34.000Z',
					rackId: 77,
					storeProduct: {
						id: 99,
						totalStoreQuantity: 18,
						productId: 98,
						storeId: 1,
						product: {
							id: 98,
							productId: '140594-0-1',
							productName: '7-Select Gummi Fun Bugs 4oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140594_7select_gummibugs_scroll1.png',
							productWeight: '118',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548603243',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 259,
					shelfIndex: '3',
					zoneId: '5',
					createdAt: '2019-11-06T00:11:39.000Z',
					updatedAt: '2019-11-06T00:11:39.000Z',
					rackId: 77,
					storeProduct: {
						id: 98,
						totalStoreQuantity: 7,
						productId: 97,
						storeId: 1,
						product: {
							id: 97,
							productId: '140312-0-1',
							productName: '7-Select Gummi Worms 4.25oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140312_7_S_GummiWorms_4.25z_scroll1.png',
							productWeight: '124',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548603175',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 258,
					shelfIndex: '3',
					zoneId: '6',
					createdAt: '2019-11-06T00:11:02.000Z',
					updatedAt: '2019-11-06T00:11:02.000Z',
					rackId: 77,
					storeProduct: {
						id: 97,
						totalStoreQuantity: 30,
						productId: 96,
						storeId: 1,
						product: {
							id: 96,
							productId: '141640-0-1',
							productName: 'Starburst Minis Original Share 3.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/141640_StarburstMiniShare_scroll1.png',
							productWeight: '104',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00022000014696',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 257,
					shelfIndex: '3',
					zoneId: '7',
					createdAt: '2019-11-06T00:10:01.000Z',
					updatedAt: '2019-11-06T00:10:01.000Z',
					rackId: 77,
					storeProduct: {
						id: 96,
						totalStoreQuantity: 34,
						productId: 95,
						storeId: 1,
						product: {
							id: 95,
							productId: '140465-0-1',
							productName: 'Skittles Sours 1.8oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/140465_SkittlesSours_scroll1.png',
							productWeight: '54',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00040000002666',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
		],
	},
	{
		id: 79,
		rackIndex: 'Rack 01',
		description: 'Rack number 1',
		left: 570.547,
		top: 51.1597,
		width: 102.829,
		height: 58.8683,
		angle: 0,
		createdAt: '2019-10-17T19:52:39.000Z',
		updatedAt: '2019-10-17T19:52:39.000Z',
		gondolaId: 50,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 434,
					shelfIndex: '1',
					zoneId: '1',
					createdAt: '2019-11-07T05:12:06.000Z',
					updatedAt: '2019-11-07T05:12:06.000Z',
					rackId: 79,
					storeProduct: {
						id: 257,
						totalStoreQuantity: 11,
						productId: 256,
						storeId: 1,
						product: {
							id: 256,
							productId: '245052-0-1',
							productName:
								'7-Select Cold Brew Coffee Espresso 11oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/245052_7_Select_Cold_Brew_Coffee_Expresso_11z_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548589011',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 421,
					shelfIndex: '1',
					zoneId: '2',
					createdAt: '2019-11-07T04:25:24.000Z',
					updatedAt: '2019-11-07T04:25:24.000Z',
					rackId: 79,
					storeProduct: {
						id: 245,
						totalStoreQuantity: 22,
						productId: 244,
						storeId: 1,
						product: {
							id: 244,
							productId: '241090-0-1',
							productName: '7-Select Energy Herbal Tonic 23.9oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241090_7SEnergyHrblTonic_24oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548590277',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 422,
					shelfIndex: '1',
					zoneId: '3',
					createdAt: '2019-11-07T04:26:44.000Z',
					updatedAt: '2019-11-07T04:26:44.000Z',
					rackId: 79,
					storeProduct: {
						id: 246,
						totalStoreQuantity: 9,
						productId: 245,
						storeId: 1,
						product: {
							id: 245,
							productId: '241521-0-1',
							productName: 'Snapple Diet Peach Tea 16oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241521_Snapple_Diet_Peach_Tea_16oz_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00076183003145',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 423,
					shelfIndex: '1',
					zoneId: '4',
					createdAt: '2019-11-07T04:27:36.000Z',
					updatedAt: '2019-11-07T04:27:36.000Z',
					rackId: 79,
					storeProduct: {
						id: 247,
						totalStoreQuantity: 0,
						productId: 246,
						storeId: 1,
						product: {
							id: 246,
							productId: '241003-0-1',
							productName: '7-Select Sweet Tea w/ Lemon 23.9oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241003_7Select_tea_with_lemmon_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548590253',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 319,
					shelfIndex: '1',
					zoneId: '5',
					createdAt: '2019-11-06T01:42:29.000Z',
					updatedAt: '2019-11-06T01:42:29.000Z',
					rackId: 79,
					storeProduct: {
						id: 155,
						totalStoreQuantity: 0,
						productId: 154,
						storeId: 1,
						product: {
							id: 154,
							productId: '178378-0-1',
							productName: 'Grapes&Chs w/insrt',
							productImageUrl:
								'https://lunchbox-live-inventory.s3.amazonaws.com/notfound.png',
							productWeight: '240',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 424,
					shelfIndex: '2',
					zoneId: '1',
					createdAt: '2019-11-07T04:31:55.000Z',
					updatedAt: '2019-11-07T04:31:55.000Z',
					rackId: 79,
					storeProduct: {
						id: 248,
						totalStoreQuantity: 24,
						productId: 247,
						storeId: 1,
						product: {
							id: 247,
							productId: '241089-0-1',
							productName: '7-Select Sweet Tea 23.9oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241089__7Select_Sweet_Tea_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548590390',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 425,
					shelfIndex: '2',
					zoneId: '2',
					createdAt: '2019-11-07T04:32:41.000Z',
					updatedAt: '2019-11-07T04:32:41.000Z',
					rackId: 79,
					storeProduct: {
						id: 249,
						totalStoreQuantity: 0,
						productId: 248,
						storeId: 1,
						product: {
							id: 248,
							productId: '240241-0-1',
							productName: 'Starbucks Doubleshot 6.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240241_Starbucks_Doubleshot_6.5z_Can_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000001772',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 426,
					shelfIndex: '2',
					zoneId: '3',
					createdAt: '2019-11-07T04:33:36.000Z',
					updatedAt: '2019-11-07T04:33:36.000Z',
					rackId: 79,
					storeProduct: {
						id: 250,
						totalStoreQuantity: 0,
						productId: 249,
						storeId: 1,
						product: {
							id: 249,
							productId: '241469-0-1',
							productName:
								'Starbucks Doubleshot Energy Vanilla 15oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241469_Starbucks_DblShtEngyVan_15zCn_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000028496',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 265,
					shelfIndex: '2',
					zoneId: '4',
					createdAt: '2019-11-06T00:16:58.000Z',
					updatedAt: '2019-11-06T00:16:58.000Z',
					rackId: 79,
					storeProduct: {
						id: 103,
						totalStoreQuantity: 9,
						productId: 102,
						storeId: 1,
						product: {
							id: 102,
							productId: '142359-0-1',
							productName: 'Hi-Chew Superfruit Mix 3.17oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/142359_HiChewSuperFruitMix_scroll1.jpg',
							productWeight: '90',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00851681008102',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 264,
					shelfIndex: '2',
					zoneId: '5',
					createdAt: '2019-11-06T00:16:18.000Z',
					updatedAt: '2019-11-06T00:16:18.000Z',
					rackId: 79,
					storeProduct: {
						id: 102,
						totalStoreQuantity: 28,
						productId: 101,
						storeId: 1,
						product: {
							id: 101,
							productId: '141873-0-1',
							productName: 'Sour Patch Watermelon 5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/141873_sourpatch_watermelon_scroll1.png',
							productWeight: '148',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00070462098631',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 437,
					shelfIndex: '3',
					zoneId: '1',
					createdAt: '2019-11-07T05:48:00.000Z',
					updatedAt: '2019-11-07T05:48:00.000Z',
					rackId: 79,
					storeProduct: {
						id: 260,
						totalStoreQuantity: 16,
						productId: 259,
						storeId: 1,
						product: {
							id: 259,
							productId: '240723-0-1',
							productName: 'Rockstar Energy Original 16oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/240723_Rockstar_Energy_16zCn_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00818094000246',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 427,
					shelfIndex: '3',
					zoneId: '2',
					createdAt: '2019-11-07T04:37:07.000Z',
					updatedAt: '2019-11-07T04:37:07.000Z',
					rackId: 79,
					storeProduct: {
						id: 251,
						totalStoreQuantity: 0,
						productId: 250,
						storeId: 1,
						product: {
							id: 250,
							productId: '241494-0-1',
							productName:
								'Starbucks Doubleshot Energy Mocha 15oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241494_Starbucks_DblShtEngyMocha_15zCn_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000028458',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 428,
					shelfIndex: '3',
					zoneId: '3',
					createdAt: '2019-11-07T04:38:09.000Z',
					updatedAt: '2019-11-07T04:38:09.000Z',
					rackId: 79,
					storeProduct: {
						id: 252,
						totalStoreQuantity: 2,
						productId: 251,
						storeId: 1,
						product: {
							id: 251,
							productId: '241181-0-1',
							productName:
								'7-Select Iced Cappuccino Mocha  13.7oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241181_7s_ice_cap_mocah_13.7z_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548590338',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 429,
					shelfIndex: '3',
					zoneId: '4',
					createdAt: '2019-11-07T04:38:53.000Z',
					updatedAt: '2019-11-07T04:38:53.000Z',
					rackId: 79,
					storeProduct: {
						id: 253,
						totalStoreQuantity: 10,
						productId: 252,
						storeId: 1,
						product: {
							id: 252,
							productId: '241255-0-1',
							productName:
								'7-Select Iced Cappuccino Vanilla 13.7oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241255_VanillaCappuccino_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548590345',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 435,
					shelfIndex: '3',
					zoneIndex: '5',
					createdAt: '2019-11-07T05:13:49.000Z',
					updatedAt: '2019-11-07T05:13:49.000Z',
					rackId: 79,
					storeProduct: {
						id: 258,
						totalStoreQuantity: 9,
						productId: 257,
						storeId: 1,
						product: {
							id: 257,
							productId: '245053-0-1',
							productName: '7-Select Cold Brew Coffee Mocha 11oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/245053_7SColdBrewCoffeeMocha_11z_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548589028',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
		],
	},
	{
		id: 74,
		rackIndex: 'Rack 01',
		description: 'Rack number 1',
		left: 788.924,
		top: 132.666,
		width: 55.6333,
		height: 60.061,
		angle: 0,
		createdAt: '2019-10-17T19:49:46.000Z',
		updatedAt: '2019-10-17T19:49:46.000Z',
		gondolaId: 48,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 438,
					shelfIndex: '1',
					zoneIndex: '1',
					createdAt: '2019-11-07T13:36:26.000Z',
					updatedAt: '2019-11-07T13:36:26.000Z',
					rackId: 74,
					storeProduct: {
						id: 261,
						totalStoreQuantity: 200,
						productId: 260,
						storeId: 1,
						product: {
							id: 260,
							productId: '241612-0-1',
							productName: 'Monster Low Carb Energy 16oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241612_monster_lowcarb_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '289',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48395,
							upc: '00070847810261',
							createdAt: null,
							updatedAt: '2020-06-03T22:31:08.000Z',
						},
					},
				},
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 470,
					shelfIndex: '1',
					zoneIndex: '3',
					createdAt: '2019-11-08T07:34:42.000Z',
					updatedAt: '2019-11-08T07:34:42.000Z',
					rackId: 74,
					storeProduct: {
						id: 283,
						totalStoreQuantity: 12,
						productId: 268,
						storeId: 1,
						product: {
							id: 268,
							productId: '210200-0-1',
							productName: 'Cup Noodles Beef 2.25oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/210200_CpNdlsBf2.25z_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: ' ',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00070662030011',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 444,
					shelfIndex: '1',
					zoneIndex: '4',
					createdAt: '2019-11-08T07:00:27.000Z',
					updatedAt: '2019-11-08T07:00:27.000Z',
					rackId: 74,
					storeProduct: {
						id: 266,
						totalStoreQuantity: 20,
						productId: 265,
						storeId: 1,
						product: {
							id: 265,
							productId: '241945-0-1',
							productName: 'Monster 16oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241945_monster_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00070847811169',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 445,
					shelfIndex: '2',
					zoneIndex: '1',
					createdAt: '2019-11-08T07:02:01.000Z',
					updatedAt: '2020-06-03T21:26:49.000Z',
					rackId: 74,
					storeProduct: {
						id: 267,
						totalStoreQuantity: 17,
						productId: 266,
						storeId: 1,
						product: {
							id: 266,
							productId: '241278-0-1',
							productName: 'Red Bull 12oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241278_redbull_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00611269818994',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 447,
					shelfIndex: '2',
					zoneIndex: '3',
					createdAt: '2019-11-08T07:02:56.000Z',
					updatedAt: '2019-11-08T07:02:56.000Z',
					rackId: 74,
					storeProduct: {
						id: 268,
						totalStoreQuantity: 0,
						productId: 267,
						storeId: 1,
						product: {
							id: 267,
							productId: '241342-0-1',
							productName: 'Red Bull Sugar Free 12oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/241342_redbull_sugarfree_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00611269423754',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 448,
					shelfIndex: '2',
					zoneIndex: '4',
					createdAt: '2019-11-08T07:03:20.000Z',
					updatedAt: '2019-11-08T07:03:20.000Z',
					rackId: 74,
					storeProduct: {
						id: 269,
						totalStoreQuantity: 25,
						productId: 287,
						storeId: 1,
						product: {
							id: 287,
							productId: '306042-0-1',
							productName: "Doritos Flamin' Hot Nacho",
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/306042_DoritosFlaminHot_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: null,
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 449,
					shelfIndex: '3',
					zoneIndex: '1',
					createdAt: '2019-11-08T07:03:49.000Z',
					updatedAt: '2019-11-08T07:03:49.000Z',
					rackId: 74,
					storeProduct: {
						id: 270,
						totalStoreQuantity: 6,
						productId: 280,
						storeId: 1,
						product: {
							id: 280,
							productId: '290357-0-1',
							productName:
								'Sargento String Cheese Mozzarella 1oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/290357_sargento_string_cheese_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: null,
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00046100017098',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 474,
					shelfIndex: '3',
					zoneIndex: '3',
					createdAt: '2019-11-08T07:49:02.000Z',
					updatedAt: '2019-11-08T07:49:02.000Z',
					rackId: 74,
					storeProduct: {
						id: 286,
						totalStoreQuantity: 3,
						productId: 281,
						storeId: 1,
						product: {
							id: 281,
							productId: '175158-0-1',
							productName: 'Turkey Ham Combo Sandwich',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/175158_TurkeyHamComboSandwich_480_hero_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: null,
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548030995',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 450,
					shelfIndex: '3',
					zoneIndex: '4',
					createdAt: '2019-11-08T07:06:39.000Z',
					updatedAt: '2019-11-08T07:06:39.000Z',
					rackId: 74,
					storeProduct: {
						id: 271,
						totalStoreQuantity: 6,
						productId: 270,
						storeId: 1,
						product: {
							id: 270,
							productId: '178144-0-1',
							productName: '7-Select Iced Honey Bun 5oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/178144_Iced Honey Bun_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: ' ',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548500542',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 483,
					shelfIndex: '4',
					zoneIndex: '1',
					createdAt: '2020-02-25T06:36:08.000Z',
					updatedAt: '2020-02-25T06:36:08.000Z',
					rackId: 74,
					storeProduct: {
						id: 291,
						totalStoreQuantity: 6,
						productId: 296,
						storeId: 1,
						product: {
							id: 296,
							productId: '246388-0-1',
							productName: 'Pure Leaf Sweetened Tea 23.9oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/246388_pure_leaf_sweet_scroll1.png',
							productWeight: '626',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00012000046353',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:53.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 484,
					shelfIndex: '4',
					zoneIndex: '2',
					createdAt: '2020-02-25T06:37:14.000Z',
					updatedAt: '2020-02-25T06:37:14.000Z',
					rackId: 74,
					storeProduct: {
						id: 292,
						totalStoreQuantity: 6,
						productId: 297,
						storeId: 1,
						product: {
							id: 297,
							productId: '140160-0-1',
							productName:
								'Starburst Original Fruit Chews 2.07oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/140160_StarburstOriginal_scroll1.png',
							productWeight: '62',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00040000000518',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 485,
					shelfIndex: '4',
					zoneIndex: '3',
					createdAt: '2020-02-25T06:37:52.000Z',
					updatedAt: '2020-02-25T06:37:52.000Z',
					rackId: 74,
					storeProduct: {
						id: 293,
						totalStoreQuantity: 4,
						productId: 298,
						storeId: 1,
						product: {
							id: 298,
							productId: '176401-0-1',
							productName: 'Tropical Cup 5.5z',
							productImageUrl:
								'https://birds-eye-assets.s3.amazonaws.com/CW_STORE_PRODUCTS/tropical_cup.png',
							productWeight: '192',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 486,
					shelfIndex: '4',
					zoneIndex: '4',
					createdAt: '2020-02-25T06:38:22.000Z',
					updatedAt: '2020-02-25T06:38:22.000Z',
					rackId: 74,
					storeProduct: {
						id: 294,
						totalStoreQuantity: 4,
						productId: 299,
						storeId: 1,
						product: {
							id: 299,
							productId: '140099-0-1',
							productName: 'Twizzlers Strawberry 7oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/140099_TwzlrStrawberryPB7oz_scroll1.jpg',
							productWeight: '203',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00034000544028',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
			],
		],
	},
	{
		id: 75,
		rackIndex: 'Rack 02',
		description: 'Rack number 1',
		left: 788.331,
		top: 194.536,
		width: 60.9807,
		height: 60.0584,
		angle: 0,
		createdAt: '2019-10-17T19:50:13.000Z',
		updatedAt: '2019-10-17T19:50:13.000Z',
		gondolaId: 48,
		shelves: [
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 455,
					shelfIndex: '1',
					zoneIndex: '1',
					createdAt: '2019-11-08T07:13:32.000Z',
					updatedAt: '2019-11-08T07:13:32.000Z',
					rackId: 75,
					storeProduct: {
						id: 272,
						totalStoreQuantity: 6,
						productId: 269,
						storeId: 1,
						product: {
							id: 269,
							productId: '177981-0-1',
							productName: '7-Select Glazed Honey Bun 5oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/177981_Glazed Honey Bun_scroll1.jpg',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: ' ',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00052548635053',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:53.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 456,
					shelfIndex: '1',
					zoneIndex: '2',
					createdAt: '2019-11-08T07:14:17.000Z',
					updatedAt: '2019-11-08T07:14:17.000Z',
					rackId: 75,
					storeProduct: {
						id: 273,
						totalStoreQuantity: 4,
						productId: 285,
						storeId: 1,
						product: {
							id: 285,
							productId: '303170-0-1',
							productName: "Cheetos Crunchy Flamin' Hot",
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/303170_cheetos_hot_3.75oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: null,
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400028172',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 471,
					shelfIndex: '1',
					zoneIndex: '3',
					createdAt: '2019-11-08T07:37:02.000Z',
					updatedAt: '2019-11-08T07:37:02.000Z',
					rackId: 75,
					storeProduct: {
						id: 284,
						totalStoreQuantity: 4,
						productId: 291,
						storeId: 1,
						product: {
							id: 291,
							productId: '305118-0-1',
							productName: 'Skinny Pop 1oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/305118_sckinny_pop_1oz_scroll1_copy.png',
							productWeight: '35',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00850251004087',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 472,
					shelfIndex: '1',
					zoneIndex: '4',
					createdAt: '2019-11-08T07:38:39.000Z',
					updatedAt: '2019-11-08T07:38:39.000Z',
					rackId: 75,
					storeProduct: {
						id: 285,
						totalStoreQuantity: 5,
						productId: 288,
						storeId: 1,
						product: {
							id: 288,
							productId: '175957-0-1',
							productName: 'Bon Appetit Bear Claw 5oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/175957_bonAp_bear_claw_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: null,
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00035751111071',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 457,
					shelfIndex: '2',
					zoneIndex: '1',
					createdAt: '2019-11-08T07:17:56.000Z',
					updatedAt: '2019-11-08T07:17:56.000Z',
					rackId: 75,
					storeProduct: {
						id: 274,
						totalStoreQuantity: 4,
						productId: 284,
						storeId: 1,
						product: {
							id: 284,
							productId: '303178-0-1',
							productName: "Chesters Flamin' Hot Fries",
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/303178_Chesters_Flamin_Hot_Fries_4oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: null,
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400028257',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				null,
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 459,
					shelfIndex: '2',
					zoneIndex: '3',
					createdAt: '2019-11-08T07:20:53.000Z',
					updatedAt: '2019-11-08T07:20:53.000Z',
					rackId: 75,
					storeProduct: {
						id: 275,
						totalStoreQuantity: 4,
						productId: 282,
						storeId: 1,
						product: {
							id: 282,
							productId: '303066-0-1',
							productName: '7-Select Ripple Buffalo Chicken Dip',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/303066_7s_ripple_buffchkdip_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: null,
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 460,
					shelfIndex: '2',
					zoneIndex: '4',
					createdAt: '2019-11-08T07:23:22.000Z',
					updatedAt: '2019-11-08T07:23:22.000Z',
					rackId: 75,
					storeProduct: {
						id: 276,
						totalStoreQuantity: 7,
						productId: 283,
						storeId: 1,
						product: {
							id: 283,
							productId: '302464-0-1',
							productName: '7-Select BBQ Potato Chips',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/302464_7S_bbqpotatochips_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: null,
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: null,
							createdAt: null,
							updatedAt: null,
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 493,
					shelfIndex: '3',
					zoneIndex: '1',
					createdAt: '2020-02-26T01:15:03.000Z',
					updatedAt: '2020-02-26T01:15:03.000Z',
					rackId: 75,
					storeProduct: {
						id: 300,
						totalStoreQuantity: 0,
						productId: 305,
						storeId: 1,
						product: {
							id: 305,
							productId: '234234332423',
							productName: 'Something',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/303379_cheetos_crunchy_flamin_hot_scroll1.png',
							productWeight: '1.3',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '23.33',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00888888888',
							createdAt: '2020-06-03T00:22:17.000Z',
							updatedAt: '2020-06-03T00:22:17.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 505,
					shelfIndex: '3',
					zoneIndex: '2',
					createdAt: '2020-02-26T02:17:31.000Z',
					updatedAt: '2020-02-26T02:17:31.000Z',
					rackId: 75,
					storeProduct: {
						id: 306,
						totalStoreQuantity: 0,
						productId: 311,
						storeId: 1,
						product: {
							id: 311,
							productId: '307810-0-1',
							productName: 'Lays Original 2.65 oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/307810_lays_regular_2.625oz_SCROLL1.png',
							productWeight: '82',
							calibrationFactor: '',
							weightScaleInfo: '',
							price: '189',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00028400323826',
							createdAt: '2020-06-03T19:31:14.000Z',
							updatedAt: '2020-06-03T19:31:37.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 464,
					shelfIndex: '3',
					zoneIndex: '3',
					createdAt: '2019-11-08T07:28:35.000Z',
					updatedAt: '2019-11-08T07:28:35.000Z',
					rackId: 75,
					storeProduct: {
						id: 277,
						totalStoreQuantity: 4,
						productId: 279,
						storeId: 1,
						product: {
							id: 279,
							productId: '290359-0-1',
							productName: 'Sargento Stick Colby Jack 1oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/290359_srgnt_stick_colbyjck_1oz_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: null,
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00046100008287',
							createdAt: null,
							updatedAt: '2020-06-22T22:19:52.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 465,
					shelfIndex: '3',
					zoneIndex: '4',
					createdAt: '2019-11-08T07:29:12.000Z',
					updatedAt: '2019-11-08T07:29:12.000Z',
					rackId: 75,
					storeProduct: {
						id: 278,
						totalStoreQuantity: 4,
						productId: 278,
						storeId: 1,
						product: {
							id: 278,
							productId: '172009-0-1',
							productName:
								'Oscar Mayer P3 Turkey, Colby Jack, Almonds',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/172009_oscarmeyer_p3_turk_colbjack_almon_2oz_scroll1_copy.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: null,
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00044700070758',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
			],
			[
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 466,
					shelfIndex: '4',
					zoneIndex: '1',
					createdAt: '2019-11-08T07:31:06.000Z',
					updatedAt: '2019-11-08T07:31:06.000Z',
					rackId: 75,
					storeProduct: {
						id: 279,
						totalStoreQuantity: 4,
						productId: 277,
						storeId: 1,
						product: {
							id: 277,
							productId: '290029-0-1',
							productName:
								'Volpi Roltini Mozzarella & Pepperoni 1.5oz',
							productImageUrl:
								'https://d21c9usmdrkboa.cloudfront.net/images/products/usa/qa/290029_volpi_mozz_pepp_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: null,
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00765171855445',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:15.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 467,
					shelfIndex: '4',
					zoneIndex: '2',
					createdAt: '2019-11-08T07:32:21.000Z',
					updatedAt: '2019-11-08T07:32:21.000Z',
					rackId: 75,
					storeProduct: {
						id: 280,
						totalStoreQuantity: 6,
						productId: 289,
						storeId: 1,
						product: {
							id: 289,
							productId: '150287-0-1',
							productName:
								'Yoplait Original Harvest Peach Yogurt 6oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/150287_YoplaitOrgnlHarvestPeachLFYogurt_6oz_scroll1.png',
							productWeight: '178.3',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00070470003078',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 468,
					shelfIndex: '4',
					zoneIndex: '3',
					createdAt: '2019-11-08T07:33:07.000Z',
					updatedAt: '2019-11-08T07:33:07.000Z',
					rackId: 75,
					storeProduct: {
						id: 281,
						totalStoreQuantity: 23,
						productId: 290,
						storeId: 1,
						product: {
							id: 290,
							productId: '304325-0-1',
							productName: 'Skinny Pop White Cheddar Popcorn 1oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/304325_Skinny_pop_White_Cheddar_Popcorn_1z_scroll1.png',
							productWeight: '2',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '0',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 0,
							upc: '00850251004438',
							createdAt: null,
							updatedAt: '2020-05-28T23:51:16.000Z',
						},
					},
				},
				{
					zoneQty: 0,
					cartQty: 0,
					isHitl: false,
					isAlgo: false,
					id: 469,
					shelfIndex: '4',
					zoneIndex: '4',
					createdAt: '2019-11-08T07:33:31.000Z',
					updatedAt: '2019-11-08T07:33:31.000Z',
					rackId: 75,
					storeProduct: {
						id: 282,
						totalStoreQuantity: 200,
						productId: 276,
						storeId: 1,
						product: {
							id: 276,
							productId: '174561-0-1',
							productName: '7-Select Pickle Bites Hot 3.5oz',
							productImageUrl:
								'https://d2ho11qqpg0ll0.cloudfront.net/images/US/174561_HotSpicyPickleBites_scroll1.png',
							productWeight: '1',
							calibrationFactor: null,
							weightScaleInfo: null,
							price: '139',
							calories: null,
							isActive: true,
							isFreshFood: true,
							categoryId: 48423,
							upc: '00052548613686',
							createdAt: null,
							updatedAt: '2020-06-03T21:29:22.000Z',
						},
					},
				},
			],
		],
	},
];
