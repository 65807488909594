import { EnvService } from '../Services/env-service.service';

export const EnvServiceFactory = () => {
	// Create env
	const env = new EnvService();
	const envStr = '__env';

	// Read environment variables from browser window
	const browserWindow = window || {};
	const browserWindowEnv = browserWindow[envStr] || {};

	// Assign environment variables from browser window to env
	// In the current implementation, properties from env.js overwrite defaults from the EnvService.
	// If needed, a deep merge can be performed here to merge properties instead of overwriting them.
	for (const key in browserWindowEnv) {
		if (browserWindowEnv.hasOwnProperty(key)) {
			env[key] = window[envStr][key];
		}
	}

	return env;
};

export const EnvServiceProvider = {
	provide: EnvService,
	useFactory: EnvServiceFactory,
	deps: [],
};
