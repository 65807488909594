import { Component, OnInit } from '@angular/core';
import { TicketQuery } from 'src/app/ticket/state/ticket.query';
import { Observable } from 'rxjs';
import { ShoppingSessionService } from '../../shopping-session/shopping-session-state/shopping-session.service';
import { RefundStatus } from './refund-status.enum';
import { TicketService } from 'src/app/ticket/state/ticket.service';

@Component({
	selector: 'app-picked-products',
	templateUrl: './picked-products.component.html',
	styleUrls: ['./picked-products.component.css'],
})
export class PickedProductsComponent {
	refundData$: Observable<any> = this.ticketQuery.refundData$;
	RefundStatus = RefundStatus;

	constructor(
		private ticketQuery: TicketQuery,
		private shoppingSessionService: ShoppingSessionService,
		private ticketService: TicketService
	) {}

	// ngOnInit(): void {
	// this.pickedProductService.getStoreProducts();
	// }

	processCase(processName: string) {
		this.ticketService.setSubmittedCart(true);
		this.shoppingSessionService.processRefund(processName);
	}
}
