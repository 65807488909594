import { Component, OnInit } from '@angular/core';
import { IPlayable } from '@videogular/ngx-videogular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CameraService } from '../../camera/camera.service';
import { Camera } from '../../camera/Models/camera.model';
import { Rack } from '../../shopping-session/shopping-session-state/Models/rack.model';
import { ShoppingSessionQuery } from '../../shopping-session/shopping-session-state/shopping-session.query';
import { ShoppingSessionService } from '../../shopping-session/shopping-session-state/shopping-session.service';

@Component({
	selector: 'app-rack-camera',
	templateUrl: './rack-camera.component.html',
	styleUrls: ['./rack-camera.component.css'],
})
export class RackCameraComponent implements OnInit {
	selectedRack$: Observable<Rack>;
	selectedCamera: number;
	currentCameras: BehaviorSubject<Array<Camera>> = new BehaviorSubject([]);
	destroyed$: Subject<boolean> = new Subject();

	constructor(
		private shoppingSessionQuery: ShoppingSessionQuery,
		private shoppingSessionService: ShoppingSessionService,
		private cameraService: CameraService
	) {}

	ngOnInit(): void {
		this.selectedRack$ = this.shoppingSessionQuery.rack$;
		this.shoppingSessionQuery.selectRackCamerasByUnitId$
			.pipe(
				tap((res) => {
					this.currentCameras.next(res);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	updateCameraId(
		camera: Camera,
		videoElement: HTMLVideoElement,
		playable: IPlayable
	) {
		this.selectedCamera = camera.id;
		this.shoppingSessionService.setCameraId(camera.id);
		this.cameraService.setMainVideoElement(videoElement, playable, camera);
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
