import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { TicketService } from '../ticket/state/ticket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';
import { REFUND, SHOPPING_SESSION, APP_NAME } from '../utils/constants';
import { ShoppingSessionService } from '../ShoppingSession/shopping-session/shopping-session-state/shopping-session.service';
import { HOME } from '../utils/routes.constants';
import { resetStores } from '@datorama/akita';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-case-complete',
	templateUrl: './case-complete.component.html',
	styleUrls: ['./case-complete.component.css'],
})
export class CaseCompleteComponent implements OnInit, OnDestroy {
	form: FormGroup;
	errMsg: string;
	caseStatuses$;

	destroying$: Subject<void> = new Subject<void>();

	constructor(
		private ticketService: TicketService,
		private router: Router,
		private errDialog: ErrorDialogService,
		private shoppingSessionService: ShoppingSessionService,
		private dataRoute: ActivatedRoute
	) {}
	ngOnDestroy(): void {
		this.destroying$.next();
	}

	ngOnInit() {
		const accessToken = this.ticketService.getAccessToken();
		if (!accessToken) {
			this.router.navigate([HOME]);
		}
		this.caseStatuses$ = this.ticketService.getCaseStatus();
		this.form = new FormGroup({
			status: new FormControl('', [Validators.required]),
			notes: new FormControl(''),
		});
	}

	submitForm() {
		const formVal = this.form.value;
		const ticketType = this.ticketService.getTicketType();
		let isManager = false;

		switch (ticketType) {
			case REFUND:
				const refundStatus =
					this.dataRoute.snapshot.queryParamMap.get(
						'refundStatusName'
					);
				this.processRefund(formVal, refundStatus);
				this.ticketService.clearCase();
				this.shoppingSessionService.clearSession();
				break;
			case SHOPPING_SESSION:
				this.shoppingSessionService.submitCart(formVal).subscribe();
				this.ticketService.clearCase();
				this.shoppingSessionService.clearSession();

				this.router.navigate([HOME]);
				break;
			default:
				this.ticketService.clearCase();
				this.router.navigate([HOME]);
				break;
		}
	}

	processRefund(formVal: any, refundStatus: string) {
		const refundData = {
			refundStatus,
			notes: this.form.get('notes').value,
			ackId: this.ticketService.getAckId(),
		};

		const userInfo: any = JSON.parse(sessionStorage.getItem('user'));
		let isManager = false;

		this.shoppingSessionService
			.submitRefund(this.ticketService.getAccessToken(), refundData)
			.pipe(takeUntil(this.destroying$))
			.subscribe((data) => {
				this.ticketService.clearCase();
				this.router.navigate([HOME]);
			});

		// const userInfo: any = JSON.parse(sessionStorage.getItem('user'));
		// let isManager = false;
		// if (userInfo !== null) {
		// 	if (userInfo?.roles[APP_NAME][ROLE_MANAGER]) {
		// 		isManager = true;
		// 	}
		// }
		// this.shoppingSessionService
		// 	.updateHitlShoppingSession(formVal)
		// 	.subscribe(data => {
		// 		this.ticketService.acknowledgeTicket().subscribe(data => {
		// 			this.ticketService.clearCase();
		// 			if (isManager) {
		// 				this.router.navigate([MANAGER]);
		// 			} else {
		// 				this.router.navigate([HOME]);
		// 			}
		// 		});
		// 	});
	}
}
