import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';
import { ErrorDialogData } from '../error-dialog/error-dialog.component';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(private errDialog: ErrorDialogService) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				let errorDialogData: ErrorDialogData =
					error.error instanceof ErrorEvent
						? {
								title: 'The app encountered an Error',
								message: error.error.message,
								rawPayload: error,
						  }
						: {
								title: `Server Responded with Error Code: ${error.status}`,
								message: error.message,
								rawPayload: error,
						  };

				this.errDialog.showError(errorDialogData);
				return throwError(error);
			})
		);
	}
}
