import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	AfterContentChecked,
	ChangeDetectorRef,
	HostListener,
	AfterViewInit,
	OnDestroy,
	Output,
	EventEmitter,
} from '@angular/core';
import { Camera } from '../../Models/camera.model';
import { CameraService } from '../../camera.service';
import { IPlayable, VgPlayerComponent } from '@videogular/ngx-videogular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-camera',
	templateUrl: './camera.component.html',
	styleUrls: ['./camera.component.css'],
})
export class CameraComponent
	implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
	@ViewChild('vgPlayer') vgPlayer: VgPlayerComponent;
	@ViewChild('video') videoElem: ElementRef;
	@ViewChild('controls') controls: ElementRef;

	@Input()
	cam: Camera;
	@Input()
	height: number;

	@Output('onSelected') selected = new EventEmitter<
		[HTMLVideoElement, IPlayable]
	>();

	private api: any;
	track: TextTrack;
	private adjustedTime = false;

	destroying$: Subject<void> = new Subject();

	constructor(
		private cameraService: CameraService,
		private cdRef: ChangeDetectorRef
	) {}

	onSelected() {
		this.selected.emit([
			this.videoElem.nativeElement as HTMLVideoElement,
			this.vgPlayer.api.getDefaultMedia(),
		]);
	}

	ngAfterViewInit(): void {
		this.cameraService.setCameraAPI(this.vgPlayer.api);
		this.initCameras();
	}

	ngOnInit() {
		this.cameraService.addOrReplaceCam(this.cam);
	}

	ngAfterContentChecked(): void {
		this.cdRef.detectChanges();
	}

	ngOnDestroy(): void {
		this.destroying$.next();
	}

	initCameras() {
		this.vgPlayer.fsAPI?.onChangeFullscreen
			.pipe(takeUntil(this.destroying$))
			.subscribe(() => {});

		this.vgPlayer.api
			?.getDefaultMedia()
			.subscriptions.loadedMetadata.pipe(takeUntil(this.destroying$))
			.subscribe(() => {
				// todo: remove this call, and keep the media reactive in a better way. For now, this is somehow keeping the player reactive on metadata load, but it also gives you a flashing light show, and changes the player everytime you load more time
				this.onSelected()
				this.api.currentTime = sessionStorage.getItem(
					this.cameraService.CAMERA_SYNC_KEY
				);
				this.cameraService.syncAllVideos(this.api.currentTime);
				const actualTime = Math.trunc(this.api.duration * 1000);
				if (
					actualTime - this.cameraService.duration > 1000 ||
					this.cameraService.duration - actualTime > 1000
				) {
					if (
						actualTime >= this.cameraService.duration &&
						this.adjustedTime === false
					) {
						const difference =
							actualTime - this.cameraService.duration;
						const tempTime =
							this.cameraService.duration - difference;
						this.cam.liveStreamUrl =
							this.cam.liveStreamUrl.substring(
								0,
								this.cam.liveStreamUrl.lastIndexOf('=') + 1
							) + Math.trunc(tempTime);
						this.adjustedTime = true;
						this.cameraService.addOrReplaceCam(this.cam);
					} else if (
						actualTime <= this.cameraService.duration &&
						this.adjustedTime === false
					) {
						const difference =
							this.cameraService.duration - actualTime;
						const tempTime =
							this.cameraService.duration + difference;
						this.cam.liveStreamUrl =
							this.cam.liveStreamUrl.substring(
								0,
								this.cam.liveStreamUrl.lastIndexOf('=') + 1
							) + Math.trunc(tempTime);
						this.adjustedTime = true;
						this.cameraService.addOrReplaceCam(this.cam);
					}
				}
			});

		this.vgPlayer.api
			?.getDefaultMedia()
			.subscriptions.seeking.pipe(takeUntil(this.destroying$))
			.subscribe((res) => {
				const storageTime = Number(
					sessionStorage.getItem(this.cameraService.CAMERA_SYNC_KEY)
				);
				if (storageTime > 1) {
					sessionStorage.setItem(
						this.cameraService.CAMERA_SYNC_KEY,
						res.srcElement.currentTime
					);
				}
			});
		this.vgPlayer.api
			?.getDefaultMedia()
			.subscriptions.pause.pipe(takeUntil(this.destroying$))
			.subscribe((res) => {
				this.cameraService.pauseAllVideos();
			});
		this.vgPlayer.api
			?.getDefaultMedia()
			.subscriptions.play.pipe(takeUntil(this.destroying$))
			.subscribe((res) => {
				const storageTime = sessionStorage.getItem(
					this.cameraService.CAMERA_SYNC_KEY
				);
				sessionStorage.setItem(
					this.cameraService.CAMERA_SYNC_KEY,
					storageTime
				);
				this.cameraService.playAllVideos();
			});

		this.vgPlayer.api
			?.getDefaultMedia()
			.subscriptions.timeUpdate.pipe(takeUntil(this.destroying$))
			.subscribe((res) => {
				if (res.srcElement.currentTime > 0) {
					sessionStorage.setItem(
						this.cameraService.CAMERA_SYNC_KEY,
						this.api.currentTime
					);
				}
			});
	}

	onPlayerReady(api) {
		this.api = api;
	}
}
