import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { TicketStore } from './ticket.store';
import { CaseStatus } from 'src/app/case-complete/models/case-status.model';
import { ErrorDialogService } from 'src/app/error-dialog/error-dialog.service';
import { EnvService } from 'src/app/Services/env-service.service';
import { AuthService } from 'src/app/auth/state/auth.service';
import LogRocket from 'logrocket';

@Injectable({ providedIn: 'root' })
export class TicketService {
	constructor(
		private ticketStore: TicketStore,
		private http: HttpClient,
		private errorDialog: ErrorDialogService,
		private env: EnvService,
		private authService: AuthService
	) {}

	setTicketStatus(status: string) {
		this.ticketStore.setStatus(status);
	}

	getStatus(): string {
		return this.ticketStore.getValue().status;
	}

	/**
	 * NOTE. Ticket accessToken is same as id of hitlSession
	 */
	getAccessToken() {
		return this.ticketStore.getValue()?.accessToken;
	}

	setStoreId(storeId: number) {
		sessionStorage.setItem('storeId', storeId.toString());
		this.ticketStore.setStoreId(storeId);
	}

	getStoreId() {
		return this.ticketStore.getValue().storeId;
	}

	getCaseRejects() {
		return this.ticketStore.getValue().caseRejects;
	}

	getAckId() {
		return this.ticketStore.getValue().ackId;
	}

	getRefundInfo() {
		return this.ticketStore.getValue().payload.refundData;
	}

	getTicketType() {
		return this.ticketStore.getValue().ticketType;
	}

	getAccepted() {
		return this.ticketStore.getValue().accepted;
	}

	getSubmittedCart() {
		return this.ticketStore.getValue().submittedCart;
	}

	addCaseReject() {
		let caseRejects = this.getCaseRejects();
		caseRejects += 1;
		this.ticketStore.setCaseRejects(caseRejects);
	}
	setAccessToken(accessToken: string) {
		this.ticketStore.setAccessToken(accessToken);
	}

	clearAccessToken() {
		this.ticketStore.clearAccessToken();
	}

	setAckId(ackId: string) {
		this.ticketStore.setAckId(ackId);
	}

	clearAckId() {
		this.ticketStore.clearAckId();
	}

	clearStatus() {
		this.ticketStore.clearStatus();
	}

	clearCaseRejection() {
		this.ticketStore.clearCaseRejects();
	}

	setPayload(payload: any) {
		this.ticketStore.setPayload(payload);
	}

	getSingleTicket() {
		const ticketType = this.ticketStore.getValue().ticketType;
		let url = `${this.env.hitlServiceUrl}/ticket/${ticketType}`;

		if (ticketType == 'customer-session') {
			url += '?visibility=30';
		}

		return this.http.get(url);
	}

	getCaseStatus() {
		return this.http
			.get<CaseStatus[]>(
				`${this.env.hitlServiceUrl}/hitl-session/case-status`
			)
			.pipe(
				catchError((error) => {
					this.errorDialog.showError(error.message);
					return of();
				})
			);
	}

	acceptTicket() {
		const ackId = this.getAckId();
		const ticketType = this.ticketStore.getValue().ticketType;
		LogRocket.startNewSession();
		const userInfo = this.authService.getUser();
		LogRocket.identify(userInfo.email, {
			name: `${userInfo.lastName}, ${userInfo.firstName}`,
			email: userInfo.email,
			permissions: JSON.stringify(userInfo.roles),
			ticketInfo: JSON.stringify(this.ticketStore.getValue()),
		});
		LogRocket.track('Accepted Ticket');
		const body = {
			ackId,
		};
		return this.http.post(
			`${this.env.hitlServiceUrl}/ticket/${ticketType}/accept-ticket`,
			body,
			{ responseType: 'text' }
		);
	}

	acknowledgeTicket() {
		LogRocket.track('Acknowledge Ticket');
		const ackId = this.getAckId();
		const ticketType = this.ticketStore.getValue().ticketType;
		const body = {
			ackId,
		};
		return this.http.post(
			`${this.env.hitlServiceUrl}/ticket/${ticketType}/ack`,
			body,
			{ responseType: 'text' }
		);
	}

	getHitlCart() {
		return this.ticketStore.getValue().isHitlCart;
	}

	setHitlCart(isHitlCart: boolean) {
		this.ticketStore.setIsHitlCart(isHitlCart);
	}

	clearCase() {
		this.ticketStore.clearAckId();
		this.ticketStore.clearAccessToken();
		this.ticketStore.clearCaseRejects();
		this.ticketStore.clearIsHitlCart();
		this.ticketStore.clearStatus();
		this.ticketStore.clearStoreId();
		this.ticketStore.clearPayload();
		this.ticketStore.setAccepted(false);
		this.ticketStore.setSubmittedCart(false);
	}

	clearPreviousCases() {
		this.ticketStore.clearAckId();
		this.ticketStore.clearAccessToken();
		this.ticketStore.clearIsHitlCart();
		this.ticketStore.clearStatus();
		this.ticketStore.clearStoreId();
		this.ticketStore.clearPayload();
		this.ticketStore.setAccepted(false);
		this.ticketStore.setSubmittedCart(false);
	}

	setTicketType(ticketType: string) {
		this.ticketStore.setTicketType(ticketType);
	}

	clearTicketType() {
		this.ticketStore.clearTicketType();
	}

	acceptedCase(accepted: boolean) {
		this.ticketStore.setAccepted(accepted);
	}

	setSubmittedCart(submittedCart: boolean) {
		this.ticketStore.setSubmittedCart(submittedCart);
	}
}
