import { Injectable } from '@angular/core';
import { ShoppingSessionStore } from './shopping-session.store';
import { Rack } from './Models/rack.model';
import { ProductZone } from './Models/product-zone.model';
import { Camera } from '../../camera/Models/camera.model';
import { AlgoCart } from '../../product-view/Models/algo-cart';
import { AuthService } from 'src/app/auth/state/auth.service';
import { EnvService } from 'src/app/Services/env-service.service';
import { TicketService } from 'src/app/ticket/state/ticket.service';
import { ErrorDialogService } from 'src/app/error-dialog/error-dialog.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { CASE_COMPLETE } from 'src/app/utils/routes.constants';
import LogRocket from 'logrocket';
import { ShoppingSessionQuery } from './shopping-session.query';
import { take } from 'rxjs/operators';
import {
	buildWowzaUrl,
	durationFromWowzaUrl,
} from 'src/app/utils/wowzaUrlHelpers';

@Injectable({ providedIn: 'root' })
export class ShoppingSessionService {
	private baseUrl = `${this.env.hitlServiceUrl}`;
	constructor(
		private shoppingSessionStore: ShoppingSessionStore,
		private shoppingSessionQuery: ShoppingSessionQuery,
		private authService: AuthService,
		private env: EnvService,
		private ticketService: TicketService,
		private errDialog: ErrorDialogService,
		private http: HttpClient,
		private router: Router
	) {}

	setAlgoProducts(algoCart: AlgoCart[]) {
		const racks = this.shoppingSessionStore.getValue().racks;
		this.shoppingSessionStore.setAlgoCart(algoCart);

		racks.forEach((rack: any) => {
			if (rack.layers) {
				rack.layers.forEach((shelf) => {
					if (shelf.productZones) {
						shelf.productZones.forEach((pz) => {
							algoCart.forEach((algoProduct) => {
								if (pz != null) {
									if (
										algoProduct.productId ===
										pz.productId + ''
									) {
										pz.isAlgo = true;
										pz.algoQty = algoProduct.quantity;
										pz.unitId = rack.id;
										pz.shelfIndex = shelf.shelfIndex;
										if (!pz.cartQty) {
											pz.cartQty = 0;
										}
										this.updateShoppingCart(
											pz,
											algoProduct.quantity
										);
									}
								}
							});
						});
					}
				});
			}
		});
		this.shoppingSessionStore.setRacks(racks);
	}

	setUnitId(unitId: number) {
		this.shoppingSessionStore.setUnitId(unitId);
	}

	setCameraId(cameraId: number) {
		this.shoppingSessionStore.setCameraId(cameraId);
	}

	clearShoppingSession() {
		let racks = this.shoppingSessionStore.getValue().racks;
		racks.forEach((rack) =>
			rack.layers.forEach((shelf: any) =>
				shelf.productZones.forEach((pz) => {
					if (pz !== null) {
						pz.isHitl = false;
						pz.isAlgo = false;
						pz.cartQty = 0;
					}
				})
			)
		);
		this.shoppingSessionStore.setRacks(racks);
		this.shoppingSessionStore.setShoppingCart([]);
	}

	setRacks(racks: Rack[]) {
		this.shoppingSessionStore.setRacks(racks);
	}

	updateShoppingCart(productZone: ProductZone, qty: number) {
		const cart: ProductZone[] =
			this.shoppingSessionStore.getValue().shoppingCart;

		let newCart: ProductZone[] = [...cart];

		//Get the product if it already exists in the cart.
		const product = newCart.filter(
			(pz: ProductZone) => pz.product.id === productZone.product.id
		);

		// If the product is not in the cart, add it.
		if (product.length === 0) {
			productZone.cartQty += qty;
			newCart.push(productZone);
		} else {
			// The product is in the cart, so just update the quantity.
			// If the quantity reaches 0 filter it out from the cart.
			const filteredCart = newCart.filter((pz) => {
				if (pz.productId === productZone.productId) {
					pz.cartQty += qty;
				}
				if (pz.cartQty > 0) {
					return pz;
				}
			});
			newCart = filteredCart;
			console.log('Updated product quantity in cart >>> ');
			console.log(newCart);
		}
		this.shoppingSessionStore.setShoppingCart(newCart);
	}

	increaseQty(unitId: number, shelfIndex: number, productZoneIndex: number) {
		const racks = this.shoppingSessionStore.getValue().racks;
		const newRacks = racks.map((rack) => {
			if (rack.id === unitId) {
				let pz: ProductZone = rack.layers[
					shelfIndex
				].productZones.filter(
					(item) => item.zoneId == productZoneIndex.toString()
				)[0];
				pz.isHitl = true;

				this.ticketService.setHitlCart(true);
				if (pz.cartQty === pz.algoQty) {
					pz.isAlgo = true;
					pz.isHitl = false;
				} else {
					pz.isAlgo = false;
					pz.isHitl = true;
				}
				this.updateShoppingCart(pz, 1);
			}
			return rack;
		});
		this.setRacks(newRacks);
	}

	decreaseQty(unitId: number, shelfIndex: number, productZoneIndex: number) {
		const racks = this.shoppingSessionStore.getValue().racks;
		const newRacks = racks.map((rack) => {
			if (rack.id === unitId) {
				let pz: ProductZone = rack.layers[
					shelfIndex
				].productZones.filter(
					(item) => item.zoneId == productZoneIndex.toString()
				)[0];
				if (pz.cartQty === pz.algoQty) {
					pz.isAlgo = true;
					pz.isHitl = false;
				} else {
					pz.isAlgo = false;
					pz.isHitl = true;
				}
				if (pz.cartQty <= 0) {
					pz.isHitl = false;
					if (pz.cartQty < 0) {
						// this is being decreased from the cart
						// Therefore, need to find another productZone with quantity above 0
						pz.cartQty = 0;
						pz = this.findProductZoneWithQuanity(
							rack,
							pz.product.productId
						);
						pz.isHitl = pz.cartQty === 0 ? false : true;
					}
				}
				this.updateShoppingCart(pz, -1);
			}
			return rack;
		});
		this.setRacks(newRacks);
	}

	/**
	 *
	 * @param rack
	 * @param productId
	 * This function is in the event that quantity is being deducted from the cart and you've selected two items of the same kind in the product view.
	 * In this case, how do you know what product quantity to deduct from if you have selected
	 * more than one of the same product on a rack (double faced products)?
	 * This function finds that other product and continues deducting the quantity from that in case a product quantity in a zone
	 * has reached 0.
	 */
	findProductZoneWithQuanity(rack: Rack, productId: string) {
		return rack.layers.map((shelf: any) => {
			return shelf.filter((pz) => {
				if (pz !== null) {
					if (pz.productId === productId && pz.cartQty > 0) {
						return pz;
					}
				}
			});
		})[0][0];
	}

	setRackCameras(cameras: Camera[]) {
		if (!cameras || (cameras && !cameras.length)) {
			return;
		}

		this.shoppingSessionStore.setRackCameras(cameras);
	}

	reloadAllCameras() {
		const { rackCameras, overviewCameras } =
			this.shoppingSessionStore.getValue();
		// this will create fresh objects which should invalidate the video dom elements causing them to reload
		this.shoppingSessionStore.setRackCameras(
			rackCameras.map((cam) => ({ ...cam }))
		);
		this.shoppingSessionStore.setOverviewCameras(
			overviewCameras.map((cam) => ({ ...cam }))
		);
	}

	requestMoreMinutes(amount: number) {
		this.requestMoreMilliseconds(amount * 60_000);
	}

	requestMoreMilliseconds(amount: number) {
		const requestMoreMilliseconds = (cam: Camera): Camera => {
			const oldDurationString = durationFromWowzaUrl(cam.liveStreamUrl);
			const oldDuration = Number(oldDurationString) || 0;
			const duration = Math.trunc(oldDuration + amount);
			const liveStreamUrl = buildWowzaUrl(cam.liveStreamUrl, {
				duration,
			});
			return {
				...cam,
				liveStreamUrl,
			};
		};

		const { rackCameras, overviewCameras } =
			this.shoppingSessionStore.getValue();

		this.shoppingSessionStore.setRackCameras(
			rackCameras.map(requestMoreMilliseconds)
		);
		this.shoppingSessionStore.setOverviewCameras(
			overviewCameras.map(requestMoreMilliseconds)
		);
	}

	setOverviewCameras(cameras: Camera[]) {
		this.shoppingSessionStore.setOverviewCameras(cameras);
	}

	processRefund(refundStatusName: string): void {
		LogRocket.track('Submit Refund');
		this.router.navigate([CASE_COMPLETE], {
			queryParams: { refundStatusName },
		});
		// const {
		// 	orderId,
		// 	productId,
		// 	comment,
		// 	sessionId,
		// 	refundAmount
		// } = this.ticketService.getRefundInfo();

		// const accessToken = this.ticketService.getAccessToken();
		// const body = {
		// 	accessToken,
		// 	orderId,
		// 	productId,
		// 	reason: comment,
		// 	sessionId,
		// 	refundStatusId,
		// 	amount: refundAmount
		// };
		// this.http
		// 	.post(
		// 		`${this.baseUrl}/${}/hitl-session/submit-refund`,
		// 		body,
		// 		this.config
		// 	)
		// 	.subscribe(
		// 		data => {
		// 			this.router.navigate([CASE_COMPLETE]);
		// 		},
		// 		error => {
		// 			this.ticketService.clearCase();
		// 		}
		// 	);
	}

	submitRefund(id: string, body: any) {
		return this.http.post(
			`${this.baseUrl}/hitl-session/${id}/submit-refund`,
			body
		);
	}

	checkinHitlSession() {
		LogRocket.track('Checkin Hitl Session');
		const hitlSessionId = this.ticketService.getAccessToken();
		const body = {
			caseOpenTime: new Date(),
		};
		let sub = this.http
			.put(`${this.baseUrl}/hitl-session/${hitlSessionId}`, body)
			.subscribe((data) => {
				sub.unsubscribe();
			});
	}

	submitCart(caseData: any) {
		LogRocket.track('Submitted Cart');

		const isHitlCart = this.ticketService.getHitlCart() ?? true;
		const email = this.authService.getUser().email ?? 'sso';
		const caseStatusId = caseData.status;
		const notes = caseData.notes;
		const ackId = this.ticketService.getAckId();
		const hitlSessionId = this.ticketService.getAccessToken();
		const body = {
			isHitlCart,
			ackId,
			email,
			caseStatusId,
			notes,
		};

		if (isHitlCart) {
			let cartProducts = [];
			this.shoppingSessionQuery.shoppingCart$.subscribe((cart) => {
				cartProducts = cart.map((zone: ProductZone) => {
					return {
						image_url: zone.product.imageUrl,
						name: zone.product.name,
						product_id: zone.product.productCode,
						quantity: zone.cartQty,
						multiplier: zone.product.multiplier,
					};
				});
			});
			body['cart'] = cartProducts;
		}

		return this.http.post(
			`${this.baseUrl}/hitl-session/${hitlSessionId}/submit-cart`,
			body
		);
	}

	updateHitlShoppingSession(caseData: any) {
		LogRocket.track('Update Hitl Session');
		const accessToken = this.ticketService.getAccessToken();
		const email = this.authService.getUser().email || 'sso';
		const { notes, caseStatusId } = caseData;

		const body = {
			accessToken,
			email,
			notes,
			caseStatusId,
		};

		return this.http.put(
			`${this.baseUrl}/shopping-session/hitl-session`,
			body
		);
	}

	// addCartItems() {
	// 	LogRocket.track('Add Items To Cart');
	// 	const cart = this.shoppingSessionStore.getValue().shoppingCart;
	// 	const hitlSessionId = this.ticketService.getAccessToken();
	// 	const products = cart.map(pz => {
	// 		return {
	// 			productId: pz.product.productId,
	// 			quantity: pz.cartQty
	// 		};
	// 	});
	// 	const body = {
	// 		cartProducts: [...products]
	// 	};

	// 	return this.http.post(
	// 		`${this.baseUrl}/shopping-session/hitl-cart-item/${hitlSessionId}`,
	// 		body,
	// 		this.config
	// 	);
	// }

	// clearCartItems() {
	// 	LogRocket.track('Clear Cart');
	// 	const accessToken = this.ticketService.getAccessToken();

	// 	return this.http.delete(
	// 		`${this.baseUrl}/shopping-session/hitl-cart-item/${accessToken}`,
	// 		this.config
	// 	);
	// }

	clearSession() {
		this.shoppingSessionStore.reset();
	}

	async getProductByUnitId(unitId) {
		return await this.http
			.get(`${this.baseUrl}/product-assignment/unit/${unitId}`)
			.pipe(take(1))
			.toPromise();
	}
}
