import { Component, OnInit } from '@angular/core';
import { Camera } from '../../Models/camera.model';
import { CameraService } from '../../camera.service';
import { ShoppingSessionQuery } from 'src/app/ShoppingSession/shopping-session/shopping-session-state/shopping-session.query';
import { Observable } from 'rxjs';
import { ShoppingSessionService } from 'src/app/ShoppingSession/shopping-session/shopping-session-state/shopping-session.service';
import { IPlayable } from '@videogular/ngx-videogular/core';

@Component({
	selector: 'app-camera-sidebar',
	templateUrl: './camera-sidebar.component.html',
	styleUrls: ['./camera-sidebar.component.css'],
})
export class CameraSidebarComponent implements OnInit {
	overviewCameras$: Observable<Camera[]> =
		this.shoppingSessionQuery.overviewCameras$;

	selectedCamera: number;

	constructor(
		private cameraService: CameraService,
		private shoppingSessionQuery: ShoppingSessionQuery,
		private shoppingSessionService: ShoppingSessionService
	) {}

	ngOnInit(): void {
		this.cameraService.getOverviewCameras();
	}

	updateCameraId(
		camera: Camera,
		videoElement: HTMLVideoElement,
		playable: IPlayable
	) {
		this.selectedCamera = camera.id;
		this.shoppingSessionService.setCameraId(camera.id);
		this.cameraService.setMainVideoElement(videoElement, playable, camera);
	}
}
