<h3>STORE FLOOR</h3>
<ng-container *ngIf="(store$ | async)?.length > 0">
	<div class="map_wrapper">
		<store-map
			view-type="3d"
			[store]="store$ | async"
			[origin]="showOrigin"
			[label]="true"
			(data)="displayData($event)"
		></store-map>
	</div>
</ng-container>
<!-- 
<div *ngIf="store$ | async as store">
	<figure id="projectsvg">
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 350 333"
			preserveAspectRatio="xMidYMid meet"
			#svg
		>
			<image
				attr.xlink:href="{{ store.layoutUrl }}"
				#img
				(load)="updateViewBox(img)"
			/>
			<ng-container *ngFor="let gondola of store.gondolas">
				<g
					*ngFor="let rack of gondola.racks"
					[ngClass]="
						rack.id === (rack$ | async)?.id
							? 'highlighted'
							: 'hover_group'
					"
				>
					<a (click)="updateProducts(rack, rack.id)">
						<rect
							[attr.x]="rack.left - rack.width / 2"
							[attr.y]="rack.top - rack.height / 2"
							opacity="0.6"
							fill="#00805e"
							[attr.width]="rack.width"
							[attr.height]="rack.height"
							[attr.transform]="
								'rotate(' +
								rack.angle +
								',' +
								rack.left +
								',' +
								rack.top +
								')'
							"
						></rect>
					</a>
					<ng-container
						*ngFor="let camera of rack.cameras; let i = index"
					>
						<image
							href="./assets/img/videocam.svg"
							class="rack-camera"
							(click)="updateSelectedCamera(camera.id)"
							height="30"
							width="30"
							[attr.x]="rack.left - rack.width - 50"
							[attr.y]="
								i % 2 !== 0
									? rack.top + rack.width - 30
									: rack.top - rack.width
							"
							[attr.transform]="
								'rotate(' +
								(rack.top - rack.width < 0
									? i % 2 !== 0
										? rack.angle + 135
										: rack.angle - 135
									: i % 2 !== 0
									? rack.angle - 45
									: rack.angle + 45) +
								',' +
								rack.left +
								',' +
								rack.top +
								')'
							"
						/>
					</ng-container>
				</g>
			</ng-container>
		</svg>
	</figure>
</div> -->
