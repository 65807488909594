export class EnvService {
	// The values that are defined here are the default values that can
	// be overridden by env.js

	// API url
	public title = '';
	public ssoClientId = '';
	public authUrl = '';
	public ticketingUrl = '';
	public hitlServiceUrl = '';
	public authApiKey = '';
	public logrocket = '';
	public ssoTenantId = '';
	public smpUrl = '';

	// Whether or not to enable debug mode
	public enableDebug = true;

	//SSO
	public issuer = '';
	public clientId = '';
	public clientSecret = '';
	public ssoConfig = '';
	public ssoTestConfig = '';

	constructor() {}
}
