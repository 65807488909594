import { Component, OnInit } from '@angular/core';
import { CustomerImageService } from '../customer-image.service';
import { CustomerImages } from './models/customer-images.model';
import { Observable } from 'rxjs';
import { REFUND } from '../../../utils/constants';
import { TicketQuery } from 'src/app/ticket/state/ticket.query';
import { CameraService } from '../../camera/camera.service';
import { ShoppingSessionService } from '../../shopping-session/shopping-session-state/shopping-session.service';

@Component({
	selector: 'app-customer-image',
	templateUrl: './customer-image.component.html',
	styleUrls: ['./customer-image.component.css'],
})
export class CustomerImageComponent implements OnInit {
	customerImages$: Observable<CustomerImages>;

	refund: string = REFUND;
	ticketType$: Observable<string> = this.ticketQuery.ticketType$;

	constructor(
		private customerImageService: CustomerImageService,
		private ticketQuery: TicketQuery,
		private shoppingSessionService: ShoppingSessionService
	) {}

	ngOnInit() {
		this.customerImages$ = this.customerImageService.getCustomerImage();
	}

	handleError(event) {
		event.target.src = '../../../../assets/img/avatar.png';
		event.target.classList.add('no-image');
	}

	reloadAllVideos() {
		this.shoppingSessionService.reloadAllCameras();
	}

	requestMoreMinutes(amount: number) {
		this.shoppingSessionService.requestMoreMinutes(amount);
	}

	requestMoreMilliseconds(amount: number) {
		this.shoppingSessionService.requestMoreMilliseconds(amount);
	}
}
