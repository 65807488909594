import { Injectable } from '@angular/core';
import { EnvService } from '../../Services/env-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user.model';
import { ManagementService } from '../management.service';
import { Role } from '../models/role.model';
import { Team } from '../models/team.model';
import { ErrorDialogService } from 'src/app/error-dialog/error-dialog.service';
import { flatMap, startWith } from 'rxjs/operators';
import { Observable, interval, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserProfileService {
	private refreshInterval = 10000;
	private baseUrl = `${this.env.hitlServiceUrl}`;

	constructor(
		private env: EnvService,
		private http: HttpClient,
		private managementService: ManagementService,
		private err: ErrorDialogService
	) {}

	getUserProfiles(): void {
		this.http.get<User[]>(`${this.baseUrl}/user-profile`).subscribe(
			(users: User[]) => {
				this.managementService.setUserProfiles(users);
			},
			(error) => this.err.showError(error.message)
		);
	}

	getUserRoles(): void {
		this.http.get<Role[]>(`${this.baseUrl}/user-profile/role`).subscribe(
			(roles: Role[]) => {
				this.managementService.setRoles(roles);
			},
			(error) => this.err.showError(error.message)
		);
	}

	getTeams(): void {
		this.http.get<Team[]>(`${this.baseUrl}/user-profile/team`).subscribe(
			(teams: Team[]) => {
				this.managementService.setTeams(teams);
			},
			(error) => this.err.showError(error.message)
		);
	}

	createUserProfile(userProfile: string): Observable<User> {
		return this.http.post<User>(
			`${this.baseUrl}/user-profile`,
			userProfile
		);
		// interval(this.refreshInterval)
		// 	.pipe(startWith(0))
		// 	.pipe(
		// 		flatMap(() =>
		// 			this.http.get<User[]>(`${this.baseUrl}/user-profile`)
		// 		)
		// 	)
		// 	.subscribe((users: User[]) => {
		// 		this.managementService.setUserProfiles(users);
		// 	});
	}

	updateUserProfile(userProfile: string): Observable<User> {
		return this.http.put<User>(`${this.baseUrl}/user-profile`, userProfile);
	}

	deleteUserProfile(userProfile: string): Observable<User> {
		return this.http.delete<User>(
			`${this.baseUrl}/user-profile/email/${userProfile['email']}`
		);
	}
}
