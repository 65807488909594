import { Component, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription, Subject } from 'rxjs';
import { takeUntil, mergeMap } from 'rxjs/operators';
import { TicketService } from '../ticket/state/ticket.service';
import { Router } from '@angular/router';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';
import { CUSTOMER, ACCEPT_CASE } from '../utils/routes.constants';
import { REFUND } from '../utils/constants';
import { SmpService } from '../Services/smp.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
	selector: 'app-agent-waiting',
	templateUrl: './agent-waiting.component.html',
	styleUrls: ['./agent-waiting.component.css'],
})
export class AgentWaitingComponent implements OnInit, OnDestroy {
	unsubscribe = new Subject<any>();
	sub: Subscription;

	constructor(
		private ticketService: TicketService,
		private router: Router,
		private errDialog: ErrorDialogService,
		private smpService: SmpService,
		private oauthService: OAuthService
	) {}

	ngOnInit() {
		this.retrieveTicket();
		console.log('===> got to agent-waiting (HOME) ');
	}

	ngOnDestroy(): void {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	retrieveTicket() {
		this.ticketService.clearPreviousCases();
		this.sub = interval(30000)
			.pipe(
				takeUntil(this.unsubscribe),
				mergeMap(() => this.ticketService.getSingleTicket())
			)
			.subscribe(
				async (data: any) => {
					if (data) {
						// Currently tickets with refunds return the store code, so we need to reach out to smp to get the store id
						if (this.ticketService.getTicketType() === REFUND) {
							const storeInfo =
								await this.smpService.getStoreIdByStoreCode(
									data.payload.storeId
								);
							this.ticketService.setStoreId(storeInfo?.id);
							sessionStorage.setItem(
								'storeId',
								JSON.stringify(storeInfo?.id)
							);
						} else {
							this.ticketService.setStoreId(data.payload.storeId);
							sessionStorage.setItem(
								'storeId',
								JSON.stringify(data.payload.storeId)
							);
						}
						this.ticketService.setAccessToken(
							data.payload.accessToken
						);

						this.ticketService.setAckId(data.ack);
						this.ticketService.setPayload(data.payload);
						sessionStorage.setItem(
							'accessToken',
							JSON.stringify(data.payload.accessToken)
						);

						sessionStorage.setItem(
							'ackId',
							JSON.stringify(data.ack)
						);
						sessionStorage.setItem(
							'payload',
							JSON.stringify(data.payload)
						);
						this.unsubscribe.next();
						this.router.navigate([ACCEPT_CASE]);
					}
				},
				(error) => {
					this.retrieveTicket();
				}
			);
	}
}
