<div class="login-wrapper">
	<img class="patterns" src="./assets/img/Patterns.png" alt="" />
	<mat-icon class="title-icon">error_outline</mat-icon>
	<h2 class="error-message">{{ errorData.title }}</h2>

	<p class="error-message">{{ errorData.message }}</p>

	<code class="error-payload" [class.shown]="showPayload">
		<pre>{{ rawPayloadString() }}</pre>
	</code>
	<mat-icon
		(click)="showPayload = !showPayload"
		class="button-show-error"
		[class.shown]="showPayload"
	>
		expand_more
	</mat-icon>

	<button mat-raised-button (click)="cancel()">Ok</button>
</div>
