import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu.component';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';

@NgModule({
	imports: [CommonModule, AppMaterialModule],
	exports: [SideMenuComponent],
	declarations: [SideMenuComponent],
	providers: [],
})
export class SideMenuModule {}
