<div class="dialog-wrapper">
	<div class="rectangle">
		<p md-dialog-title class="title">User Profile</p>
		<form [formGroup]="form">
			<mat-card class="agent-card">
				<fieldset class="fieldset">
					<mat-card-content>
						<div class="form-row">
							<div class="col">
								<mat-form-field appearance="outline">
									<mat-label>First Name</mat-label>
									<input
										matInput
										formControlName="firstName"
									/>
									<mat-error
										*ngIf="
											form.controls['firstName'].errors
												?.required
										"
									>
										First Name is Required.
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col">
								<mat-form-field appearance="outline">
									<mat-label>Last Name</mat-label>
									<input
										matInput
										formControlName="lastName"
									/>
									<mat-error
										*ngIf="
											form.controls['lastName'].errors
												?.required
										"
									>
										Last Name is Required.
									</mat-error>
								</mat-form-field>
							</div>
						</div>
						<div class="form-row">
							<div class="col">
								<mat-form-field appearance="outline">
									<mat-label>Role</mat-label>
									<mat-select
										formControlName="role"
										[compareWith]="compareRoles"
									>
										<mat-option
											*ngFor="let role of roles$ | async"
											[value]="role"
										>
											{{ role.name }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col">
								<mat-form-field appearance="outline">
									<mat-label>Phone</mat-label>
									<input matInput formControlName="phone" />
									<mat-error
										*ngIf="
											form.controls['phone'].errors
												?.required
										"
									>
										Phone is Required.
									</mat-error>
									<mat-error
										*ngIf="
											form.controls['phone'].errors
												?.pattern
										"
									>
										Phone has the wrong format.
									</mat-error>
								</mat-form-field>
							</div>
						</div>

						<div class="form-row">
							<div class="col">
								<mat-form-field appearance="outline">
									<mat-label>Team</mat-label>
									<mat-select
										formControlName="team"
										[compareWith]="compareTeams"
									>
										<mat-option
											*ngFor="let team of teams$ | async"
											[value]="team"
										>
											{{ team.name }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col">
								<mat-form-field appearance="outline">
									<mat-label>Email</mat-label>
									<input matInput formControlName="email" />
									<mat-error
										*ngIf="
											form.controls['email'].errors
												?.required
										"
									>
										Email is Required.
									</mat-error>
									<mat-error
										*ngIf="
											form.controls['email'].errors
												?.pattern
										"
									>
										Email has the wrong format.
									</mat-error>
								</mat-form-field>
							</div>
						</div>
						<div class="form-row">
							<div class="col">
								<mat-form-field
									floatLabel="always"
									appearance="outline"
								>
									<mat-label>Assign Cases</mat-label>
									<mat-slide-toggle
										formControlName="isTakingCases"
									>
									</mat-slide-toggle>
									<textarea matInput hidden></textarea>
								</mat-form-field>
							</div>
							<div class="col"></div>
						</div>
					</mat-card-content>
				</fieldset>
				<mat-dialog-actions>
					<div class="buttons">
						<button
							mat-raised-button
							[disabled]="userData.parent === 'dialog-add'"
							(click)="onDelete(form)"
							type="submit"
						>
							Delete
						</button>
						<button
							mat-raised-button
							(click)="onSubmit(form)"
							type="submit"
						>
							Submit
						</button>
					</div>
				</mat-dialog-actions>
			</mat-card>
		</form>
	</div>
</div>
