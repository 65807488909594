<div class="rectangle">
	<div class="parent-banner">
		<img class="patterns" src="./assets/img/Patterns.png" alt="" />
		<img class="logo" src="./assets/img/Logo.svg" />
		<p class="new-case">A new case has been assigned to you.</p>
		<span
			[ngClass]="
				isLastChanceToAcceptCase ? 'pulse-button-warn' : 'pulse-button'
			"
			><h1 id="count-down">{{ countdown }}</h1></span
		>
		<p *ngIf="!isLastChanceToAcceptCase" class="start-case">
			Would you like to start the case?
		</p>
		<p *ngIf="isLastChanceToAcceptCase" class="start-case-warn">
			Are you still available to take a case? If not the system will sign
			you off.
		</p>
	</div>
	<div class="buttons">
		<button mat-raised-button (click)="acceptTicket()">YES</button>
		<button mat-raised-button (click)="didNotAccept()">NO</button>
	</div>
</div>
