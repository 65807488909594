import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/auth/state/auth.service';
import { TicketService } from 'src/app/ticket/state/ticket.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subject, interval } from 'rxjs';
import { takeUntil, mergeMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AcceptCaseComponent } from 'src/app/accept-case/accept-case.component';
import { CaseTypeSelectionComponent } from 'src/app/case-type-selection/case-type-selection.component';
import { REFUND } from 'src/app/utils/constants';
import { SmpService } from 'src/app/Services/smp.service';
@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.css'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
	_destroying$: Subject<void> = new Subject();
	isSelectingCases: boolean = false;
	isMenuSelected: boolean = false;
	constructor(
		public authService: AuthService,
		private ticketService: TicketService,
		public dialog: MatDialog,
		private smpService: SmpService
	) {}
	ngOnInit(): void {
		this.ticketService.clearAccessToken();
		this.ticketService.clearAckId();
		this.ticketService.addCaseReject();
	}
	ngOnDestroy(): void {
		this._destroying$.next();
	}
	isTakingCases(event: MatSlideToggleChange) {
		this.isSelectingCases = event.checked;
		if (this.isSelectingCases) {
			const dialogRef = this.dialog.open(CaseTypeSelectionComponent, {
				disableClose: true,
				panelClass: 'backdropBackground',
			});
			dialogRef
				.afterClosed()
				.pipe(takeUntil(this._destroying$))
				.subscribe(() => {
					this.getTicket();
				});
		} else {
			this._destroying$.next();
		}
	}
	openDialog() {
		const tempTicket = this.ticketService.getAckId();
		if (tempTicket) {
			const dialogRef = this.dialog.open(AcceptCaseComponent, {
				disableClose: true,
				panelClass: 'backdropBackground',
			});
			dialogRef
				.afterClosed()
				.pipe(takeUntil(this._destroying$))
				.subscribe((dialogCloseMessage: string) => {
					if (
						(this.isSelectingCases &&
							dialogCloseMessage !== 'accepted') ||
						(this.isSelectingCases &&
							dialogCloseMessage === 'denied')
					) {
						this.getTicket();
					} else {
						this._destroying$.next();
					}
				});
		} else {
			this.getTicket();
		}
	}
	getTicket() {
		interval(1000)
			.pipe(
				takeUntil(this._destroying$),
				mergeMap(() => this.ticketService.getSingleTicket())
			)
			.subscribe(
				async (data: any) => {
					if (data) {
						console.log(data);
						if (this.ticketService.getTicketType() === REFUND) {
							const storeInfo =
								await this.smpService.getStoreIdByStoreCode(
									data.payload.storeId
								);
							this.ticketService.setStoreId(storeInfo?.id);
							sessionStorage.setItem(
								'storeId',
								JSON.stringify(storeInfo?.id)
							);
						} else {
							this.ticketService.setStoreId(data.payload.storeId);
							sessionStorage.setItem(
								'storeId',
								JSON.stringify(data.payload.storeId)
							);
						}
						this.ticketService.setAccessToken(
							data.payload.accessToken
						);
						this.ticketService.setAckId(data.ack);
						this.ticketService.setPayload(data.payload);
						sessionStorage.setItem(
							'accessToken',
							JSON.stringify(data.payload.accessToken)
						);
						sessionStorage.setItem(
							'ackId',
							JSON.stringify(data.ack)
						);
						sessionStorage.setItem(
							'payload',
							JSON.stringify(data.payload)
						);
						this._destroying$.next();
						this.openDialog();
					}
				},
				(error) => {
					console.log('getTicket error => ' + error);
					this._destroying$.next();
					this.getTicket();
				}
			);
	}
	signOut() {
		this.ticketService.clearAccessToken();
		this.ticketService.clearStatus();
		this.ticketService.clearCase();
		this.ticketService.clearTicketType();
		this.authService.logout();
	}
}
