import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManagementComponent } from './management.component';
import { AuthGuard } from '../auth/auth.guard';

const routes: Routes = [
	{
		path: '',
		component: ManagementComponent,
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./user-profile/user-profile.module').then(
						(m) => m.UserProfileModule
					),
				canActivate: [AuthGuard],
			},
			{
				path: 'user-profile',
				loadChildren: () =>
					import('./user-profile/user-profile.module').then(
						(m) => m.UserProfileModule
					),
				canActivate: [AuthGuard],
			},
			{
				path: 'refund',
				loadChildren: () =>
					import('./refund/refund.module').then(
						(m) => m.RefundModule
					),
				canActivate: [AuthGuard],
			},
			{
				path: 'reports',
				loadChildren: () =>
					import('./reports/reports.module').then(
						(m) => m.ReportsModule
					),
				canActivate: [AuthGuard],
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ManagementRoutingModule {}
