import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagementComponent } from './management.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { ManagementRoutingModule } from './management.routing.module';
import { ToolbarModule } from './toolbar/toolbar.module';
import { SideMenuModule } from './side-menu/side-menu.module';
import { ManagementStore } from './management.store';
import { ManagementSessionQuery } from './management.query';
import { AgentDialogModule } from './agent-dialog/agent-dialog.module';

@NgModule({
	declarations: [ManagementComponent],
	imports: [
		CommonModule,
		AppMaterialModule,
		ManagementRoutingModule,
		ToolbarModule,
		SideMenuModule,
		AgentDialogModule,
	],
	exports: [ManagementComponent],
	bootstrap: [ManagementComponent],
})
export class ManagementModule {
	static forRoot(): ModuleWithProviders<ManagementModule> {
		return {
			ngModule: ManagementModule,
			providers: [ManagementStore, ManagementSessionQuery],
		};
	}
}
