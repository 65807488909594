import { Component, OnInit } from '@angular/core';
import { TicketQuery } from 'src/app/ticket/state/ticket.query';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-customer-notes',
	templateUrl: './customer-notes.component.html',
	styleUrls: ['./customer-notes.component.css'],
})
export class CustomerNotesComponent {
	notes$: Observable<string> = this.ticketQuery.customerNotes$;

	constructor(private ticketQuery: TicketQuery) {}
}
