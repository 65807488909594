import { Injectable } from '@angular/core';
import { EnvService } from '../../Services/env-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TicketService } from '../../ticket/state/ticket.service';
import { ShoppingSessionService } from '../shopping-session/shopping-session-state/shopping-session.service';
import { AlgoCartItems } from './Models/algo-cart-items';
import { Rack } from '../shopping-session/shopping-session-state/Models/rack.model';

@Injectable({
	providedIn: 'root',
})
export class ProductViewService {
	private baseUrl = `${this.env.hitlServiceUrl}`;

	constructor(
		private env: EnvService,
		private http: HttpClient,
		private ticketService: TicketService,
		private shoppingSessionService: ShoppingSessionService
	) {}

	setProducts(products) {}

	getAlgoProducts(): void {
		const hitlSessionId = this.ticketService.getAccessToken();
		this.http
			.get<AlgoCartItems>(
				`${this.baseUrl}/hitl-session/${hitlSessionId}/algo-cart`
			)
			.subscribe((response: any) => {
				this.shoppingSessionService.setAlgoProducts(
					response.AlgoCartItems
				);
			});
	}

	findFirstRackWithProducts(units: any[]): any {
		let foundUnit = null;
		units.forEach((unit) => {
			if (unit.layers && unit.layers.length > 0) {
				unit.layers.forEach((layer: any) => {
					if (layer.length > 0) {
						if (foundUnit === null) {
							foundUnit = unit;
						}
					}
				});
			}
		});
		return foundUnit === null ? units[0] : foundUnit;
	}
}
