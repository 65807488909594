import { IUser } from '../models/user.model';
import { Store, StoreConfig } from '@datorama/akita';

export type AuthState = {
	user: IUser | null;
};

export const initialState: AuthState = {
	user: JSON.parse(sessionStorage.getItem('user')),
};

@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
	constructor() {
		super(initialState);
	}

	updateUser(user: IUser) {
		if (!user) return;

		this.update({ user });
		sessionStorage.setItem('user', JSON.stringify(user));
	}

	logout() {
		this.update({ user: null });
		sessionStorage.removeItem('user');
	}
}
