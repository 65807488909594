import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { AgentDialogComponent } from './agent-dialog.component';

@NgModule({
	declarations: [AgentDialogComponent],
	imports: [CommonModule, AppMaterialModule],
	exports: [AgentDialogComponent],
	bootstrap: [AgentDialogComponent],
})
export class AgentDialogModule {
	static forRoot(): ModuleWithProviders<AgentDialogModule> {
		return {
			ngModule: AgentDialogModule,
			providers: [],
		};
	}
}
