import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerImageComponent } from './customer-image/customer-image.component';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';

@NgModule({
	declarations: [CustomerImageComponent],
	imports: [CommonModule, AppMaterialModule],
	exports: [CustomerImageComponent],
})
export class CustomerImageModule {}
