import {
	Component,
	OnInit,
	Inject,
	ViewEncapsulation,
	OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../models/user.model';
import { UserProfileService } from '../user-profile/user-profile.service';
import { ManagementSessionQuery } from '../management.query';
import { ManagementStore } from '../management.store';
import { DialogData } from '../models/dialog-data.model';
import { Role } from '../models/role.model';
import { Observable, Subject } from 'rxjs';
import { Team } from '../models/team.model';
import { ManagementService } from '../management.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorDialogService } from 'src/app/error-dialog/error-dialog.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import {
	DIALOG_ADD,
	DIALOG_EDIT,
	DIALOG_SUBMIT,
	DIALOG_UPDATE,
} from '../../utils/constants';

const singleInputValidators = [
	Validators.required,
	Validators.minLength(1),
	Validators.maxLength(40),
];

const phoneValidators = [Validators.required, Validators.pattern('[0-9 ]{10}')];

const emailValidators = [Validators.required, Validators.email];

@Component({
	selector: 'app-agent-dialog',
	templateUrl: './agent-dialog.component.html',
	styleUrls: ['./agent-dialog.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class AgentDialogComponent implements OnInit, OnDestroy {
	form: FormGroup = new FormGroup({
		firstName: new FormControl(
			this.userData.data != '' ? this.userData.data?.firstName : '',
			singleInputValidators
		),
		team: new FormControl(
			this.userData.data != '' ? this.userData.data?.team : '',
			singleInputValidators
		),
		email: new FormControl(
			this.userData.data != '' ? this.userData.data?.email : '',
			emailValidators
		),
		lastName: new FormControl(
			this.userData.data != '' ? this.userData.data?.lastName : '',
			singleInputValidators
		),
		role: new FormControl(
			this.userData.data != '' ? this.userData.data?.role : '',
			singleInputValidators
		),
		phone: new FormControl(
			this.userData.data != '' ? this.userData.data?.phone : '',
			phoneValidators
		),
		isTakingCases: new FormControl(
			this.userData.data != ''
				? this.userData.data?.isTakingCases
				: false,
			singleInputValidators
		),
	});

	roles$: Observable<Role[]> = this.managementQuery.roles$;
	teams$: Observable<Team[]> = this.managementQuery.teams$;
	_destroying$: Subject<void> = new Subject();

	constructor(
		public thisDialogRef: MatDialogRef<AgentDialogComponent>,
		private userProfileService: UserProfileService,
		private managementQuery: ManagementSessionQuery,
		private managementStore: ManagementStore,
		private managementService: ManagementService,
		private err: ErrorDialogService,
		private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public userData: any
	) {}

	ngOnInit(): void {
		if (this.managementStore.getValue().roles.length == 0)
			this.userProfileService.getUserRoles();
		if (this.managementStore.getValue().teams.length == 0)
			this.userProfileService.getTeams();

		if (this.userData.parent === DIALOG_EDIT)
			this.form.controls['email'].disable();
	}

	ngOnDestroy(): void {
		this._destroying$.next();
	}

	onDelete({ valid: boolean }) {
		let dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: 'Do you want to delete this user?',
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result === true) {
				this.userProfileService
					.deleteUserProfile(this.form.getRawValue())
					.pipe(takeUntil(this._destroying$))
					.subscribe(
						(user: User) => {
							this.managementService.deleteProfile(user);
							this.thisDialogRef.close(DIALOG_SUBMIT);
						},
						(error) => this.err.showError(error.message)
					);
			}
		});
	}

	onSubmit({ valid }: { valid: boolean }) {
		if (valid && this.userData.parent === DIALOG_ADD) {
			this.userProfileService
				.createUserProfile(this.form.getRawValue())
				.pipe(takeUntil(this._destroying$))
				.subscribe(
					(user: User) => {
						this.managementService.addProfile(user);
						this.thisDialogRef.close(DIALOG_SUBMIT);
					},
					(error) => this.err.showError(error.message)
				);
		} else if (valid && this.userData.parent === DIALOG_EDIT) {
			this.userProfileService
				.updateUserProfile(this.form.getRawValue())
				.pipe(takeUntil(this._destroying$))
				.subscribe((user: User) => {
					this.managementService.updateProfile(user);
					this.thisDialogRef.close(DIALOG_UPDATE);
				});
			(error) => this.err.showError(error.message);
		}
	}

	compareRoles(roleGiven: string, role: string) {
		return roleGiven['id'] === role['id'];
	}

	compareTeams(teamGiven: string, team: string) {
		return teamGiven['id'] === team['id'];
	}
}
