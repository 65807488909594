import { Injectable } from '@angular/core';
import { EnvService } from '../../Services/env-service.service';
import { TicketService } from '../../ticket/state/ticket.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomerImages } from './customer-image/models/customer-images.model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CustomerImageService {
	private baseUrl = `${this.env.hitlServiceUrl}`;

	constructor(
		private env: EnvService,
		private ticketService: TicketService,
		private http: HttpClient
	) {}

	getCustomerImage(): Observable<CustomerImages> {
		const hitlSessionId = this.ticketService.getAccessToken();

		return this.http.get<CustomerImages>(
			`${this.baseUrl}/hitl-session/snapshots/hitlSessionId/${hitlSessionId}`
		);
	}
}
