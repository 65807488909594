<div class="row">
	<p class="title">PICKED PRODUCTS</p>
</div>

<div class="row">
	<div class="col-3">
		<div class="row">
			<div class="col">
				<p class="product-name">
					{{ (refundData$ | async)?.name }}
				</p>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="img rounded">
					<img
						[src]="(refundData$ | async)?.imageUrl"
						class="rounded"
					/>
					<p class="qty">
						QTY: {{ (refundData$ | async)?.productQuantity }}
					</p>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<button
					class="decline-button"
					mat-raised-button
					(click)="processCase(RefundStatus.UNAPPROVED)"
				>
					DECLINE
				</button>

				<button
					class="accept-button"
					mat-raised-button
					(click)="processCase(RefundStatus.APPROVED)"
				>
					ACCEPT
				</button>
			</div>
		</div>
	</div>
</div>
