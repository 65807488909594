import { Injectable } from '@angular/core';
import { ManagementStore } from './management.store';
import { User } from './models/user.model';
import { Role } from './models/role.model';
import { Team } from './models/team.model';
import { of } from 'core-js/fn/array';
import { Refund } from './models/refund.model';

@Injectable({ providedIn: 'root' })
export class ManagementService {
	constructor(private managementStore: ManagementStore) {}

	setUserProfiles(userProfiles: User[]) {
		this.managementStore.setUserProfiles(userProfiles);
	}

	setRoles(roles: Role[]) {
		this.managementStore.setRoles(roles);
	}

	setTeams(teams: Team[]) {
		this.managementStore.setTeams(teams);
	}

	addProfile(user: User) {
		const profiles = this.managementStore.getValue().userProfiles;
		profiles.push(user);
		this.managementStore.setUserProfiles(profiles);
	}

	setRefunds(refunds: Refund[]) {
		this.managementStore.setRefunds(refunds);
	}

	updateProfile(user: User) {
		const oldUsers: User[] = this.managementStore.getValue().userProfiles;
		let newUsers: User[] = [...oldUsers];
		const changedIndex = newUsers.findIndex(
			(u: User) => u.email === user.email
		);

		newUsers.splice(changedIndex, 1, user);
		this.managementStore.setUserProfiles(newUsers);
	}

	deleteProfile(user: User) {
		const oldUsers: User[] = this.managementStore.getValue().userProfiles;
		let newUsers: User[] = [...oldUsers];
		const deleteIndex = newUsers.findIndex(
			(u: User) => u.email === user.email
		);

		newUsers.splice(deleteIndex, 1);
		this.managementStore.setUserProfiles(newUsers);
	}

	clearSession() {
		this.managementStore.reset();
	}
}
