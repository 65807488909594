import { Component, OnInit } from '@angular/core';
import { ShoppingSessionQuery } from '../shopping-session/shopping-session-state/shopping-session.query';

import { Observable } from 'rxjs';
import { ShoppingSessionService } from '../shopping-session/shopping-session-state/shopping-session.service';
import { Router } from '@angular/router';
import {
	faMinusSquare,
	faPlusSquare,
} from '@fortawesome/free-regular-svg-icons';
import { TicketService } from 'src/app/ticket/state/ticket.service';
import { ErrorDialogService } from 'src/app/error-dialog/error-dialog.service';
import { CASE_COMPLETE } from 'src/app/utils/routes.constants';
import { ProductZone } from 'src/app/Models/product-zone';

@Component({
	selector: 'app-shopping-cart',
	templateUrl: './shopping-cart.component.html',
	styleUrls: ['./shopping-cart.component.css'],
})
export class ShoppingCartComponent {
	cart$: Observable<ProductZone[]> = this.shoppingSessionQuery.shoppingCart$;
	faMinusSquare = faMinusSquare;
	faPlusSquare = faPlusSquare;

	constructor(
		private shoppingSessionQuery: ShoppingSessionQuery,
		private shoppingSessionService: ShoppingSessionService,
		private router: Router,
		private ticketService: TicketService,
		private err: ErrorDialogService
	) {}

	increaseQty(productZone: ProductZone) {
		this.shoppingSessionService.increaseQty(
			productZone.unitId,
			Number(productZone.shelfIndex) - 1,
			Number(productZone.zoneId)
		);
	}

	decreaseQty(productZone: ProductZone) {
		this.shoppingSessionService.decreaseQty(
			productZone.unitId,
			Number(productZone.shelfIndex) - 1,
			Number(productZone.zoneId)
		);
	}

	nonShopper() {
		this.shoppingSessionService.clearShoppingSession();

		this.router.navigateByUrl(CASE_COMPLETE, {
			skipLocationChange: true,
		});
	}

	clearAll() {
		this.shoppingSessionService.clearShoppingSession();
	}

	checkout() {
		// //clear the cart item in case this case has been processed before with error
		// this.shoppingSessionService.clearCartItems().subscribe(
		// 	data => {
		// 		this.shoppingSessionService.addCartItems().subscribe(
		// 			data => {
		// 				this.ticketService.setSubmittedCart(true);
		// 				this.router.navigateByUrl(CASE_COMPLETE, {
		// 					skipLocationChange: true
		// 				});
		// 			},
		// 			error => this.err.openDialog(error.message)
		// 		);
		// 	},
		// 	error => this.err.openDialog(error.message)
		// );

		this.ticketService.setSubmittedCart(true);
		this.router.navigateByUrl(CASE_COMPLETE, {
			skipLocationChange: true,
		});
	}
}
