import { Store, StoreConfig } from '@datorama/akita';
import { SHOPPING_SESSION, REFUND } from '../../../app/utils/constants';

export type TicketState = {
	status: string | null;
	// Ticket accessToken is the same as id field of hitl session
	accessToken: string;
	ackId: string;
	isHitlCart: boolean;
	caseRejects: number;
	storeId: number;
	ticketType: string;
	payload: any;
	accepted: boolean;
	submittedCart: boolean;
};

export const initialState: TicketState = {
	status: JSON.parse(sessionStorage.getItem('status')) ?? null,
	accessToken: JSON.parse(sessionStorage.getItem('accessToken')) ?? null,
	storeId: JSON.parse(sessionStorage.getItem('storeId')) ?? null,
	ackId: JSON.parse(sessionStorage.getItem('ackId')) ?? null,
	isHitlCart: JSON.parse(sessionStorage.getItem('isHitlCart')) ?? null,
	caseRejects: JSON.parse(sessionStorage.getItem('caseRejects')) ?? 0,
	ticketType: sessionStorage.getItem('ticketType') ?? SHOPPING_SESSION,
	payload: JSON.parse(sessionStorage.getItem('payload')) ?? null,
	accepted: JSON.parse(sessionStorage.getItem('accepted')) ?? false,
	submittedCart: JSON.parse(sessionStorage.getItem('submittedCart')) ?? false,
};

@StoreConfig({ name: 'ticket' })
export class TicketStore extends Store<TicketState> {
	constructor() {
		super(initialState);
	}

	setStatus(status: string) {
		this.update({ status });
	}

	setStoreId(storeId: number) {
		this.update({ storeId });
	}

	setAccessToken(accessToken: string) {
		this.update({ accessToken });
	}

	clearAccessToken(accessToken = null) {
		sessionStorage.removeItem('accessToken');
		this.update({ accessToken });
	}

	setAckId(ackId: string) {
		this.update({ ackId });
	}

	clearAckId(ackId = null) {
		sessionStorage.removeItem('ackId');
		this.update({ ackId });
	}

	clearStatus(status = null) {
		sessionStorage.removeItem('status');
		this.update({ status });
	}

	setIsHitlCart(isHitlCart: boolean) {
		this.update({ isHitlCart });
	}

	clearIsHitlCart(isHitlCart = null) {
		sessionStorage.removeItem('isHitlCart');
		this.update({ isHitlCart });
	}

	setCaseRejects(caseRejects: number) {
		this.update({ caseRejects });
	}

	clearCaseRejects(caseRejects = 0) {
		sessionStorage.removeItem('caseRejects');
		this.update({ caseRejects });
	}

	clearStoreId(storeId = null) {
		sessionStorage.removeItem('payload');
		this.update({ storeId });
	}

	clearPayload(payload = null) {
		sessionStorage.removeItem('payload');
		this.update({ payload });
	}

	clearTicketType(ticketType: string = SHOPPING_SESSION) {
		this.update({ ticketType });
	}

	setTicketType(ticketType: string) {
		this.update({ ticketType });
	}

	setPayload(payload: any) {
		this.update({ payload });
	}

	setAccepted(accepted: boolean) {
		sessionStorage.setItem('accepted', accepted.toString());
		this.update({ accepted });
	}

	setSubmittedCart(submittedCart: boolean) {
		sessionStorage.setItem('submittedCart', submittedCart.toString());
		this.update({ submittedCart });
	}
}
