import { Component, OnInit } from '@angular/core';
import { ShoppingSessionQuery } from '../shopping-session/shopping-session-state/shopping-session.query';
import { ShoppingSessionService } from '../shopping-session/shopping-session-state/shopping-session.service';

@Component({
	selector: 'app-product-list',
	templateUrl: './product-list.component.html',
	styleUrls: ['./product-list.component.css'],
})
export class ProductListComponent {
	productZones$ = this.shoppingSessionQuery.shoppingCart$;

	constructor(
		private shoppingSessionQuery: ShoppingSessionQuery,
		private shoppingSessionService: ShoppingSessionService
	) {}

	updateRack(unitId: number) {
		this.shoppingSessionService.setUnitId(unitId);
	}
}
