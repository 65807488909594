import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RackCameraComponent } from './rack-camera/rack-camera.component';
import { CameraModule } from '../camera/camera.module';

@NgModule({
	declarations: [RackCameraComponent],
	imports: [CommonModule, CameraModule],
	exports: [RackCameraComponent],
})
export class RackCamerasModule {}
