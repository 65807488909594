import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickedProductsComponent } from './picked-products.component';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';

@NgModule({
	declarations: [PickedProductsComponent],
	imports: [CommonModule, AppMaterialModule],
	exports: [PickedProductsComponent],
})
export class PickedProductsModule {}
